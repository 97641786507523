import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Card,
  Image
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/HomePage/FI_LOGO.png";

class TwoFactorAuth extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        email: this.props.loginData.email,
        password: this.props.loginData.password,
        token: ""
      }
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        params: {
          ...this.state.params,
          email: nextProps.loginData.email,
          password: nextProps.loginData.password
        }
      });
    }
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({
      params: {
        ...this.state.params,
        [name]: value
      }
    });
  };

  handleLogin = () => {
    this.props.loginWithToken(this.state.params);
  };

  render() {
    const disabled = this.state.params.token.length == 0 ? true : false;
    return (
      <Container fluid className="login-container">
        <Row noGutters className="login-row">
          <Col sm={8} md={6} lg={4} xl={4}>
            <Card className="login-box">
              <Row noGutters>
                <Col className="login-content-col">
                  <Row noGutters className="login-header">
                    <Link to="/">
                      <Image fluid src={logo} className="login-logo" />
                    </Link>
                  </Row>

                  <Row
                    noGutters
                    className="login-box-padding login-formcontrol-row"
                  >
                    <Col className="login-box-col">
                      <Row noGutters className="login-welcome">
                        <Col>
                          <h1>Welcome</h1>
                          <p>
                            Your account requires 2-Factor authentication.
                            Please enter the code from your authenticator app.
                          </p>
                        </Col>
                      </Row>

                      <Row noGutters className="login-formcontrol-container">
                        <div className="login-formcontrol-box">
                          <div className="login-formcontrol-text">Code</div>
                          <FormControl
                            size="sm"
                            name="token"
                            value={this.state.params.token}
                            onChange={this.handleOnChange}
                            className="login-formcontrol"
                          />
                        </div>
                      </Row>

                      <Row noGutters className="login-button-row">
                        <Button
                          size="sm"
                          disabled={disabled}
                          onClick={this.handleLogin}
                          className="teal-button"
                        >
                          LOGIN
                        </Button>
                      </Row>

                      <div className="login-navlinks">
                        <Link to="/forgot">Forgot Password</Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TwoFactorAuth;
