import * as CONSTANTS from "../constants/options";

const initialState = {
  sidebarOpened: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpened: !state.sidebarOpened
      };
    default:
      return state;
  }
};
