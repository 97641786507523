export const ADMIN_GET_TRADED_SYMBOLS_REQUEST =
  "ADMIN_GET_TRADED_SYMBOLS_REQUEST";
export const ADMIN_GET_TRADED_SYMBOLS_SUCCESS =
  "ADMIN_GET_TRADED_SYMBOLS_SUCCESS";
export const ADMIN_GET_TRADED_SYMBOLS_ERROR = "ADMIN_GET_TRADED_SYMBOLS_ERROR";

export const ADMIN_CREATE_TRADED_SYMBOL_REQUEST =
  "ADMIN_CREATE_TRADED_SYMBOL_REQUEST";
export const ADMIN_CREATE_TRADED_SYMBOL_SUCCESS =
  "ADMIN_CREATE_TRADED_SYMBOL_SUCCESS";
export const ADMIN_CREATE_TRADED_SYMBOL_ERROR =
  "ADMIN_CREATE_TRADED_SYMBOL_ERROR";

export const ADMIN_SEARCH_USERS_REQUEST = "ADMIN_SEARCH_USERS_REQUEST";
export const ADMIN_SEARCH_USERS_SUCCESS = "ADMIN_SEARCH_USERS_SUCCESS";
export const ADMIN_SEARCH_USERS_ERROR = "ADMIN_SEARCH_USERS_ERROR";
export const ADMIN_SEARCH_USERS_CLEAR = "ADMIN_SEARCH_USERS_CLEAR";

export const ADMIN_SEARCH_REPORTS_REQUEST = "ADMIN_SEARCH_REPORTS_REQUEST";
export const ADMIN_SEARCH_REPORTS_SUCCESS = "ADMIN_SEARCH_REPORTS_SUCCESS";
export const ADMIN_SEARCH_REPORTS_ERROR = "ADMIN_SEARCH_REPORTS_ERROR";
export const ADMIN_SEARCH_REPORTS_CLEAR = "ADMIN_SEARCH_REPORTS_CLEAR";

export const ADMIN_CREATE_OFFERING_REQUEST = "ADMIN_CREATE_OFFERING_REQUEST";
export const ADMIN_CREATE_OFFERING_SUCCESS = "ADMIN_CREATE_OFFERING_SUCCESS";
export const ADMIN_CREATE_OFFERING_ERROR = "ADMIN_CREATE_OFFERING_ERROR";

export const ADMIN_GET_OFFER_REQUEST = "ADMIN_GET_OFFER_REQUEST";
export const ADMIN_GET_OFFER_SUCCESS = "ADMIN_GET_OFFER_SUCCESS";
export const ADMIN_GET_OFFER_ERROR = "ADMIN_GET_OFFER_ERROR";

export const ADMIN_UPDATE_OFFER_REQUEST = "ADMIN_UPDATE_OFFER_REQUEST";
export const ADMIN_UPDATE_OFFER_SUCCESS = "ADMIN_UPDATE_OFFER_SUCCESS";
export const ADMIN_UPDATE_OFFER_ERROR = "ADMIN_UPDATE_OFFER_ERROR";

export const ADMIN_GET_WEBSITE_CONFIG_REQUEST =
  "ADMIN_GET_WEBSITE_CONFIG_REQUEST";
export const ADMIN_GET_WEBSITE_CONFIG_SUCCESS =
  "ADMIN_GET_WEBSITE_CONFIG_SUCCESS";
export const ADMIN_GET_WEBSITE_CONFIG_ERROR = "ADMIN_GET_WEBSITE_CONFIG_ERROR";

export const ADMIN_UPDATE_WEBSITE_CONFIG_REQUEST =
  "ADMIN_UPDATE_WEBSITE_CONFIG_REQUEST";
export const ADMIN_UPDATE_WEBSITE_CONFIG_SUCCESS =
  "ADMIN_UPDATE_WEBSITE_CONFIG_SUCCESS";
export const ADMIN_UPDATE_WEBSITE_CONFIG_ERROR =
  "ADMIN_UPDATE_WEBSITE_CONFIG_ERROR";

export const SOFT_UPDATE_WEBSITE_CONFIG = "SOFT_UPDATE_WEBSITE_CONFIG";
