export const MY_ACCOUNT_REQUEST = "MY_ACCOUNT_REQUEST";
export const MY_ACCOUNT_SUCCESS = "MY_ACCOUNT_SUCCESS";
export const MY_ACCOUNT_ERROR = "MY_ACCOUNT_ERROR";

export const PROFILE_IMAGE_UPLOAD_REQUEST = "PROFILE_IMAGE_UPLOAD_REQUEST";
export const PROFILE_IMAGE_UPLOAD_SUCCESS = "PROFILE_IMAGE_UPLOAD_SUCCESS";
export const PROFILE_IMAGE_UPLOAD_ERROR = "PROFILE_IMAGE_UPLOAD_ERROR";

export const SEARCH_USER_REQUEST = "SEARCH_USER_REQUEST";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_ERROR = "SEARCH_USER_ERROR";

export const RESET_SEARCH_USER = "RESET_SEARCH_USER";

export const CHANGE_ADMIN_STATUS_REQUEST = "CHANGE_ADMIN_STATUS_REQUEST";
export const CHANGE_ADMIN_STATUS_SUCCESS = "CHANGE_ADMIN_STATUS_SUCCESS";
export const CHANGE_ADMIN_STATUS_ERROR = "CHANGE_ADMIN_STATUS_ERROR";

export const CHANGE_CONTRIBUTOR_STATUS_REQUEST =
  "CHANGE_CONTRIBUTOR_STATUS_REQUEST";
export const CHANGE_CONTRIBUTOR_STATUS_SUCCESS =
  "CHANGE_CONTRIBUTOR_STATUS_SUCCESS";
export const CHANGE_CONTRIBUTOR_STATUS_ERROR =
  "CHANGE_CONTRIBUTOR_STATUS_ERROR";

export const EDIT_ACCOUNT_REQUEST = "EDIT_ACCOUNT_REQUEST";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_ERROR = "EDIT_ACCOUNT_ERROR";

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";

export const CLEAR_USER_CACHE = "CLEAR_USER_CACHE";
export const CLEAR_USER_LIST_CACHE = "CLEAR_USER_LIST_CACHE";

export const CONTRIBUTOR_APPLICATION_REQUEST =
  "CONTRIBUTOR_APPLICATION_REQUEST";
export const CONTRIBUTOR_APPLICATION_SUCCESS =
  "CONTRIBUTOR_APPLICATION_SUCCESS";
export const CONTRIBUTOR_APPLICATION_ERROR = "CONTRIBUTOR_APPLICATION_ERROR";

export const EDIT_CONTRIBUTOR_PROFILE_REQUEST =
  "EDIT_CONTRIBUTOR_PROFILE_REQUEST";
export const EDIT_CONTRIBUTOR_PROFILE_SUCCESS =
  "EDIT_CONTRIBUTOR_PROFILE_SUCCESS";
export const EDIT_CONTRIBUTOR_PROFILE_ERROR = "EDIT_CONTRIBUTOR_PROFILE_ERROR";

export const EDIT_CONTRIBUTOR_ACCOUNT_REQUEST =
  "EDIT_CONTRIBUTOR_ACCOUNT_REQUEST";
export const EDIT_CONTRIBUTOR_ACCOUNT_SUCCESS =
  "EDIT_CONTRIBUTOR_ACCOUNT_SUCCESS";
export const EDIT_CONTRIBUTOR_ACCOUNT_ERROR = "EDIT_CONTRIBUTOR_ACCOUNT_ERROR";

export const GET_PUBLISHED_REPORTS_REQUEST = "GET_PUBLISHED_REPORTS_REQUEST";
export const GET_PUBLISHED_REPORTS_SUCCESS = "GET_PUBLISHED_REPORTS_SUCCESS";
export const GET_PUBLISHED_REPORTS_ERROR = "GET_PUBLISHED_REPORTS_ERROR";

export const GET_DRAFTS_REQUEST = "GET_DRAFTS_REQUEST";
export const GET_DRAFTS_SUCCESS = "GET_DRAFTS_SUCCESS";
export const GET_DRAFTS_ERROR = "GET_DRAFTS_ERROR";

export const PROFILE_IMAGE_REQUEST = "PROFILE_IMAGE_REQUEST";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";

export const CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_REQUEST =
  "CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_REQUEST";
export const CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_SUCCESS =
  "CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_SUCCESS";

export const CHECK_USER_STATUS = "CHECK_USER_STATUS";
export const SET_USER_AUTHENTICATED_STATUS_TRUE =
  "SET_USER_AUTHENTICATED_STATUS_TRUE";
export const SET_USER_AUTHENTICATED_STATUS_FALSE =
  "SET_USER_AUTHENTICATED_STATUS_FALSE";

export const WIPE_LOCAL_STORAGE = "WIPE_LOCAL_STORAGE";

export const SEND_INVITES_REQUEST = "SEND_INVITES_REQUEST";
export const SEND_INVITES_SUCCESS = "SEND_INVITES_SUCCESS";

export const UPLOAD_AVATAR_IMAGE_REQUEST = "UPLOAD_AVATAR_IMAGE_REQUEST";
export const UPLOAD_AVATAR_IMAGE_SUCCESS = "UPLOAD_AVATAR_IMAGE_SUCCESS";
export const UPLOAD_AVATAR_IMAGE_ERROR = "UPLOAD_AVATAR_IMAGE_ERROR";

export const UPLOAD_BANNER_IMAGE_REQUEST = "UPLOAD_BANNER_IMAGE_REQUEST";
export const UPLOAD_BANNER_IMAGE_SUCCESS = "UPLOAD_BANNER_IMAGE_SUCCESS";
export const UPLOAD_BANNER_IMAGE_ERROR = "UPLOAD_BANNER_IMAGE_ERROR";

export const LANDING_PAGE_USER_SEARCH_REQUEST =
  "LANDING_PAGE_USER_SEARCH_REQUEST";
export const LANDING_PAGE_USER_SEARCH_SUCCESS =
  "LANDING_PAGE_USER_SEARCH_SUCCESS";
export const LANDING_PAGE_USER_SEARCH_ERROR = "LANDING_PAGE_USER_SEARCH_ERROR";

export const ADMIN_SEARCH_USERS_REQUEST = "ADMIN_SEARCH_USERS_REQUEST";
export const ADMIN_SEARCH_USERS_SUCCESS = "ADMIN_SEARCH_USERS_SUCCESS";
export const ADMIN_SEARCH_USERS_ERROR = "ADMIN_SEARCH_USERS_ERROR";

export const ADMIN_TOGGLE_CONTRIBUTOR_REQUEST =
  "ADMIN_TOGGLE_CONTRIBUTOR_REQUEST";
export const ADMIN_TOGGLE_CONTRIBUTOR_SUCCESS =
  "ADMIN_TOGGLE_CONTRIBUTOR_SUCCESS";
export const ADMIN_TOGGLE_CONTRIBUTOR_ERROR = "ADMIN_TOGGLE_CONTRIBUTOR_ERROR";

export const UPDATE_WATCHLIST_REQUEST = "UPDATE_WATCHLIST_REQUEST";
export const UPDATE_WATCHLIST_SUCCESS = "UPDATE_WATCHLIST_SUCCESS";
export const UPDATE_WATCHLIST_ERROR = "UPDATE_WATCHLIST_ERROR";
