import React, { PureComponent } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Paginations from "../Pagination";
import ReactLoading from "react-loading";
import DatePicker from "react-date-picker";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import {
  MdSave,
  MdComment,
  MdPublish,
  MdClear,
  MdArrowBack,
  MdList,
  MdViewModule,
  MdOpenInNew
} from "react-icons/md";
import { AiOutlineRedo } from "react-icons/ai";
import {
  IoIosHeartEmpty,
  IoIosHeart,
  IoMdPeople,
  IoIosArrowRoundForward
} from "react-icons/io";
import {
  Row,
  Col,
  Button,
  FormControl,
  Image,
  Card,
  Container,
  Table,
  Spinner,
  Pagination
} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import he from "he";
import { history } from "../../configureStore";
import MetaTags from "react-meta-tags";
import "./News.css";
import { initGA, logPageView } from "../../utils/analytics";
import LogoImg from "../../images/logoImage.js";
let defaultNewsImage1 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image1.jpg";
let defaultNewsImage2 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image2.jpg";
let defaultNewsImage3 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image3.jpg";
let defaultNewsImage4 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image4.jpg";
let defaultNewsImage5 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image5.jpg";
let defaultNewsImage6 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image6.jpg";
let defaultNewsImage7 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image7.jpg";
let defaultNewsImage8 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image8.jpg";
let defaultNewsImage9 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image9.jpg";
let defaultNewsImage10 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image10.jpg";
let defaultNewsImage11 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image11.jpg";
let defaultNewsImage12 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image12.jpg";
let defaultNewsImage13 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image13.jpg";
let defaultNewsImage14 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image14.jpg";
let defaultNewsImage15 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image15.jpg";
let defaultNewsImage16 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image16.jpg";
let defaultNewsImage17 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image17.jpg";
let defaultNewsImage18 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image18.jpg";
let defaultNewsImage19 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image19.jpg";
let defaultNewsImage20 =
  "https://vc-stock-photos.s3.amazonaws.com/default-image20.jpg";
let usedErrorImages = [];

class News extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentSection: "latest news",
      currentView: "cards",
      currentPage: 1,
      newsFeedPageSize: 9,
      showReadMoreModal: false,
      newsFeed: {
        data: {},
        loading: false,
        loaded: false,
        error: ""
      },
      publishers: {
        data: [],
        loading: false,
        loaded: false,
        error: ""
      },
      trendingTopics: {
        data: {},
        loading: false,
        loaded: false,
        error: ""
      },
      specificArticle: {
        data: {},
        loading: false,
        loaded: false,
        error: ""
      },
      searchParams: {
        publisher: "",
        from: "",
        to: "",
        query: "",
        offset: 0
      }
    };
  }

  componentDidMount() {
    initGA();
    logPageView();
    this.handleParseUrl();
    this.handleGetPublishers();
    this.handleGetTrendingTopics();
    this.handleGetSavedNews();
    this.handleGetSavedNewsIds();
    this.props.clearSpecificNewsArticle();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        newsFeed: nextProps.newsFeed,
        publishers: nextProps.publishers,
        trendingTopics: nextProps.trendingTopics,
        specificArticle: nextProps.specificArticle
      });
    }
  }

  handleParseUrl = () => {
    const search = this.props.history.location.search;
    const splitSearch = search.split("?name=");

    this.setState(
      {
        searchParams: {
          ...this.state.searchParams,
          query: splitSearch[1]
        }
      },
      () => this.handleSearchNewsArticles()
    );
  };

  handleSearchNewsArticles = () => {
    this.props.getNewsFeedRequest(this.state.searchParams);
  };

  handleGetPublishers = () => {
    this.props.newsFeedListRequest();
  };

  handleGetTrendingTopics = () => {
    this.props.getCategoryListRequest();
  };

  handleGetSavedNews = () => {
    this.props.getSavedNewsRequest();
  };

  handleGetSavedNewsIds = () => {
    this.props.getSavedNewsIdsRequest();
  };

  handleChangeParams = e => {
    this.setState(
      {
        searchParams: {
          ...this.state.searchParams,
          [e.target.name]: e.target.value
        }
      },
      () => this.handleSearchNewsArticles()
    );
  };

  handleChangeDateParam = (e, name) => {
    this.setState(
      {
        searchParams: {
          ...this.state.searchParams,
          [name]: e
        }
      },
      () => this.handleSearchNewsArticles()
    );
  };

  handleClickPage = number => {
    window.scrollTo(0, 0);
    this.setState(
      {
        currentPage: number,
        searchParams: {
          ...this.state.searchParams,
          offset: (number - 1) * 10
        }
      },
      () => this.handleSearchNewsArticles()
    );
  };

  handleSwitchCurrentView = e => {
    this.setState({
      currentView: e
    });
  };

  handleSwitchCurrentSection = e => {
    this.setState({
      currentSection: e
    });
  };

  handleSearchForTrendingTopic = topic => {
    this.setState(
      {
        searchParams: {
          ...this.state.searchParams,
          query: topic
        }
      },
      () => this.handleSearchNewsArticles()
    );
  };

  handleResetSearchParams = () => {
    this.setState(
      {
        searchParams: {
          publisher: "",
          from: "",
          to: "",
          query: "",
          offset: 0
        }
      },
      () => this.handleSearchNewsArticles()
    );
  };

  handleImageError = e => {
    /*
      This is used if the image sent with the news article returns an error.
      It will replace the image src with a random image from the images array.
    */
    const imagesArray = [
      defaultNewsImage10,
      defaultNewsImage11,
      defaultNewsImage12,
      defaultNewsImage13,
      defaultNewsImage14,
      defaultNewsImage15,
      defaultNewsImage16,
      defaultNewsImage17,
      defaultNewsImage18,
      defaultNewsImage19,
      defaultNewsImage20
    ];
    let randomImage =
      imagesArray[Math.floor(Math.random() * imagesArray.length)];

    if (
      usedErrorImages.includes(randomImage) &&
      usedErrorImages.length !== imagesArray.length
    ) {
      this.handleImageError(e);
    } else if (usedErrorImages.length !== imagesArray.length) {
      usedErrorImages.push(randomImage);
      e.target.src = randomImage;
    } else if (usedErrorImages.length === imagesArray.length) {
      usedErrorImages = [];
      e.target.src = randomImage;
    }
  };

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  };

  handleShowReadMoreModal = id => {
    this.setState(
      {
        showReadMoreModal: true
      },
      () => this.props.getNewsRequest(id)
    );
  };

  handleHideReadMoreModal = () => {
    this.setState({
      showReadMoreModal: false
    });
  };

  /****************************************************************************/

  renderSearchBar = () => {
    const { publisher, from, to, query } = this.state.searchParams;
    const { data, loaded } = this.state.publishers;

    let publishers;
    if (loaded) {
      publishers = data.map(publisher => this.renderPublisherOption(publisher));
    }
    return (
      <Row noGutters className="news-search-row">
        <div className="news-search-datepicker-container">
          <div className="news-search-form-control">
            From
            <DatePicker
              calendarClassName="date-picker-calendar"
              className="news-date-picker"
              value={from}
              onChange={e => this.handleChangeDateParam(e, "from")}
              calendarType="US"
            />
          </div>

          <div className="news-search-form-control">
            To
            <DatePicker
              calendarClassName="date-picker-calendar"
              className="news-date-picker"
              value={to}
              onChange={e => this.handleChangeDateParam(e, "to")}
              calendarType="US"
            />
          </div>
        </div>

        <FormControl
          size="sm"
          className="search-assets-form-control-new"
          placeholder="Search..."
          value={query}
          name="query"
          onChange={this.handleChangeParams}
        />
        {this.renderResetParamsButton()}
      </Row>
    );
  };

  renderMenuBar = () => {
    const listActive = this.state.currentView == "list" ? "active" : "";
    const cardsActive = this.state.currentView == "cards" ? "active" : "";
    const latestActive =
      this.state.currentSection == "latest news" ? "active" : "";
    const savedActive =
      this.state.currentSection == "saved articles" ? "active" : "";

    return (
      <Row noGutters className="news-menu-row">
        <div className="news-menu-control-container">
          <div
            onClick={() => this.handleSwitchCurrentSection("latest news")}
            className="news-menu-item"
            id={latestActive}
          >
            Latest News
          </div>
          <MdList
            onClick={() => this.handleSwitchCurrentView("list")}
            className="news-menu-item icon"
            id={listActive}
          />
          <MdViewModule
            onClick={() => this.handleSwitchCurrentView("cards")}
            className="news-menu-item icon"
            id={cardsActive}
          />
        </div>
      </Row>
    );
  };

  renderPublisherOption = publisher => (
    <option
      key={publisher.searchTitle}
      name={publisher.searchTitle}
      value={publisher.searchTitle}
    >
      {publisher.displayTitle}
    </option>
  );

  renderNewsFeed = () => {
    const { data, loading, loaded } = this.state.newsFeed;
    const { currentView } = this.state;

    let content;
    if (loading) {
      content = <div className="news-feed-loading">{this.renderLoading()}</div>;
    } else if (loaded && data.total > 0) {
      if (currentView == "list") {
        content = this.renderNewsList();
      } else if (currentView == "cards") {
        content = this.renderNewsCards();
      }
    } else {
      content = (
        <div className="news-feed-loading">Couldn't find any articles</div>
      );
    }

    return content;
  };

  renderNewsCards = () => {
    const { data } = this.state.newsFeed;
    const content = data.docs.map(article => this.renderNewsCard(article));

    return (
      <Row noGutters className="news-cards-container">
        {content}
      </Row>
    );
  };

  renderNewsCard = article => {
    const { date, image, link, publisher, title, content, _id } = article;

    return (
      <Card key={_id} className="news-card">
        <a href={link} target="_blank">
          <Card.Img onError={this.handleImageError} variant="top" src={image} />
        </a>
        <Card.Title>
          <a href={link} target="_blank">
            {title}
          </a>
        </Card.Title>
        <div
          onClick={() => this.handleShowReadMoreModal(_id)}
          className="news-card-footer"
        >
          Read more
          <IoIosArrowRoundForward />
        </div>
      </Card>
    );
  };

  renderNewsList = () => {
    const { data } = this.state.newsFeed;
    const content = data.docs.map(article => this.renderNewsListItem(article));

    return (
      <Row noGutters className="news-list-container">
        <Col>{content}</Col>
      </Row>
    );
  };

  renderNewsListItem = article => {
    const { id, date, image, link, publisher, title, content } = article;

    let titleContent;
    if (title.length > 70) {
      titleContent = title.slice(0, 70) + "...";
    } else {
      titleContent = title;
    }

    return (
      <Row noGutters className="news-list-item">
        <div className="news-item-first-half">
          <div className="date-col">
            <Moment fromNow>{date}</Moment>
          </div>
          <div className="title-col">
            <a href={link} target="_blank">
              {titleContent}
            </a>
          </div>
        </div>

        <div className="news-item-last-half">
          <div className="publisher-col">{publisher}</div>
          <a className="article-link" href={link} target="_blank">
            <MdOpenInNew className="common-icon" />
          </a>
        </div>
      </Row>
    );
  };

  renderTrendingTopics = () => {
    const { data, loading, loaded } = this.state.trendingTopics;

    let content;
    if (loading) {
      content = (
        <div className="news-no-trending-topics">{this.renderLoading()}</div>
      );
    } else if (loaded && data.total > 0) {
      content = data.docs.map(topic => this.renderTrendingTopic(topic));
    } else {
      content = (
        <div className="news-no-trending-topics">No trending topics</div>
      );
    }

    return (
      <div className="news-trending-topics">
        <h5>Trending Topics</h5>
        {content}
      </div>
    );
  };

  renderTrendingTopic = topic => {
    const { name, count } = topic;

    return (
      <div className="news-trending-topic">
        <div
          onClick={() => this.handleSearchForTrendingTopic(name)}
          className="trending-name"
        >
          {name}
        </div>
        <div>{count}</div>
      </div>
    );
  };

  renderPagination = () => {
    const { newsFeed, currentPage, newsFeedPageSize } = this.state;

    if (newsFeed.loaded) {
      const { data, total } = newsFeed.data;
      const numberOfPages = Math.ceil(total / newsFeedPageSize);
      let pages = [];

      for (let number = 1; number <= numberOfPages; number++) {
        pages.push(
          <Pagination.Item
            onClick={() => this.handleClickPage(number)}
            key={number}
            active={number === currentPage}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (pages.length > 10 && currentPage > 10) {
        pages = pages.slice(currentPage - 5, currentPage + 5);
      } else if (pages.length > 10 && currentPage <= 10) {
        pages = pages.slice(0, currentPage + 5);
      }

      let prevEllipsis;
      let nextEllipsis;
      if (
        numberOfPages > 10 &&
        currentPage >= 10 &&
        currentPage <= numberOfPages - 10
      ) {
        prevEllipsis = (
          <Pagination.Ellipsis
            onClick={() => this.handleClickPage(currentPage - 10)}
          />
        );
        nextEllipsis = (
          <Pagination.Ellipsis
            onClick={() => this.handleClickPage(currentPage + 10)}
          />
        );
      } else if (currentPage <= numberOfPages - 10) {
        nextEllipsis = (
          <Pagination.Ellipsis
            onClick={() => this.handleClickPage(currentPage + 10)}
          />
        );
      }

      const first =
        Math.abs(currentPage - 1) > 3 ? (
          <Pagination.First onClick={() => this.handleClickPage(1)} />
        ) : (
          ""
        );
      const last =
        Math.abs(currentPage - numberOfPages) > 3 ? (
          <Pagination.Last
            onClick={() => this.handleClickPage(numberOfPages)}
          />
        ) : (
          ""
        );
      const next =
        currentPage !== numberOfPages ? (
          <Pagination.Next
            onClick={() => this.handleClickPage(currentPage + 1)}
          />
        ) : (
          ""
        );
      const prev =
        currentPage !== 1 ? (
          <Pagination.Prev
            onClick={() => this.handleClickPage(currentPage - 1)}
          />
        ) : (
          ""
        );

      return (
        <Row noGutters className="pagination-row">
          <Pagination className="common-pagination">
            {first}
            {prev}
            {prevEllipsis}
            {pages}
            {nextEllipsis}
            {next}
            {last}
          </Pagination>
        </Row>
      );
    }
  };

  renderReadMoreModal = () => {
    const { data, loading, loaded } = this.state.specificArticle;

    let body;
    if (loading) {
      body = (
        <Modal.Body className="loading-body">{this.renderLoading()}</Modal.Body>
      );
    } else if (loaded) {
      const { publisher, title, content, date, image, link } = data;
      const formattedDate = this.formatDate(date);
      let articleContent = ReactHtmlParser(content);
      body = (
        <Modal.Body>
          <a href={link} target="_blank">
            <img
              onError={this.handleImageError}
              src={image}
              className="news-modal-image"
            />
          </a>
          <div className="news-modal-content-container">
            <div className="news-modal-pub-date">
              <div className="news-modal-publisher">{publisher},</div>
              <div className="news-modal-date">{formattedDate}</div>
            </div>
            <a className="news-modal-title" href={link} target="_blank">
              {title}
            </a>
            <div className="news-modal-content">{articleContent}</div>
            <a href={link} target="_blank" className="news-modal-read-more">
              See original article on {publisher}
              <IoIosArrowRoundForward />
            </a>
          </div>
        </Modal.Body>
      );
    }
    return (
      <Modal
        centered
        show={this.state.showReadMoreModal}
        onHide={this.handleHideReadMoreModal}
        className="common-modal news-modal"
      >
        {body}
      </Modal>
    );
  };

  renderResetParamsButton = () => (
    <AiOutlineRedo
      className="reset-params-icon"
      onClick={this.handleResetSearchParams}
    />
  );

  renderLoading = () => (
    <Row noGutters className="app-spinner">
      <Spinner animation="border" variant="secondary" />
    </Row>
  );

  renderMetaTags = () => {
    return (
      <MetaTags>
        <title>Asset Builder</title>
        <meta
          name="description"
          content="Latest news on digital assets, blockchain, crypto, and security tokens"
        />
        <meta
          name="title"
          property="og:title"
          content="Velocity Channel Digital Asset News"
        />
        <meta
          property="og:description"
          content="Latest news on digital assets, blockchain, crypto, and security tokens"
        />
        <meta property="og:image" content={LogoImg} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://velocitychannel.io/news" />
        <meta
          property="twitter:title"
          content="Velocity Channel Digital Asset News"
        />
        <meta
          property="twitter:description"
          content="Latest news on digital assets, blockchain, crypto, and security tokens"
        />
        <meta property="twitter:image" content={LogoImg} />
        <meta property="twitter:site" content="Velocity Channel" />
        <meta name="robots" content="nofollow" />
      </MetaTags>
    );
  };

  render() {
    return (
      <Container fluid className="content-pane-container">
        {this.renderMetaTags()}
        {this.renderReadMoreModal()}
        <Row noGutters className="news-menu-section">
          {this.renderSearchBar()}
          {this.renderMenuBar()}
        </Row>
        <Row noGutters className="news-feed-section">
          <div className="news-feed-container">
            {this.renderNewsFeed()}
            {this.renderPagination()}
          </div>
          {this.renderTrendingTopics()}
        </Row>
      </Container>
    );
  }
}

export default News;
