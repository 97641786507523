import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import request from "../../../utils/apiRequest";
import * as CONSTANTS from "../../constants/announcements";
import * as ACTIONS from "../../actions/announcements";

function* createAnnouncement(action) {
  try {
    const responseData = yield call(request, "/announcement/create", "POST");

    yield put(ACTIONS.createAnnouncementSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.createAnnouncementError(err.reason));
  }
}

function* searchAnnouncements(action) {
  try {
    const responseData = yield call(
      request,
      "/announcement/search?",
      "GET",
      action.params
    );

    yield put(ACTIONS.searchAnnouncementsSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.searchAnnouncementsError(err.reason));
  }
}

function* deleteAnnouncement(action) {
  try {
    const body = {
      _id: action._id
    };
    const responseData = yield call(
      request,
      "/announcement/delete",
      "POST",
      body
    );

    yield put(ACTIONS.deleteAnnouncementSuccess());
    yield put(ACTIONS.searchAnnouncementsRequest(action.params));
  } catch (err) {
    yield put(ACTIONS.deleteAnnouncementError(err.reason));
  }
}

function* getAnnouncement(action) {
  try {
    const body = {
      _id: action._id
    };

    const responseData = yield call(
      request,
      "/announcement/search?",
      "GET",
      body
    );

    yield put(ACTIONS.getAnnouncementSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getAnnouncementError(err.reason));
  }
}

function* editAnnouncement(action) {
  try {
    const newDraftData = action.data;
    const deepCopy = { ...newDraftData };
    deepCopy.docs = [...newDraftData.docs];
    deepCopy.docs[0] = { ...newDraftData.docs[0] };
    deepCopy.docs[0].attachments = JSON.stringify(
      newDraftData.docs[0].attachments
    );

    const responseData = yield call(
      request,
      "/announcement/edit",
      "POST",
      deepCopy.docs[0]
    );

    // console.log(responseData);

    yield put(ACTIONS.editAnnouncementSuccess());
  } catch (err) {
    yield put(ACTIONS.editAnnouncementError(err.reason));
  }
}

function* publishAnnouncement(action) {
  try {
    const body = { _id: action._id };
    const responseData = yield call(
      request,
      "/announcement/publish",
      "POST",
      body
    );

    history.push(`/announcements/${action._id}`);
    // yield put(ACTIONS.getAnnouncementRequest(action._id));
    // yield put(ACTIONS.searchAnnouncementsRequest(action.params));
    yield put(ACTIONS.publishAnnouncementSuccess());
  } catch (err) {
    yield put(ACTIONS.publishAnnouncementError(err.reason));
  }
}

function* unpublishAnnouncement(action) {
  try {
    const body = { _id: action._id };
    const responseData = yield call(
      request,
      "/announcement/unpublish",
      "POST",
      body
    );

    yield put(ACTIONS.unpublishAnnouncementSuccess());
    yield put(ACTIONS.searchAnnouncementsRequest(action.params));
  } catch (err) {
    yield put(ACTIONS.unpublishAnnouncementError(err.reason));
  }
}

function* attachFileToAnnouncement(action) {
  try {
    let formData = new FormData();
    formData.append("file", action.file);
    const responseData = yield call(
      request,
      "/file/uploadFile",
      "POST",
      formData,
      false,
      true
    );

    const fileName = action.file.name;
    const url = responseData.url;
    const newDraftData = action.formData;
    const attachments = newDraftData.docs[0].attachments;
    const newAttachment = { fileName: fileName, url: url };
    attachments.push(newAttachment);
    // const stringifiedAttachments = JSON.stringify(attachments);
    const stringifiedAttachments = attachments;
    const deepCopy = { ...newDraftData };
    deepCopy.docs = [...newDraftData.docs];
    deepCopy.docs[0] = { ...newDraftData.docs[0] };
    deepCopy.docs[0].attachments = stringifiedAttachments;
    yield put(ACTIONS.editAnnouncementRequest(deepCopy));
    yield put(ACTIONS.attachFileToAnnouncementSuccess());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.attachFileToAnnouncementError(err));
  }
}

function* removeFileFromAnnouncement(action) {
  try {
    const body = {
      url: action.url
    };

    const responseData = yield call(request, "/file/deleteFile", "POST", body);
    const itemToBeRemoved = action.index;
    const newDraftData = action.formData;
    let attachments = newDraftData.docs[0].attachments;
    attachments.splice(itemToBeRemoved, 1);
    newDraftData.docs[0].attachments = attachments;
    const stringifiedAttachments = JSON.stringify(attachments);
    const deepCopy = { ...newDraftData };
    deepCopy.docs = [...newDraftData.docs];
    deepCopy.docs[0] = { ...newDraftData.docs[0] };
    deepCopy.docs[0].attachments = stringifiedAttachments;
    yield put(ACTIONS.editAnnouncementRequest(deepCopy));
    yield put(ACTIONS.removeFileFromAnnouncementSuccess());
  } catch (err) {
    // toast.error(err.reason);

    if (err.reason == "File not found") {
      const itemToBeRemoved = action.index;
      const newDraftData = action.formData;
      let attachments = newDraftData.docs[0].attachments;
      attachments.splice(itemToBeRemoved, 1);
      const stringifiedAttachments = JSON.stringify(attachments);
      const deepCopy = { ...newDraftData };
      deepCopy.docs = [...newDraftData.docs];
      deepCopy.docs[0] = { ...newDraftData.docs[0] };
      deepCopy.docs[0].attachments = stringifiedAttachments;
      yield put(ACTIONS.editAnnouncementRequest(deepCopy));
      yield put(ACTIONS.removeFileFromAnnouncementSuccess());
    }

    yield put(ACTIONS.removeFileFromAnnouncementError(err));
  }
}

export default function* announcementsSaga() {
  yield takeLatest(CONSTANTS.CREATE_ANNOUNCEMENT_REQUEST, createAnnouncement);
  yield takeLatest(CONSTANTS.DELETE_ANNOUNCEMENT_REQUEST, deleteAnnouncement);
  yield takeLatest(CONSTANTS.SEARCH_ANNOUNCEMENTS_REQUEST, searchAnnouncements);
  yield takeLatest(CONSTANTS.GET_ANNOUNCEMENT_REQUEST, getAnnouncement);
  yield takeLatest(CONSTANTS.EDIT_ANNOUNCEMENT_REQUEST, editAnnouncement);
  yield takeLatest(CONSTANTS.PUBLISH_ANNOUNCEMENT_REQUEST, publishAnnouncement);
  yield takeLatest(
    CONSTANTS.UNPUBLISH_ANNOUNCEMENT_REQUEST,
    unpublishAnnouncement
  );
  yield takeLatest(
    CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_REQUEST,
    attachFileToAnnouncement
  );
  yield takeLatest(
    CONSTANTS.REMOVE_FILE_FROM_ANNOUNCEMENT_REQUEST,
    removeFileFromAnnouncement
  );
}
