export const EQUITY_TOKEN_FORM_REQUEST = "EQUITY_TOKEN_FORM_REQUEST";
export const EQUITY_TOKEN_FORM_SUCCESS = "EQUITY_TOKEN_FORM_SUCCESS";
export const EQUITY_TOKEN_FORM_ERROR = "EQUITY_TOKEN_FORM_ERROR";

export const DEBT_SECURITY_TOKEN_FORM_REQUEST =
  "DEBT_SECURITY_TOKEN_FORM_REQUEST";
export const DEBT_SECURITY_TOKEN_FORM_SUCCESS =
  "DEBT_SECURITY_TOKEN_FORM_SUCCESS";
export const DEBT_SECURITY_TOKEN_FORM_ERROR = "DEBT_SECURITY_TOKEN_FORM_ERROR";

export const REAL_ESTATE_TOKEN_FORM_REQUEST = "REAL_ESTATE_TOKEN_FORM_REQUEST";
export const REAL_ESTATE_TOKEN_FORM_SUCCESS = "REAL_ESTATE_TOKEN_FORM_SUCCESS";
export const REAL_ESTATE_TOKEN_FORM_ERROR = "REAL_ESTATE_TOKEN_FORM_ERROR";

export const FUND_SECURITY_FORM_REQUEST = "FUND_SECURITY_FORM_REQUEST";
export const FUND_SECURITY_FORM_SUCCESS = "FUND_SECURITY_FORM_SUCCESS";
export const FUND_SECURITY_FORM_ERROR = "FUND_SECURITY_FORM_ERROR";

export const GET_TOKEN_REQUEST = "GET_TOKEN_REQUEST";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";

export const GET_TOKEN_EDIT_REQUEST = "GET_TOKEN_EDIT_REQUEST";
export const EDIT_TOKEN_REQUEST = "EDIT_TOKEN_REQUEST";
export const EDIT_TOKEN_SUCCESS = "EDIT_TOKEN_SUCCESS";
export const EDIT_TOKEN_ERROR = "EDIT_TOKEN_ERROR";

export const EDIT_REAL_ESTATE_TOKEN_REQUEST = "EDIT_REAL_ESTATE_TOKEN_REQUEST";
export const EDIT_DEBT_TOKEN_REQUEST = "EDIT_DEBT_TOKEN_REQUEST";
export const EDIT_FUND_SECURITY_REQUEST = "EDIT_FUND_SECURITY_REQUEST";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR";

export const UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_ERROR = "UPLOAD_DOCUMENT_ERROR";

export const RESET_TOKEN_FORM_ITEMS = "RESET_TOKEN_FORM_ITEMS";
export const RESET_DOCUMENT_LINK = "RESET_DOCUMENT_LINK";

export const CREATE_TOKEN_REQUEST = "CREATE_TOKEN_REQUEST";
export const CREATE_TOKEN_SUCCESS = "CREATE_TOKEN_SUCCESS";

export const SUBMIT_LISTING_REQUEST = "SUBMIT_LISTING_REQUEST";
export const SUBMIT_LISTING_SUCCESS = "SUBMIT_LISTING_SUCCESS";

export const GET_LISTING_REQUESTS_REQUEST = "GET_LISTING_REQUESTS_REQUEST";
export const GET_LISTING_REQUESTS_SUCCESS = "GET_LISTING_REQUESTS_SUCCESS";

export const GET_TOKEN_TEMPLATES_REQUEST = "GET_TOKEN_TEMPLATES_REQUEST";
export const GET_TOKEN_TEMPLATES_SUCCESS = "GET_TOKEN_TEMPLATES_SUCCESS";
export const GET_TOKEN_TEMPLATES_ERROR = "GET_TOKEN_TEMPLATES_ERROR";

export const GET_TOKEN_TEMPLATE_TO_EDIT_REQUEST =
  "GET_TOKEN_TEMPLATE_TO_EDIT_REQUEST";
export const GET_TOKEN_TEMPLATE_TO_EDIT_SUCCESS =
  "GET_TOKEN_TEMPLATE_TO_EDIT_SUCCESS";
export const GET_TOKEN_TEMPLATE_TO_EDIT_ERROR =
  "GET_TOKEN_TEMPLATE_TO_EDIT_ERROR";

export const HANDLE_CLEAR_TOKEN_TEMPLATE_TO_EDIT =
  "HANDLE_CLEAR_TOKEN_TEMPLATE_TO_EDIT";

export const EDIT_TOKEN_TEMPLATE_REQUEST = "EDIT_TOKEN_TEMPLATE_REQUEST";
export const EDIT_TOKEN_TEMPLATE_SUCCESS = "EDIT_TOKEN_TEMPLATE_SUCCESS";
export const EDIT_TOKEN_TEMPLATE_ERROR = "EDIT_TOKEN_TEMPLATE_ERROR";

export const CREATE_TOKEN_TEMPLATE_REQUEST = "CREATE_TOKEN_TEMPLATE_REQUEST";
export const CREATE_TOKEN_TEMPLATE_SUCCESS = "CREATE_TOKEN_TEMPLATE_SUCCESS";
export const CREATE_TOKEN_TEMPLATE_ERROR = "CREATE_TOKEN_TEMPLATE_ERROR";

export const DELETE_TOKEN_TEMPLATE_REQUEST = "DELETE_TOKEN_TEMPLATE_REQUEST";
export const DELETE_TOKEN_TEMPLATE_SUCCESS = "DELETE_TOKEN_TEMPLATE_SUCCESS";
export const DELETE_TOKEN_TEMPLATE_ERROR = "DELETE_TOKEN_TEMPLATE_ERROR";

export const PUBLISH_TOKEN_TEMPLATE_REQUEST = "PUBLISH_TOKEN_TEMPLATE_REQUEST";
export const PUBLISH_TOKEN_TEMPLATE_SUCCESS = "PUBLISH_TOKEN_TEMPLATE_SUCCESS";
export const PUBLISH_TOKEN_TEMPLATE_ERROR = "PUBLISH_TOKEN_TEMPLATE_ERROR";

export const GET_PUBLISHED_TEMPLATES_REQUEST =
  "GET_PUBLISHED_TEMPLATES_REQUEST";
export const GET_PUBLISHED_TEMPLATES_SUCCESS =
  "GET_PUBLISHED_TEMPLATES_SUCCESS";
export const GET_PUBLISHED_TEMPLATES_ERROR = "GET_PUBLISHED_TEMPLATES_ERROR";

export const CREATE_TOKEN_FORM_FROM_TEMPLATE_REQUEST =
  "CREATE_TOKEN_FORM_FROM_TEMPLATE_REQUEST";
export const CREATE_TOKEN_FORM_FROM_TEMPLATE_SUCCESS =
  "CREATE_TOKEN_FORM_FROM_TEMPLATE_SUCCESS";
export const CREATE_TOKEN_FORM_FROM_TEMPLATE_ERROR =
  "CREATE_TOKEN_FORM_FROM_TEMPLATE_ERROR";

export const RESET_TOKEN_FORM_PARAMS = "RESET_TOKEN_FORM_PARAMS";

export const UPLOAD_TOKEN_FORM_IMAGE_REQUEST =
  "UPLOAD_TOKEN_FORM_IMAGE_REQUEST";
export const UPLOAD_TOKEN_FORM_IMAGE_SUCCESS =
  "UPLOAD_TOKEN_FORM_IMAGE_SUCCESS";
export const UPLOAD_TOKEN_FORM_IMAGE_ERROR = "UPLOAD_TOKEN_FORM_IMAGE_ERROR";

export const UPLOAD_TOKEN_FORM_DOCUMENT_REQUEST =
  "UPLOAD_TOKEN_FORM_DOCUMENT_REQUEST";
export const UPLOAD_TOKEN_FORM_DOCUMENT_SUCCESS =
  "UPLOAD_TOKEN_FORM_DOCUMENT_SUCCESS";
export const UPLOAD_TOKEN_FORM_DOCUMENT_ERROR =
  "UPLOAD_TOKEN_FORM_DOCUMENT_ERROR";

export const EDIT_TOKEN_FORM_REQUEST = "EDIT_TOKEN_FORM_REQUEST";
export const EDIT_TOKEN_FORM_SUCCESS = "EDIT_TOKEN_FORM_SUCCESS";
export const EDIT_TOKEN_FORM_ERROR = "EDIT_TOKEN_FORM_ERROR";

export const GET_TOKEN_PROFILES_LIST_REQUEST =
  "GET_TOKEN_PROFILES_LIST_REQUEST";
export const GET_TOKEN_PROFILES_LIST_SUCCESS =
  "GET_TOKEN_PROFILES_LIST_SUCCESS";
export const GET_TOKEN_PROFILES_LIST_ERROR = "GET_TOKEN_PROFILES_LIST_ERROR";

export const SELECT_ASSET_PROFILE_REQUEST = "SELECT_ASSET_PROFILE_REQUEST";
export const SELECT_ASSET_PROFILE_SUCCESS = "SELECT_ASSET_PROFILE_SUCCESS";
export const SELECT_ASSET_PROFILE_ERROR = "SELECT_ASSET_PROFILE_ERROR";

export const PUBLISH_ASSET_PROFILE_REQUEST = "PUBLISH_ASSET_PROFILE_REQUEST";
export const PUBLISH_ASSET_PROFILE_SUCCESS = "PUBLISH_ASSET_PROFILE_SUCCESS";
export const PUBLISH_ASSET_PROFILE_ERROR = "PUBLISH_ASSET_PROFILE_ERROR";

export const SEARCH_ASSET_PROFILES_REQUEST = "SEARCH_ASSET_PROFILES_REQUEST";
export const SEARCH_ASSET_PROFILES_SUCCESS = "SEARCH_ASSET_PROFILES_SUCCESS";
export const SEARCH_ASSET_PROFILES_ERROR = "SEARCH_ASSET_PROFILES_ERROR";

export const DELETE_ASSET_PROFILE_REQUEST = "DELETE_ASSET_PROFILE_REQUEST";
export const DELETE_ASSET_PROFILE_SUCCESS = "DELETE_ASSET_PROFILE_SUCCESS";
export const DELETE_ASSET_PROFILE_ERROR = "DELETE_ASSET_PROFILE_ERROR";

export const UNPUBLISH_ASSET_PROFILE_REQUEST =
  "UNPUBLISH_ASSET_PROFILE_REQUEST";
export const UNPUBLISH_ASSET_PROFILE_SUCCESS =
  "UNPUBLISH_ASSET_PROFILE_SUCCESS";
export const UNPUBLISH_ASSET_PROFILE_ERROR = "UNPUBLISH_ASSET_PROFILE_ERROR";
export const RESET_UNPUBLISH_ASSET_PROFILE = "RESET_UNPUBLISH_ASSET_PROFILE";

export const UNPUBLISH_TOKEN_TEMPLATE_REQUEST =
  "UNPUBLISH_TOKEN_TEMPLATE_REQUEST";
export const UNPUBLISH_TOKEN_TEMPLATE_SUCCESS =
  "UNPUBLISH_TOKEN_TEMPLATE_SUCCESS";
export const UNPUBLISH_TOKEN_TEMPLATE_ERROR = "UNPUBLISH_TOKEN_TEMPLATE_ERROR";
export const RESET_UNPUBLISH_TOKEN_TEMPLATE = "RESET_UNPUBLISH_TOKEN_TEMPLATE";

export const CREATE_ASSET_PROFILE_REQUEST = "CREATE_ASSET_PROFILE_REQUEST";
export const CREATE_ASSET_PROFILE_SUCCESS = "CREATE_ASSET_PROFILE_SUCCESS";
export const CREATE_ASSET_PROFILE_ERROR = "CREATE_ASSET_PROFILE_ERROR";

export const SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_REQUEST =
  "SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_REQUEST";
export const SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_SUCCESS =
  "SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_SUCCESS";
export const SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_ERROR =
  "SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_ERROR";

export const GET_ASSET_PROFILE_EDITORS_REQUEST =
  "GET_ASSET_PROFILE_EDITORS_REQUEST";
export const GET_ASSET_PROFILE_EDITORS_SUCCESS =
  "GET_ASSET_PROFILE_EDITORS_SUCCESS";
export const GET_ASSET_PROFILE_EDITORS_ERROR =
  "GET_ASSET_PROFILE_EDITORS_ERROR";

export const ADD_EDITOR_TO_ASSET_PROFILE_REQUEST =
  "ADD_EDITOR_TO_ASSET_PROFILE_REQUEST";
export const ADD_EDITOR_TO_ASSET_PROFILE_SUCCESS =
  "ADD_EDITOR_TO_ASSET_PROFILE_SUCCESS";
export const ADD_EDITOR_TO_ASSET_PROFILE_ERROR =
  "ADD_EDITOR_TO_ASSET_PROFILE_ERROR";

export const SEARCH_USERS_FOR_ASSET_PROFILES_REQUEST =
  "SEARCH_USERS_FOR_ASSET_PROFILES_REQUEST";
export const SEARCH_USERS_FOR_ASSET_PROFILES_SUCCESS =
  "SEARCH_USERS_FOR_ASSET_PROFILES_SUCCESS";
export const SEARCH_USERS_FOR_ASSET_PROFILES_ERROR =
  "SEARCH_USERS_FOR_ASSET_PROFILES_ERROR";

export const RESET_SEARCH_USERS_AP_PARAMS = "RESET_SEARCH_USERS_AP_PARAMS";

export const ASSET_PROFILE_UPLOAD_FILE_REQUEST =
  "ASSET_PROFILE_UPLOAD_FILE_REQUEST";
export const ASSET_PROFILE_UPLOAD_FILE_SUCCESS =
  "ASSET_PROFILE_UPLOAD_FILE_SUCCESS";
export const ASSET_PROFILE_UPLOAD_FILE_ERROR =
  "ASSET_PROFILE_UPLOAD_FILE_ERROR";

export const ASSET_PROFILE_DELETE_FILE_REQUEST =
  "ASSET_PROFILE_DELETE_FILE_REQUEST";
export const ASSET_PROFILE_DELETE_FILE_SUCCESS =
  "ASSET_PROFILE_DELETE_FILE_SUCCESS";
export const ASSET_PROFILE_DELETE_FILE_ERROR =
  "ASSET_PROFILE_DELETE_FILE_ERROR";

export const UPLOAD_ASSET_PROFILE_IMAGE_REQUEST =
  "UPLOAD_ASSET_PROFILE_IMAGE_REQUEST";
export const UPLOAD_ASSET_PROFILE_IMAGE_SUCCESS =
  "UPLOAD_ASSET_PROFILE_IMAGE_SUCCESS";
export const UPLOAD_ASSET_PROFILE_IMAGE_ERROR =
  "UPLOAD_ASSET_PROFILE_IMAGE_ERROR";

export const DELETE_ASSET_PROFILE_IMAGE_REQUEST =
  "DELETE_ASSET_PROFILE_IMAGE_REQUEST";
export const DELETE_ASSET_PROFILE_IMAGE_SUCCESS =
  "DELETE_ASSET_PROFILE_IMAGE_SUCCESS";
export const DELETE_ASSET_PROFILE_IMAGE_ERROR =
  "DELETE_ASSET_PROFILE_IMAGE_ERROR";

export const USER_VIEW_ASSET_REQUEST = "USER_VIEW_ASSET_REQUEST";
export const USER_VIEW_ASSET_SUCCESS = "USER_VIEW_ASSET_SUCCESS";
export const USER_VIEW_ASSET_ERROR = "USER_VIEW_ASSET_ERROR";

export const GET_LINKS_FOR_ADMIN_TOPBAR_REQUEST =
  "GET_LINKS_FOR_ADMIN_TOPBAR_REQUEST";
export const GET_LINKS_FOR_ADMIN_TOPBAR_SUCCESS =
  "GET_LINKS_FOR_ADMIN_TOPBAR_SUCCESS";
export const GET_LINKS_FOR_ADMIN_TOPBAR_ERROR =
  "GET_LINKS_FOR_ADMIN_TOPBAR_ERROR";

export const UPDATE_ASSET_PROFILE_OFFERING_REQUEST =
  "UPDATE_ASSET_PROFILE_OFFERING_REQUEST";
export const UPDATE_ASSET_PROFILE_OFFERING_SUCCESS =
  "UPDATE_ASSET_PROFILE_OFFERING_SUCCESS";
export const UPDATE_ASSET_PROFILE_OFFERING_ERROR =
  "UPDATE_ASSET_PROFILE_OFFERING_ERROR";

export const UPLOAD_OFFERING_DOCUMENT_REQUEST =
  "UPLOAD_OFFERING_DOCUMENT_REQUEST";
export const UPLOAD_OFFERING_DOCUMENT_SUCCESS =
  "UPLOAD_OFFERING_DOCUMENT_SUCCESS";
export const UPLOAD_OFFERING_DOCUMENT_ERROR = "UPLOAD_OFFERING_DOCUMENT_ERROR";

export const CLEAR_OFFERING_DOCUMENT = "CLEAR_OFFERING_DOCUMENT";

export const ADMIN_IMPORT_FROM_EXCEL_REQUEST =
  "ADMIN_IMPORT_FROM_EXCEL_REQUEST";
export const ADMIN_IMPORT_FROM_EXCEL_SUCCESS =
  "ADMIN_IMPORT_FROM_EXCEL_SUCCESS";
export const ADMIN_IMPORT_FROM_EXCEL_ERROR = "ADMIN_IMPORT_FROM_EXCEL_ERROR";

export const ADMIN_CLONE_PROFILE_REQUEST = "ADMIN_CLONE_PROFILE_REQUEST";
export const ADMIN_CLONE_PROFILE_SUCCESS = "ADMIN_CLONE_PROFILE_SUCCESS";
export const ADMIN_CLONE_PROFILE_ERROR = "ADMIN_CLONE_PROFILE_ERROR";

export const ADMIN_CLONE_TEMPLATE_REQUEST = "ADMIN_CLONE_TEMPLATE_REQUEST";
export const ADMIN_CLONE_TEMPLATE_SUCCESS = "ADMIN_CLONE_TEMPLATE_SUCCESS";
export const ADMIN_CLONE_TEMPLATE_ERROR = "ADMIN_CLONE_TEMPLATE_ERROR";
