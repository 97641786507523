import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Spinner,
  Image,
  ListGroup,
  NavDropdown,
  Accordion,
  Dropdown
} from "react-bootstrap";

import { NavLink, Link } from "react-router-dom";
import {
  AiOutlineFileSearch,
  AiOutlineFundView,
  AiOutlineTeam,
  AiOutlineWarning,
  AiOutlineReconciliation,
  AiOutlineSolution,
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineUnorderedList,
  AiOutlineGlobal,
  AiOutlineWallet,
  AiOutlineThunderbolt,
  AiTwotoneExperiment
} from "react-icons/ai";

import { ImNewspaper } from "react-icons/im";
import { FiSearch, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaRegHandshake } from "react-icons/fa";
import { Switch, Route } from "react-router-dom";
import { toggleSidebar } from "../../redux/actions/options";
import LogoImg from "../../images/HomePage/FI_LOGO.png";
import LogoImgSmall from "../../images/HomePage/FI_LOGO_SMALL.png";

const mapStateToProps = state => ({
  sidebarOpened: state.options.sidebarOpened
});

const mapDispatchToProps = {
  toggleSidebar
};

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapseSidebar: false
    };
  }

  renderSidebarToggleButton = () => {
    let className = this.props.sidebarOpened
      ? "admin-sidebar-collapse-toggle-button flip"
      : "admin-sidebar-collapse-toggle-button";

    return (
      <Row noGutters className="admin-sidebar-collapse-toggle-row">
        <div
          onClick={this.handleToggleCollapseSidebar}
          className="admin-sidebar-collapse-toggle-aura"
        >
          <FiChevronRight className={className} />
        </div>
      </Row>
    );
  };

  handleToggleCollapseSidebar = () => {
    this.props.toggleSidebar();
  };

  render() {
    let currentSection = "";

    const sidebarClassName = this.props.sidebarOpened
      ? "new-sidebar"
      : "new-sidebar-closed";

    const width = this.props.sidebarOpened ? 1 : 12;

    const image = this.props.sidebarOpened ? LogoImg : LogoImgSmall;

    return (
      <div className={sidebarClassName}>
        <Col>
          <Row noGutters className="admin-logo-row">
            <NavLink to="/">
              <Image className="admin-logo" src={image} />
            </NavLink>
          </Row>

          <Row noGutters className="admin-system-row">
            <div className="system-header">SYSTEM</div>
          </Row>

          <ListGroup variant="flush" className="admin-list-group">
            <ListGroup.Item
              className="admin-list-item"
              active={currentSection === "asset-profiles"}
            >
              <NavLink to="/" className="admin-list-item-title">
                <Row noGutters>
                  <Col sm={width}>
                    <AiOutlineReconciliation className="admin-list-item-icon" />
                  </Col>
                  <Col>Contracts</Col>
                </Row>
              </NavLink>
            </ListGroup.Item>

            <ListGroup.Item
              className="admin-list-item"
              active={currentSection === "asset-profiles"}
            >
              <a
                href="https://dev-trade.usx.co.nz/"
                target="_blank"
                className="admin-list-item-title"
              >
                <Row noGutters>
                  <Col sm={width}>
                    <FaRegHandshake className="admin-list-item-icon" />
                  </Col>
                  <Col>Participate</Col>
                </Row>
              </a>
            </ListGroup.Item>
          </ListGroup>

          {this.renderSidebarToggleButton()}
        </Col>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
