import * as CONSTANTS from "../constants/tokenform";

export const getEquityTokenFormRequest = tokenData => ({
  type: CONSTANTS.EQUITY_TOKEN_FORM_REQUEST,
  tokenData
});

export const getEquityTokenFormSuccess = tokenResult => ({
  type: CONSTANTS.EQUITY_TOKEN_FORM_SUCCESS,
  tokenResult
});

export const getEquityTokenFormError = error => ({
  type: CONSTANTS.EQUITY_TOKEN_FORM_ERROR,
  error
});

// export const editTokenRequest = data => ({
//   type: CONSTANTS.EDIT_TOKEN_REQUEST,
//   data,
// })
//
// export const editTokenSuccess = response => ({
//   type: CONSTANTS.EDIT_TOKEN_SUCCESS,
//   response,
// });

export const editTokenError = error => ({
  type: CONSTANTS.EDIT_TOKEN_ERROR,
  error
});

export const getDebtSecurityTokenFormRequest = data => ({
  type: CONSTANTS.DEBT_SECURITY_TOKEN_FORM_REQUEST,
  data
});

export const getDebtSecurityTokenFormSuccess = tokenResult => ({
  type: CONSTANTS.DEBT_SECURITY_TOKEN_FORM_SUCCESS,
  tokenResult
});

export const getDebtSecurityTokenFormError = error => ({
  type: CONSTANTS.DEBT_SECURITY_TOKEN_FORM_ERROR,
  error
});

export const getRealEstateTokenFormRequest = data => ({
  type: CONSTANTS.REAL_ESTATE_TOKEN_FORM_REQUEST,
  data
});

export const getRealEstateTokenFormSuccess = tokenResult => ({
  type: CONSTANTS.REAL_ESTATE_TOKEN_FORM_SUCCESS,
  tokenResult
});

export const getRealEstateTokenFormError = error => ({
  type: CONSTANTS.REAL_ESTATE_TOKEN_FORM_ERROR,
  error
});

export const getFundSecurityFormRequest = data => ({
  type: CONSTANTS.FUND_SECURITY_FORM_REQUEST,
  data
});

export const getFundSecurityFormSuccess = tokenResult => ({
  type: CONSTANTS.FUND_SECURITY_FORM_SUCCESS,
  tokenResult
});

export const getFundSecurityFormError = error => ({
  type: CONSTANTS.FUND_SECURITY_FORM_ERROR,
  error
});

export const getTokenRequest = name => ({
  type: CONSTANTS.GET_TOKEN_REQUEST,
  name
});

export const getTokenEditRequest = name => ({
  type: CONSTANTS.GET_TOKEN_EDIT_REQUEST,
  name
});

export const getTokenSuccess = token => ({
  type: CONSTANTS.GET_TOKEN_SUCCESS,
  token
});

export const getTokenError = error => ({
  type: CONSTANTS.GET_TOKEN_ERROR,
  error
});

export const resetTokenForm = () => ({
  type: CONSTANTS.RESET_TOKEN_FORM_ITEMS
});

export const editDebtTokenRequest = data => ({
  type: CONSTANTS.EDIT_DEBT_TOKEN_REQUEST,
  data
});

export const editRealEstateTokenRequest = data => ({
  type: CONSTANTS.EDIT_REAL_ESTATE_TOKEN_REQUEST,
  data
});

export const editFundTokenRequest = data => ({
  type: CONSTANTS.EDIT_FUND_SECURITY_REQUEST,
  data
});

export const uploadImageRequest = image => ({
  type: CONSTANTS.UPLOAD_IMAGE_REQUEST,
  image
});

export const uploadImageSuccess = imageInfo => ({
  type: CONSTANTS.UPLOAD_IMAGE_SUCCESS,
  imageInfo
});

export const uploadImageError = error => ({
  type: CONSTANTS.UPLOAD_IMAGE_ERROR,
  error
});

export const uploadDocumentRequest = (file, fieldType, fileName) => ({
  type: CONSTANTS.UPLOAD_DOCUMENT_REQUEST,
  file,
  fieldType,
  fileName
});

export const uploadDocumentSuccess = (documentInfo, fieldType, fileName) => ({
  type: CONSTANTS.UPLOAD_DOCUMENT_SUCCESS,
  documentInfo,
  fieldType,
  fileName
});

export const uploadDocumentError = error => ({
  type: CONSTANTS.UPLOAD_DOCUMENT_ERROR,
  error
});

export const resetDocUrl = () => ({
  type: CONSTANTS.RESET_DOCUMENT_LINK
});

export const createTokenRequest = data => ({
  type: CONSTANTS.CREATE_TOKEN_REQUEST,
  data
});

export const createTokenSuccess = data => ({
  type: CONSTANTS.CREATE_TOKEN_SUCCESS,
  data
});

export const editTokenRequest = data => ({
  type: CONSTANTS.EDIT_TOKEN_REQUEST,
  data
});

export const editTokenSuccess = data => ({
  type: CONSTANTS.EDIT_TOKEN_SUCCESS,
  data
});

export const submitListingRequest = data => ({
  type: CONSTANTS.SUBMIT_LISTING_REQUEST,
  data
});

export const submitListingSuccess = () => ({
  type: CONSTANTS.SUBMIT_LISTING_SUCCESS
});

export const getListingRequestsRequest = offset => ({
  type: CONSTANTS.GET_LISTING_REQUESTS_REQUEST,
  offset
});

export const getListingRequestsSuccess = listings => ({
  type: CONSTANTS.GET_LISTING_REQUESTS_SUCCESS,
  listings
});

export const getTokenTemplatesRequest = params => ({
  type: CONSTANTS.GET_TOKEN_TEMPLATES_REQUEST,
  params
});

export const getTokenTemplatesSuccess = data => ({
  type: CONSTANTS.GET_TOKEN_TEMPLATES_SUCCESS,
  data
});

export const getTokenTemplatesError = error => ({
  type: CONSTANTS.GET_TOKEN_TEMPLATES_ERROR,
  error
});

export const editTokenTemplateRequest = template => ({
  type: CONSTANTS.EDIT_TOKEN_TEMPLATE_REQUEST,
  template
});

export const editTokenTemplateSuccess = () => ({
  type: CONSTANTS.EDIT_TOKEN_TEMPLATE_SUCCESS
});

export const editTokenTemplateError = error => ({
  type: CONSTANTS.EDIT_TOKEN_TEMPLATE_ERROR,
  error
});

export const getTokenTemplateToEditRequest = id => ({
  type: CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_REQUEST,
  id
});

export const getTokenTemplateToEditSuccess = template => ({
  type: CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_SUCCESS,
  template
});

export const getTokenTemplateToEditError = error => ({
  type: CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_ERROR,
  error
});

export const handleClearTokenTemplateToEdit = () => ({
  type: CONSTANTS.HANDLE_CLEAR_TOKEN_TEMPLATE_TO_EDIT
});

export const createTokenTemplateRequest = () => ({
  type: CONSTANTS.CREATE_TOKEN_TEMPLATE_REQUEST
});

export const createTokenTemplateSuccess = template => ({
  type: CONSTANTS.CREATE_TOKEN_TEMPLATE_SUCCESS,
  template
});

export const createTokenTemplateError = error => ({
  type: CONSTANTS.CREATE_TOKEN_TEMPLATE_ERROR,
  error
});

export const deleteTokenTemplateRequest = (id, params) => ({
  type: CONSTANTS.DELETE_TOKEN_TEMPLATE_REQUEST,
  id,
  params
});

export const deleteTokenTemplateSuccess = () => ({
  type: CONSTANTS.DELETE_TOKEN_TEMPLATE_SUCCESS
});

export const deleteTokenTemplateError = error => ({
  type: CONSTANTS.DELETE_TOKEN_TEMPLATE_ERROR
});

export const getPublishedTemplatesRequest = () => ({
  type: CONSTANTS.GET_PUBLISHED_TEMPLATES_REQUEST
});

export const getPublishedTemplatesSuccess = data => ({
  type: CONSTANTS.GET_PUBLISHED_TEMPLATES_SUCCESS,
  data
});

export const getPublishedTemplatesError = error => ({
  type: CONSTANTS.GET_PUBLISHED_TEMPLATES_ERROR,
  error
});

export const createTokenFormFromTemplateRequest = assetType => ({
  type: CONSTANTS.CREATE_TOKEN_FORM_FROM_TEMPLATE_REQUEST,
  assetType
});

export const createTokenFormFromTemplateSuccess = tokenForm => ({
  type: CONSTANTS.CREATE_TOKEN_FORM_FROM_TEMPLATE_SUCCESS,
  tokenForm
});

export const createTokenFormFromTemplateError = error => ({
  type: CONSTANTS.CREATE_TOKEN_FORM_FROM_TEMPLATE_ERROR,
  error
});

export const resetTokenFormParams = () => ({
  type: CONSTANTS.RESET_TOKEN_FORM_PARAMS
});

export const uploadTokenFormImageRequest = (
  image,
  fieldIndex,
  sectionIndex
) => ({
  type: CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_REQUEST,
  image,
  fieldIndex,
  sectionIndex
});

export const uploadTokenFormImageSuccess = (
  image,
  fieldIndex,
  sectionIndex
) => ({
  type: CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_SUCCESS,
  image,
  fieldIndex,
  sectionIndex
});

export const uploadTokenFormImageError = error => ({
  type: CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_ERROR,
  error
});

export const uploadTokenFormDocumentRequest = (
  file,
  fieldIndex,
  sectionIndex
) => ({
  type: CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_REQUEST,
  file,
  fieldIndex,
  sectionIndex
});

export const uploadTokenFormDocumentSuccess = (
  file,
  fieldIndex,
  sectionIndex
) => ({
  type: CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_SUCCESS,
  file,
  fieldIndex,
  sectionIndex
});

export const uploadTokenFormDocumentError = error => ({
  type: CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_ERROR,
  error
});

export const editTokenFormRequest = form => ({
  type: CONSTANTS.EDIT_TOKEN_FORM_REQUEST,
  form
});

export const editTokenFormSuccess = () => ({
  type: CONSTANTS.EDIT_TOKEN_FORM_SUCCESS
});

export const editTokenFormError = error => ({
  type: CONSTANTS.EDIT_TOKEN_FORM_ERROR,
  error
});

export const getTokenProfilesListRequest = params => ({
  type: CONSTANTS.GET_TOKEN_PROFILES_LIST_REQUEST,
  params
});

export const getTokenProfilesListSuccess = data => ({
  type: CONSTANTS.GET_TOKEN_PROFILES_LIST_SUCCESS,
  data
});

export const getTokenProfilesListError = error => ({
  type: CONSTANTS.GET_TOKEN_PROFILES_LIST_ERROR,
  error
});

export const selectAssetProfileRequest = profileId => ({
  type: CONSTANTS.SELECT_ASSET_PROFILE_REQUEST,
  profileId
});

export const selectAssetProfileSuccess = tokenForm => ({
  type: CONSTANTS.SELECT_ASSET_PROFILE_SUCCESS,
  tokenForm
});

export const selectAssetProfileError = error => ({
  type: CONSTANTS.SELECT_ASSET_PROFILE_ERROR,
  error
});

export const publishAssetProfileRequest = profileId => ({
  type: CONSTANTS.PUBLISH_ASSET_PROFILE_REQUEST,
  profileId
});

export const publishAssetProfileSuccess = () => ({
  type: CONSTANTS.PUBLISH_ASSET_PROFILE_SUCCESS
});

export const publishAssetProfileError = error => ({
  type: CONSTANTS.PUBLISH_ASSET_PROFILE_ERROR,
  error
});

export const searchAssetProfilesRequest = params => ({
  type: CONSTANTS.SEARCH_ASSET_PROFILES_REQUEST,
  params
});

export const searchAssetProfilesSuccess = data => ({
  type: CONSTANTS.SEARCH_ASSET_PROFILES_SUCCESS,
  data
});

export const searchAssetProfilesError = error => ({
  type: CONSTANTS.SEARCH_ASSET_PROFILES_ERROR,
  error
});

export const deleteAssetProfileRequest = (profileId, params) => ({
  type: CONSTANTS.DELETE_ASSET_PROFILE_REQUEST,
  profileId,
  params
});

export const deleteAssetProfileSuccess = () => ({
  type: CONSTANTS.DELETE_ASSET_PROFILE_SUCCESS
});

export const deleteAssetProfileError = error => ({
  type: CONSTANTS.DELETE_ASSET_PROFILE_ERROR,
  error
});

export const unpublishAssetProfileRequest = profileId => ({
  type: CONSTANTS.UNPUBLISH_ASSET_PROFILE_REQUEST,
  profileId
});

export const unpublishAssetProfileSuccess = profileId => ({
  type: CONSTANTS.UNPUBLISH_ASSET_PROFILE_SUCCESS,
  profileId
});

export const unpublishAssetProfileError = error => ({
  type: CONSTANTS.UNPUBLISH_ASSET_PROFILE_ERROR,
  error
});

export const unpublishTokenTemplateRequest = id => ({
  type: CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_REQUEST,
  id
});

export const unpublishTokenTemplateSuccess = () => ({
  type: CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_SUCCESS
});

export const unpublishTokenTemplateError = error => ({
  type: CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_ERROR,
  error
});

export const resetUnpublishAssetProfile = () => ({
  type: CONSTANTS.RESET_UNPUBLISH_ASSET_PROFILE
});

export const resetUnpublishTokenTemplate = () => ({
  type: CONSTANTS.RESET_UNPUBLISH_TOKEN_TEMPLATE
});

export const createAssetProfileRequest = id => ({
  type: CONSTANTS.CREATE_ASSET_PROFILE_REQUEST,
  id
});

export const createAssetProfileSuccess = data => ({
  type: CONSTANTS.CREATE_ASSET_PROFILE_SUCCESS,
  data
});

export const createAssetProfileError = error => ({
  type: CONSTANTS.CREATE_ASSET_PROFILE_ERROR,
  error
});

export const submitAssetProfileForVerificationRequest = (
  profileId,
  userId
) => ({
  type: CONSTANTS.SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_REQUEST,
  profileId,
  userId
});

export const submitAssetProfileForVerificationSuccess = () => ({
  type: CONSTANTS.SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_SUCCESS
});

export const submitAssetProfileForVerificationError = error => ({
  type: CONSTANTS.SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_ERROR,
  error
});

export const getAssetProfileEditorsRequest = profileId => ({
  type: CONSTANTS.GET_ASSET_PROFILE_EDITORS_REQUEST,
  profileId
});

export const getAssetProfileEditorsSuccess = data => ({
  type: CONSTANTS.GET_ASSET_PROFILE_EDITORS_SUCCESS,
  data
});

export const getAssetProfileEditorsError = error => ({
  type: CONSTANTS.GET_ASSET_PROFILE_EDITORS_ERROR,
  error
});

export const addEditorToAssetProfileRequest = (userId, profileId) => ({
  type: CONSTANTS.ADD_EDITOR_TO_ASSET_PROFILE_REQUEST,
  userId,
  profileId
});

export const addEditorToAssetProfileSuccess = data => ({
  type: CONSTANTS.ADD_EDITOR_TO_ASSET_PROFILE_SUCCESS,
  data
});

export const addEditorToAssetProfileError = error => ({
  type: CONSTANTS.ADD_EDITOR_TO_ASSET_PROFILE_ERROR,
  error
});

export const searchUsersForAssetProfilesRequest = query => ({
  type: CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_REQUEST,
  query
});

export const searchUsersForAssetProfilesSuccess = data => ({
  type: CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_SUCCESS,
  data
});

export const searchUsersForAssetProfilesError = error => ({
  type: CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_ERROR,
  error
});

export const resetSearchUsersAPParams = () => ({
  type: CONSTANTS.RESET_SEARCH_USERS_AP_PARAMS
});

export const publishTokenTemplateRequest = id => ({
  type: CONSTANTS.PUBLISH_TOKEN_TEMPLATE_REQUEST,
  id
});

export const publishTokenTemplateSuccess = () => ({
  type: CONSTANTS.PUBLISH_TOKEN_TEMPLATE_SUCCESS
});

export const publishTokenTemplateError = error => ({
  type: CONSTANTS.PUBLISH_TOKEN_TEMPLATE_ERROR,
  error
});

export const assetProfileUploadFileRequest = (
  fileData,
  formData,
  fieldIndex,
  sectionIndex,
  subfieldIndex,
  objectSubfieldIndex
) => ({
  type: CONSTANTS.ASSET_PROFILE_UPLOAD_FILE_REQUEST,
  fileData,
  formData,
  fieldIndex,
  sectionIndex,
  subfieldIndex,
  objectSubfieldIndex
});

export const assetProfileUploadFileSuccess = fileUrl => ({
  type: CONSTANTS.ASSET_PROFILE_UPLOAD_FILE_SUCCESS,
  fileUrl
});

export const assetProfileUploadFileError = error => ({
  type: CONSTANTS.ASSET_PROFILE_UPLOAD_FILE_ERROR,
  error
});

export const assetProfileDeleteFileRequest = (
  url,
  formData,
  fieldIndex,
  sectionIndex,
  subfieldIndex,
  objectSubfieldIndex
) => ({
  type: CONSTANTS.ASSET_PROFILE_DELETE_FILE_REQUEST,
  url,
  formData,
  fieldIndex,
  sectionIndex,
  subfieldIndex,
  objectSubfieldIndex
});

export const assetProfileDeleteFileSuccess = () => ({
  type: CONSTANTS.ASSET_PROFILE_DELETE_FILE_SUCCESS
});

export const assetProfileDeleteFileError = error => ({
  type: CONSTANTS.ASSET_PROFILE_DELETE_FILE_ERROR,
  error
});

export const uploadAssetProfileImageRequest = (
  imageData,
  formData,
  position
) => ({
  type: CONSTANTS.UPLOAD_ASSET_PROFILE_IMAGE_REQUEST,
  imageData,
  formData,
  position
});

export const uploadAssetProfileImageSuccess = imageUrl => ({
  type: CONSTANTS.UPLOAD_ASSET_PROFILE_IMAGE_SUCCESS,
  imageUrl
});

export const uploadAssetProfileImageError = error => ({
  type: CONSTANTS.UPLOAD_ASSET_PROFILE_IMAGE_ERROR,
  error
});

export const deleteAssetProfileImageRequest = (url, formData, position) => ({
  type: CONSTANTS.DELETE_ASSET_PROFILE_IMAGE_REQUEST,
  url,
  formData,
  position
});

export const deleteAssetProfileImageSuccess = () => ({
  type: CONSTANTS.DELETE_ASSET_PROFILE_IMAGE_SUCCESS
});

export const deleteAssetProfileImageError = error => ({
  type: CONSTANTS.DELETE_ASSET_PROFILE_IMAGE_ERROR,
  error
});

export const userViewAssetRequest = id => ({
  type: CONSTANTS.USER_VIEW_ASSET_REQUEST,
  id
});

export const userViewAssetSuccess = data => ({
  type: CONSTANTS.USER_VIEW_ASSET_SUCCESS,
  data
});

export const userViewAssetError = error => ({
  type: CONSTANTS.USER_VIEW_ASSET_ERROR,
  error
});

export const getLinksForAdminTopbarRequest = () => ({
  type: CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_REQUEST
});

export const getLinksForAdminTopbarSuccess = data => ({
  type: CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_SUCCESS,
  data
});

export const getLinksForAdminTopbarError = error => ({
  type: CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_ERROR,
  error
});

export const updateAssetProfileOfferingRequest = (id, offering) => ({
  type: CONSTANTS.UPDATE_ASSET_PROFILE_OFFERING_REQUEST,
  id,
  offering
});

export const updateAssetProfileOfferingSuccess = () => ({
  type: CONSTANTS.UPDATE_ASSET_PROFILE_OFFERING_SUCCESS
});

export const updateAssetProfileOfferingError = error => ({
  type: CONSTANTS.UPDATE_ASSET_PROFILE_OFFERING_ERROR,
  error
});

export const uploadOfferingDocumentRequest = (file, location, formData) => ({
  type: CONSTANTS.UPLOAD_OFFERING_DOCUMENT_REQUEST,
  file,
  location,
  formData
});

export const uploadOfferingDocumentSuccess = data => ({
  type: CONSTANTS.UPLOAD_OFFERING_DOCUMENT_SUCCESS,
  data
});

export const uploadOfferingDocumentError = error => ({
  type: CONSTANTS.UPLOAD_OFFERING_DOCUMENT_ERROR,
  error
});

export const clearOfferingDocument = (url, location, formData) => ({
  type: CONSTANTS.CLEAR_OFFERING_DOCUMENT,
  url,
  location,
  formData
});

/***********************************************************/

export const importFromExcelRequest = (data, params) => ({
  type: CONSTANTS.ADMIN_IMPORT_FROM_EXCEL_REQUEST,
  data,
  params
});

export const importFromExcelSuccess = data => ({
  type: CONSTANTS.ADMIN_IMPORT_FROM_EXCEL_SUCCESS,
  data
});

export const importFromExcelError = error => ({
  type: CONSTANTS.ADMIN_IMPORT_FROM_EXCEL_ERROR,
  error
});

/***********************************************************/

export const cloneProfileRequest = id => ({
  type: CONSTANTS.ADMIN_CLONE_PROFILE_REQUEST,
  id
});

export const cloneProfileSuccess = data => ({
  type: CONSTANTS.ADMIN_CLONE_PROFILE_SUCCESS,
  data
});

export const cloneProfileError = error => ({
  type: CONSTANTS.ADMIN_CLONE_PROFILE_ERROR,
  error
});

/***********************************************************/

export const cloneTemplateRequest = id => ({
  type: CONSTANTS.ADMIN_CLONE_TEMPLATE_REQUEST,
  id
});

export const cloneTemplateSuccess = data => ({
  type: CONSTANTS.ADMIN_CLONE_TEMPLATE_SUCCESS,
  data
});

export const cloneTemplateError = error => ({
  type: CONSTANTS.ADMIN_CLONE_TEMPLATE_ERROR,
  error
});
