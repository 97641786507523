import { combineReducers } from "redux";
import landingReducer from "./landingPage";
import loginReducer from "./login";
import forgotPasswordReducer from "./forgotpassword";
import resetPasswordReducer from "./resetpassword";
import newsReducer from "./news";
import contactReducer from "./contact";
import accountReducer from "./account";
import tokenReducer from "./tokenlist";
import tokenFormReducer from "./tokenform";
import suggestionReducer from "./suggestion";
import contributorsReducer from "./contributor";
import reportsReducer from "./research";
import researchReducer from "./researches";
import websiteReducer from "./website";
import analyticsReducer from "./analytics";
import collectionsReducer from "./collections";
import discoverReducer from "./discover";
import adminReducer from "./admin";
import optionsReducer from "./options";
import announcementsReducer from "./announcements";
// import chartsReducer from './charts';
import { CLEAR_USER_CACHE } from "../constants/account";
import { LOGOUT, LOGIN_ERROR } from "../constants/login";

const appReducer = combineReducers({
  landing: landingReducer,
  login: loginReducer,
  forgot: forgotPasswordReducer,
  reset: resetPasswordReducer,
  news: newsReducer,
  contact: contactReducer,
  account: accountReducer,
  token: tokenFormReducer,
  tokens: tokenReducer,
  suggestions: suggestionReducer,
  contributors: contributorsReducer,
  reports: reportsReducer,
  research: researchReducer,
  website: websiteReducer,
  analytics: analyticsReducer,
  collections: collectionsReducer,
  discover: discoverReducer,
  admin: adminReducer,
  options: optionsReducer,
  announcements: announcementsReducer
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_USER_CACHE || action.type == LOGOUT) {
    console.log(`Constant ${action.type} was called. Clearing the cache...`);
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
