import * as CONSTANTS from "../constants/announcements";

export const createAnnouncementRequest = () => ({
  type: CONSTANTS.CREATE_ANNOUNCEMENT_REQUEST
});

export const createAnnouncementSuccess = data => ({
  type: CONSTANTS.CREATE_ANNOUNCEMENT_SUCCESS,
  data
});

export const createAnnouncementError = error => ({
  type: CONSTANTS.CREATE_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const deleteAnnouncementRequest = (_id, params) => ({
  type: CONSTANTS.DELETE_ANNOUNCEMENT_REQUEST,
  _id,
  params
});

export const deleteAnnouncementSuccess = data => ({
  type: CONSTANTS.DELETE_ANNOUNCEMENT_SUCCESS,
  data
});

export const deleteAnnouncementError = error => ({
  type: CONSTANTS.DELETE_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const editAnnouncementRequest = data => ({
  type: CONSTANTS.EDIT_ANNOUNCEMENT_REQUEST,
  data
});

export const editAnnouncementSuccess = data => ({
  type: CONSTANTS.EDIT_ANNOUNCEMENT_SUCCESS,
  data
});

export const editAnnouncementError = error => ({
  type: CONSTANTS.EDIT_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const publishAnnouncementRequest = (_id, params) => ({
  type: CONSTANTS.PUBLISH_ANNOUNCEMENT_REQUEST,
  _id,
  params
});

export const publishAnnouncementSuccess = data => ({
  type: CONSTANTS.PUBLISH_ANNOUNCEMENT_SUCCESS,
  data
});

export const publishAnnouncementError = error => ({
  type: CONSTANTS.PUBLISH_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const unpublishAnnouncementRequest = (_id, params) => ({
  type: CONSTANTS.UNPUBLISH_ANNOUNCEMENT_REQUEST,
  _id,
  params
});

export const unpublishAnnouncementSuccess = data => ({
  type: CONSTANTS.UNPUBLISH_ANNOUNCEMENT_SUCCESS,
  data
});

export const unpublishAnnouncementError = error => ({
  type: CONSTANTS.UNPUBLISH_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const searchAnnouncementsRequest = params => ({
  type: CONSTANTS.SEARCH_ANNOUNCEMENTS_REQUEST,
  params
});

export const searchAnnouncementsSuccess = data => ({
  type: CONSTANTS.SEARCH_ANNOUNCEMENTS_SUCCESS,
  data
});

export const searchAnnouncementsError = error => ({
  type: CONSTANTS.SEARCH_ANNOUNCEMENTS_ERROR,
  error
});

/*******************************************************************************/

export const getAnnouncementRequest = _id => ({
  type: CONSTANTS.GET_ANNOUNCEMENT_REQUEST,
  _id
});

export const getAnnouncementSuccess = data => ({
  type: CONSTANTS.GET_ANNOUNCEMENT_SUCCESS,
  data
});

export const getAnnouncementError = error => ({
  type: CONSTANTS.GET_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const clearAnnouncements = () => ({
  type: CONSTANTS.CLEAR_ANNOUNCEMENTS
});

export const clearCurrentAnnouncement = () => ({
  type: CONSTANTS.CLEAR_CURRENT_ANNOUNCEMENT
});

/*******************************************************************************/

export const attachFileToAnnouncementRequest = (file, formData) => ({
  type: CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_REQUEST,
  file,
  formData
});

export const attachFileToAnnouncementSuccess = file => ({
  type: CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_SUCCESS,
  file
});

export const attachFileToAnnouncementError = error => ({
  type: CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_ERROR,
  error
});

/*******************************************************************************/

export const removeFileFromAnnouncementRequest = (url, formData, index) => ({
  type: CONSTANTS.REMOVE_FILE_FROM_ANNOUNCEMENT_REQUEST,
  url,
  formData,
  index
});

export const removeFileFromAnnouncementSuccess = () => ({
  type: CONSTANTS.REMOVE_FILE_FROM_ANNOUNCEMENT_SUCCESS
});

export const removeFileFromAnnouncementError = error => ({
  type: CONSTANTS.REMOVE_FILE_FROM_ANNOUNCEMENT_ERROR,
  error
});
