import { all, fork } from "redux-saga/effects";

import loginSaga from "./watchers/login";
import signupSaga from "./watchers/signup";
import contactSaga from "./watchers/contact";
import forgotPasswordSaga from "./watchers/forgotpassword";
import resetPasswordSaga from "./watchers/resetpassword";
import newsFeedListSaga from "./watchers/news";
import myAccountSaga from "./watchers/account";
import tokenFormSaga from "./watchers/tokenform";
import tokenListSaga from "./watchers/tokenlist";
import suggestionFormSaga from "./watchers/suggestion";
import contributorSaga from "./watchers/contributor";
import researchSaga from "./watchers/research";
import researchsSaga from "./watchers/researches";
import chartSaga from "./watchers/charts";
import landingPageSaga from "./watchers/landingPage";
import websiteSaga from "./watchers/website";
import analyticsSaga from "./watchers/analytics";
import collectionsSaga from "./watchers/collections";
import discoverSaga from "./watchers/discover";
import adminSaga from "./watchers/admin";
import announcementsSaga from "./watchers/announcements";

export default function* root() {
  yield all([
    fork(loginSaga),
    fork(signupSaga),
    fork(contactSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(newsFeedListSaga),
    fork(myAccountSaga),
    fork(tokenFormSaga),
    fork(tokenListSaga),
    fork(suggestionFormSaga),
    fork(contributorSaga),
    fork(researchSaga),
    fork(researchsSaga),
    fork(chartSaga),
    fork(landingPageSaga),
    fork(websiteSaga),
    fork(analyticsSaga),
    fork(collectionsSaga),
    fork(discoverSaga),
    fork(adminSaga),
    fork(announcementsSaga)
  ]);
}
