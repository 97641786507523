import React, { Component } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Image,
  FormControl,
  Spinner,
  Table,
  Modal,
  NavLink,
  Pagination
} from "react-bootstrap";
import ReactLoading from "react-loading";
import ReactHtmlParser from "react-html-parser";
import he from "he";
import { history } from "../../../configureStore";
import "./Research.css";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSearch,
  AiOutlineReload
} from "react-icons/ai";
import stockImage from "../../../images/stockimage.png";

class Research extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        author: "",
        title: "",
        status: ""
      },
      users: this.props.users,
      reports: this.props.reports,
      currentModal: "",
      deleteDraftInfo: {},
      currentPage: 1
    };
  }

  componentDidMount() {
    this.handleInitiatePage();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        users: nextProps.users,
        reports: nextProps.reports
      });
    }
  }

  handleInitiatePage = () => {
    this.props.searchUsersClear();
    this.props.searchReportsClear();
    this.props.searchReportsRequest(this.state.params);
  };

  handleChangeParamsSearchUsers = e => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value
        }
      },
      () => this.props.searchUsersRequest(this.state.params)
    );
  };

  handleChangeParamsSearchReports = e => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value
        }
      },
      () => this.props.searchReportsRequest(this.state.params)
    );
  };

  handleShowConfirmDeleteModal = (id, title) => {
    this.setState({
      currentModal: "showConfirmDeleteModal",
      deleteDraftInfo: { id, title }
    });
  };

  handleHideConfirmDeleteModal = () => {
    this.setState({
      currentModal: "",
      deleteDraftInfo: {}
    });
  };

  handleDeleteDraft = () => {
    const draftId = this.state.deleteDraftInfo.id;
    const userId = this.props.accountData.data.userId;
    const params = this.state.params;
    this.handleHideConfirmDeleteModal();
    this.props.deleteDraftRequest(draftId, userId, params);
  };

  handleSelectUser = id => {
    this.setState(
      {
        params: {
          ...this.state.params,
          userId: id,
          author: id
        }
      },
      () => this.props.searchReportsRequest(this.state.params)
    );
  };

  handleSelectDraft = id => {
    this.props.history.push(`/admin/create-a-report?id=${id}`);
  };

  handleImageError = e => {
    e.target.src = stockImage;
  };

  handleReset = () => {
    this.setState(
      {
        params: {
          author: "",
          title: "",
          status: ""
        }
      },
      () => this.handleInitiatePage()
    );
  };

  handleUnpublish = articleId => {
    const userId = this.props.accountData.data.userId;
    this.props.unpublishReportRequest(articleId, userId, this.state.params);
  };

  handleLinkToReport = url => {
    this.props.history.push(url);
  };

  handleClickPage = number => {
    this.setState(
      {
        currentPage: number,
        params: {
          ...this.state.params,
          offset: (number - 1) * 10
        }
      },
      () => this.props.searchReportsRequest(this.state.params)
    );
  };

  /********************************************************************/

  renderSearchbar = () => {
    const { author, title, status } = this.state.params;

    return (
      <Row noGutters className="submissions-search-bar">
        {this.renderResetButton()}
        <Col>
          <label className="submission-form-control-label">Author:</label>
          <FormControl
            size="sm"
            onChange={this.handleChangeParamsSearchUsers}
            name="author"
            value={author}
            onChange={this.handleChangeParamsSearchUsers}
            className="submission-form-control"
          />
        </Col>
        <Col>
          <label className="submission-form-control-label">Title:</label>
          <FormControl
            size="sm"
            onChange={this.handleChangeParamsSearchReports}
            name="title"
            value={title}
            className="submission-form-control"
          />
        </Col>
        <Col>
          <label className="submission-form-control-label">Status:</label>
          <FormControl
            size="sm"
            onChange={this.handleChangeParamsSearchReports}
            name="status"
            as="select"
            value={status}
            className="submission-form-control"
          >
            <option value="">Select a Status...</option>
            <option value="true">Published</option>
            <option value="false">Draft</option>
          </FormControl>
        </Col>
      </Row>
    );
  };

  renderResetButton = () => {
    return (
      <AiOutlineReload
        onClick={this.handleReset}
        className="individual-form-icon reset-icon"
      />
    );
  };

  renderUsers = () => {
    const { data, loaded, loading } = this.state.users;
    let content;
    if (loaded) {
      if (data.total > 0) {
        content = data.docs.map(user => this.renderUser(user));
      } else {
        content = (
          <Row noGutters className="no-users-found-row">
            No users found
          </Row>
        );
      }
    } else if (loading) {
      content = this.renderUsersLoading();
    }
    return (
      <Row noGutters className="view-reports-users-row">
        {content}
      </Row>
    );
  };

  renderUser = user => {
    const { email, _id, role } = user;

    let className;
    if (_id === this.state.params.userId) {
      className = "submission-user-selector selected";
    } else {
      className = "submission-user-selector";
    }

    return (
      <div
        key={_id}
        onClick={() => this.handleSelectUser(_id)}
        className={className}
      >
        {_id}
      </div>
    );
  };

  renderUsersLoading = () => (
    <Row noGutters className="no-users-found-row">
      <Spinner
        className="asset-profile-loading-spinner"
        animation="border"
        role="status"
        variant="primary"
      />
    </Row>
  );

  renderTable = () => {
    if (!this.state.reports) return;

    const { data, loaded, loading } = this.state.reports;
    let content;

    if (loading) {
      content = (
        <tr>
          <td colSpan="4">
            <Row noGutters className="table-loading-row">
              <Spinner
                className="asset-profile-loading-spinner"
                animation="border"
              />
            </Row>
          </td>
        </tr>
      );
    } else if (loaded) {
      if (data.total > 0) {
        content = data.docs.map(report => this.renderReport(report));
      } else {
        content = (
          <tr>
            <td colSpan="4">
              <Row noGutters className="table-loading-row">
                No reports found
              </Row>
            </td>
          </tr>
        );
      }
    }

    return (
      <Table bordered className="admin-table">
        <thead>
          <tr>
            <th className="common-image-th">Image</th>
            <th>Title</th>
            <th>Author</th>
            <th>Published</th>
          </tr>
        </thead>

        <tbody>{content}</tbody>
      </Table>
    );
  };

  renderReport = report => {
    const { image, title, user_id, published, _id } = report;

    let isPublished;
    let buttons;
    let renderTitle;
    let url;
    if (published) {
      const navName = title.replace(/\s+/g, "-").toLowerCase() + "-" + _id;
      url = `/research/${navName}`;
      isPublished = "Published";
      buttons = (
        <div>
          <AiOutlineSearch
            onClick={() => this.handleLinkToReport(url)}
            className="individual-form-icon"
          />
          <Button
            onClick={() => this.handleUnpublish(_id)}
            className="teal-button table-button"
            size="sm"
          >
            Unpublish
          </Button>
        </div>
      );
      renderTitle = (
        <NavLink className="table-navlink" to={url}>
          {title}
        </NavLink>
      );
    } else {
      isPublished = "Draft";
      buttons = (
        <div>
          <AiOutlineEdit
            onClick={() => this.handleSelectDraft(_id)}
            className="individual-form-icon"
          />
          <AiOutlineDelete
            onClick={() => this.handleShowConfirmDeleteModal(_id, title)}
            className="individual-form-icon"
          />
        </div>
      );
      renderTitle = title;
    }

    return (
      <tr key={_id}>
        <td>
          <Image
            src={image || stockImage}
            onError={this.handleImageError}
            className="common-table-image"
          />
        </td>
        <td>{renderTitle || "Untitled Report"}</td>
        <td>{user_id._id}</td>
        <td>
          <Row noGutters className="td-row">
            {isPublished}
            {buttons}
          </Row>
        </td>
      </tr>
    );
  };

  renderConfirmDeleteModal = () => {
    const { currentModal, deleteDraftInfo } = this.state;
    const show = currentModal == "showConfirmDeleteModal" ? true : false;

    const displayName = deleteDraftInfo.title
      ? deleteDraftInfo.title
      : deleteDraftInfo.id;
    return (
      <Modal
        centered
        className="admin-modal"
        show={show}
        onHide={this.handleHideConfirmDeleteModal}
      >
        <Modal.Header closeButton>Delete Draft</Modal.Header>

        <Modal.Body>
          <Row noGutters className="confirm-delete-modal-row">
            Are you sure you want to delete this draft?
          </Row>

          <Row noGutters className="confirm-delete-modal-row email-row">
            <b>{displayName}</b>
          </Row>

          <Row noGutters className="confirm-delete-modal-row">
            <Button
              onClick={this.handleDeleteDraft}
              className="delete-button"
              size="sm"
            >
              Delete
            </Button>

            <Button
              onClick={this.handleHideConfirmDeleteModal}
              className="teal-button"
              size="sm"
            >
              Cancel
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  renderPagination = () => {
    if (!this.state.reports) return;
    if (this.state.reports.loaded) {
      const { docs, limit, total } = this.state.reports.data;
      const { currentPage } = this.state;
      const numberOfPages = Math.ceil(total / limit);
      let pages = [];

      for (let number = 1; number <= numberOfPages; number++) {
        pages.push(
          <Pagination.Item
            onClick={() => this.handleClickPage(number)}
            key={number}
            active={number === currentPage}
          >
            {number}
          </Pagination.Item>
        );
      }

      const first =
        Math.abs(currentPage - 1) > 3 ? (
          <Pagination.First onClick={() => this.handleClickPage(1)} />
        ) : (
          ""
        );
      const last =
        Math.abs(currentPage - numberOfPages) > 3 ? (
          <Pagination.Last
            onClick={() => this.handleClickPage(numberOfPages)}
          />
        ) : (
          ""
        );

      const next =
        currentPage !== numberOfPages ? (
          <Pagination.Next
            onClick={() => this.handleClickPage(currentPage + 1)}
          />
        ) : (
          ""
        );
      const prev =
        currentPage !== 1 ? (
          <Pagination.Prev
            onClick={() => this.handleClickPage(currentPage - 1)}
          />
        ) : (
          ""
        );

      return (
        <Row noGutters className="pagination-row">
          <Pagination size="sm" className="common-pagination">
            {first}
            {prev}
            {pages}
            {next}
            {last}
          </Pagination>
        </Row>
      );
    }
  };

  render() {
    return (
      <Container fluid className="admin-content-container">
        {this.renderConfirmDeleteModal()}
        <Row noGutters className="forms-header">
          <h4>Reports</h4>
        </Row>
        <div className="forms-table-box">
          {this.renderSearchbar()}
          {this.renderUsers()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </Container>
    );
  }
}

export default Research;
