import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { searchAnnouncementsRequest } from "../../../redux/actions/announcements.js";

const mapStateToProps = state => ({
  announcements: state.announcements.announcements
});

const mapDispatchToProps = { searchAnnouncementsRequest };

class AnnouncementsTab extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getAnnouncements();
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  };

  getAnnouncements = () => {
    const { name, tradedSymbol } = this.props.data;
    const params = {
      categories: [name],
      published: true
    };
    this.props.searchAnnouncementsRequest(params);
  };

  renderAnnouncements = () => {
    const { loaded, loading, data } = this.props.announcements;
    let content = <div>test</div>;
    if (loaded) {
      if (data.total > 0) {
        const reversedData = data.docs.reverse();
        content = reversedData.map((item, index) =>
          this.renderAnnouncement(item, index)
        );
      } else {
        content = this.renderNoAnnouncements();
      }
    } else if (loading) {
      content = this.renderLoading();
    }

    return content;
  };

  renderLoading = () => {
    return (
      <Row noGutters className="create-report-loading-row">
        <Spinner
          animation="border"
          variant="light"
          className="create-report-spinner"
        />
      </Row>
    );
  };

  renderAnnouncement = (item, index) => {
    const { _id, user_id, title, datePublished } = item;
    return (
      <div className="rendered-announcement">
        <NavLink to={`/announcements/${_id}`}>
          <h5 className="announcement-title">{title}</h5>
        </NavLink>

        <Row noGutters style={{ marginBottom: "16px" }}>
          <div className="announcement-info" style={{ marginRight: "16px" }}>
            {this.formatDate(datePublished)}
          </div>
          <div className="announcement-info">{user_id._id}</div>
        </Row>
        <NavLink to={`/announcements/${_id}`}>
          <Button className="teal-button" size="sm">
            Read More
            <AiOutlineArrowRight style={{ marginLeft: "4px" }} />
          </Button>
        </NavLink>
      </div>
    );
  };

  renderNoAnnouncements = () => (
    <div className="no-announcements">
      There are no announcements for this asset
    </div>
  );

  render() {
    return (
      <div
        style={{
          backgroundColor: "white",
          alignItems: "center",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        {this.renderAnnouncements()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementsTab);
