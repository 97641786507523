import { connect } from "react-redux";
import Admin from "./Admin";
import TokenTemplates from "./TokenTemplates";
import UserLookUp from "./UserLookUp";
import TradedSymbols from "./TradedSymbols";
import AssetProfiles from "./AssetProfiles";
import Research from "./Research";
import CreateReport from "./CreateReport";
import NotFound from "../NotFound";
import WebsiteConfig from "./WebsiteConfig";
import Announcements from "./Announcements/Announcements.js";
import CreateAnnouncement from "./CreateAnnouncement/CreateAnnouncement.js";
import { logout } from "../../redux/actions/login";
import { getLinksForAdminTopbarRequest } from "../../redux/actions/tokenform";

const mapStateToProps = state => ({
  adminTopbarLinks: state.token.adminTopbarLinks,
  account: state.login.accountData
});

const mapDispatchToProps = {
  getLinksForAdminTopbarRequest,
  logout
};

// these are the pieces that get rendered via routes in the Admin component
export {
  TokenTemplates,
  UserLookUp,
  AssetProfiles,
  TradedSymbols,
  Research,
  CreateReport,
  NotFound,
  WebsiteConfig,
  Announcements,
  CreateAnnouncement
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
