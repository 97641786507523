import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import he from "he";
import {
  getAnnouncementRequest,
  clearCurrentAnnouncement
} from "../../redux/actions/announcements.js";
import RenderAnnouncement from "./RenderAnnouncement.js";

const mapStateToProps = state => ({
  currentAnnouncement: state.announcements.currentAnnouncement
});

const mapDispatchToProps = { getAnnouncementRequest, clearCurrentAnnouncement };

class Announcements extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getAnnouncement();
  }

  componentWillUnmount() {
    this.props.clearCurrentAnnouncement();
  }

  getAnnouncement = () => {
    if (this.props.match.params) {
      const { announcement_id } = this.props.match.params;
      if (announcement_id) {
        this.props.getAnnouncementRequest(announcement_id);
      }
    }
  };

  renderAnnouncement = () => {
    const { data, loaded, loading } = this.props.currentAnnouncement;
    let content;
    if (loaded) {
      content = <RenderAnnouncement data={data.docs[0]} />;
    } else if (loading) {
      content = this.renderLoading();
    }

    return content;
  };

  renderLoading = () => {
    return (
      <Row noGutters className="create-report-loading-row">
        <Spinner
          animation="border"
          variant="light"
          className="create-report-spinner"
        />
      </Row>
    );
  };

  render() {
    return (
      <section className="announcement-section">
        <div className="announcement-content">{this.renderAnnouncement()}</div>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
