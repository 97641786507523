import { connect } from "react-redux";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import {
  getMyAccountRequest,
  wipeLocalStorage,
  checkUserStatus
} from "../redux/actions/account";
import {
  enableWebWorker,
  showLoginModal,
  hideLoginModal,
  hideLoginModalAndWipeLocalStorage,
  autoLoginRequest
} from "../redux/actions/login";
import { getVersionRequest } from "../redux/actions/index";
import { getWebsiteConfigRequest } from "../redux/actions/admin";
import { toggleSidebar } from "../redux/actions/options";

const mapStateToProps = state => ({
  accountData: state.login.accountData,
  websiteConfig: state.admin.websiteConfig,
  sidebarOpened: state.options.sidebarOpened
});

const mapDispatchToProps = {
  getMyAccountRequest,
  enableWebWorker,
  getVersionRequest,
  showLoginModal,
  hideLoginModal,
  wipeLocalStorage,
  checkUserStatus,
  hideLoginModalAndWipeLocalStorage,
  autoLoginRequest,
  getWebsiteConfigRequest,
  toggleSidebar
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
