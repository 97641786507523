import { connect } from "react-redux";
import SearchAssets from "./SearchAssets";
import "./SearchAssets.css";
import {
  getListOfFiltersForAssetTypeRequest,
  resetListOfFiltersForAssetType,
  getAssetsWithParamsRequest,
  getListOfAssetTypesRequest,
  getAllItemsSearchRequest
} from "../../../redux/actions/tokenlist";
import {
  updateWebsiteConfigRequest,
  softUpdateWebsiteConfig
} from "../../../redux/actions/admin";
import { updateWatchlistRequest } from "../../../redux/actions/account";

const mapStateToProps = state => ({
  profileFilters: state.tokens.profileFilters,
  listOfFiltersForAssetType: state.tokens.listOfFiltersForAssetType,
  listOfAssetsForSearch: state.tokens.listOfAssetsForSearch,
  listOfAssetTypes: state.tokens.listOfAssetTypes,
  websiteConfig: state.admin.websiteConfig,
  allItems: state.tokens.allItems,
  account: state.login.accountData,
  websiteConfig: state.admin.websiteConfig,
  weakWebsiteConfig: state.admin.weakWebsiteConfig
});

const mapDispatchToProps = {
  getListOfFiltersForAssetTypeRequest,
  resetListOfFiltersForAssetType,
  getAssetsWithParamsRequest,
  getListOfAssetTypesRequest,
  getAllItemsSearchRequest,
  getListOfAssetTypesRequest,
  updateWebsiteConfigRequest,
  softUpdateWebsiteConfig,
  updateWatchlistRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAssets);
