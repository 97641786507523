import React, { PureComponent } from "react";
import { history } from "../../../configureStore";
import {
  Row,
  Col,
  Container,
  Spinner,
  Image,
  FormControl,
  Table,
  Card,
  Button
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  MdSearch,
  MdLocationOn,
  MdViewHeadline,
  MdViewModule,
  MdViewStream
} from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import { initGA, logPageView } from "../../../utils/analytics";
import MetaTags from "react-meta-tags";
import countryList from "react-select-country-list";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import LogoImg from "../../../images/logoImage.js"; // for metatags
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

class SearchAssets extends PureComponent {
  constructor(props) {
    super(props);

    this.locationOptions = countryList().getLabels();

    this.state = {
      listOfAssetsForSearch: this.props.listOfAssetsForSearch,
      listOfAssetTypes: this.props.listOfAssetTypes,
      listOfFiltersForAssetType: this.props.listOfFiltersForAssetType,
      selectedAssetType: "",
      assetTypeSearchQuery: "",
      profileFilterValues: {},
      searchAllParams: {
        q: "",
        offset: ""
      }
    };
  }

  componentDidMount() {
    this.props.resetListOfFiltersForAssetType();
    this.props.getAssetsWithParamsRequest();
    this.props.getListOfAssetTypesRequest();
    this.props.getAllItemsSearchRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        listOfFiltersForAssetType: nextProps.listOfFiltersForAssetType,
        listOfAssetsForSearch: nextProps.listOfAssetsForSearch,
        listOfAssetTypes: nextProps.listOfAssetTypes
      });
    }
  }

  /************* HANDLERS *****************************************************/
  handleChangeProfileFilter = e => {
    const { selectedAssetType, profileFilterValues } = this.state;
    const { name, value } = e.target;

    const newProfileFilterValues = profileFilterValues;
    newProfileFilterValues[name] = value;
    newProfileFilterValues.assetType = selectedAssetType;

    this.props.getAssetsWithParamsRequest(newProfileFilterValues);
  };

  handleChangeProfileFilterLocation = (value, name) => {
    const { selectedAssetType, profileFilterValues } = this.state;

    const newProfileFilterValues = profileFilterValues;
    newProfileFilterValues[name] = value;
    newProfileFilterValues.assetType = selectedAssetType;

    this.props.getAssetsWithParamsRequest(newProfileFilterValues);
  };

  handleRouteChange = assetName => {
    const path = `/assets/${assetName}`;
    history.push(path);
  };

  handleSearchAssetTypes = e => {
    const params = { template_id: e.target.value };
    this.props.getAssetsWithParamsRequest(params);
    this.props.getListOfFiltersForAssetTypeRequest(params);
  };

  handleChangeAssetTypeSearchQuery = e => {
    const { selectedAssetType, profileFilterValues } = this.state;
    const query = e.target.value;
    const assetType = selectedAssetType;

    const newProfileFilterValues = profileFilterValues;
    newProfileFilterValues.assetType = selectedAssetType;
    newProfileFilterValues.q = query;

    let newState = this.state;
    newState.assetTypeSearchQuery = query;

    this.props.getListOfFiltersForAssetTypeRequest(newProfileFilterValues);
  };

  /************* END OF HANDLERS **********************************************/

  /************* RENDERS ******************************************************/

  renderLoading = () => (
    <Row noGutters className="loading-bar-row">
      <Spinner animation="border" variant="light" />
    </Row>
  );

  renderSearchAssetsTiles = () => {
    const {
      assetTypeSearchQuery,
      listOfAssetTypes,
      listOfAssetsForSearch,
      profileFilterValues,
      listOfFiltersForAssetType
    } = this.state;

    let options;
    if (listOfAssetTypes.loaded) {
      options = listOfAssetTypes.assetTypes.docs.map(assetType =>
        this.renderAssetTypeOption(assetType)
      );
    } else if (listOfAssetTypes.loading) {
      options = this.renderLoading();
    }

    return (
      <Col className="search-assets-content">
        <Row noGutters className="search-assets-tile-row">
          {listOfAssetsForSearch.loaded
            ? listOfAssetsForSearch.assets.docs.map((asset, index) =>
                this.renderTile(asset, index)
              )
            : this.renderLoading()}
        </Row>
      </Col>
    );
  };

  renderTile = (asset, index) => {
    let desc;
    if (asset.description) {
      if (asset.description.length > 180) {
        desc = asset.description.substr(0, 180) + "...";
      } else {
        desc = asset.description;
      }
    }

    let pricingInfo;
    if (this.props.account.loaded) {
      pricingInfo = (
        <div className="pricing-info">
          <Row noGutters>
            <div className="title">Best Bid</div>
            <div className="value" id="green">
              98.77
            </div>
          </Row>
          <Row noGutters>
            <div className="title">Best Offer</div>
            <div className="value" id="red">
              101
            </div>
          </Row>
        </div>
      );
    } else {
      pricingInfo = (
        <div className="pricing-info ">
          <div className="posRel">
            <NavLink to="/login" className="clean-nav">
              <div className="overlay centered">
                To view market data please log in
              </div>
            </NavLink>
            <Row noGutters className="blur1">
              <div className="title">Best Bid</div>
              <div className="value" id="green">
                98.77
              </div>
            </Row>
            <Row noGutters className="blur1">
              <div className="title">Best Offer</div>
              <div className="value" id="red">
                101
              </div>
            </Row>
          </div>
        </div>
      );
    }

    let participateBtn;
    if (this.props.account.loaded) {
      participateBtn = (
        <NavLink to="/login" className="clean-nav">
          <Button className="trans-button small" size="sm">
            Participate
          </Button>
        </NavLink>
      );
    } else {
      participateBtn = (
        <a
          href="https://trade.mexchange.online/"
          target="_blank"
          className="participate-btn-a"
        >
          <Button className="trans-button small" size="sm">
            Participate
          </Button>
        </a>
      );
    }

    return (
      <Card
        style={{ cursor: "pointer" }}
        onClick={() => this.handleRouteChange(asset.name)}
        className="search-assets-tile"
        key={index}
      >
        <Row noGutters className="header-content">
          {asset.image && <Card.Img src={asset.image} />}
        </Row>

        <Card.Text className="sat-desc">{desc}</Card.Text>
        {pricingInfo}
      </Card>
    );
  };

  handleUpdateWatchlist = item => {
    const watchlist = this.props.account.data.watch_list || [];
    watchlist.push(item);

    this.props.updateWatchlistRequest(watchlist);
  };

  renderSearchAssetsList = () => {
    const {
      assetTypeSearchQuery,
      listOfAssetTypes,
      listOfAssetsForSearch,
      profileFilterValues,
      listOfFiltersForAssetType
    } = this.state;

    let loadingInfo, tableContent;
    if (listOfAssetsForSearch.loaded) {
      if (listOfAssetsForSearch.assets.total == 0) {
        loadingInfo = (
          <tr>
            <Row noGutters className="table-loading-row">
              No assets found
            </Row>
          </tr>
        );
      } else {
        tableContent = listOfAssetsForSearch.assets.docs.map((asset, index) =>
          this.renderAssetData(asset, index)
        );
      }
    } else if (listOfAssetsForSearch.loading) {
      loadingInfo = (
        <tr>
          <td colSpan="8">
            <Row noGutters className="table-loading-row">
              <Spinner
                className="asset-profile-loading-spinner"
                animation="border"
              />
            </Row>
          </td>
        </tr>
      );
    }

    return (
      <Table responsive bordered className="dark-table">
        <thead>
          <tr>{this.renderHeaderCells()}</tr>
        </thead>
        <tbody>
          {tableContent}
          {loadingInfo}
        </tbody>
      </Table>
    );
  };

  renderHeaderCells = () => {
    let cells;
    if (this.state.listOfFiltersForAssetType.loaded) {
      const preview = this.state.listOfFiltersForAssetType.preview;
      const nameCell = preview.includes("name") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">Name</div>
        </th>
      ) : (
        ""
      );
      const descriptionCell = preview.includes("description") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">Description</div>
        </th>
      ) : (
        ""
      );

      const assetTypeCell = preview.includes("assetType") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">Asset Type</div>
        </th>
      ) : (
        ""
      );

      const industryCell = preview.includes("industry") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">Industry</div>
        </th>
      ) : (
        ""
      );

      const locationCell = preview.includes("location") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">Location</div>
        </th>
      ) : (
        ""
      );

      const marketDataCells = preview.includes("marketData") ? (
        <React.Fragment>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Price</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">1D High</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">1D Low</div>
          </th>
        </React.Fragment>
      ) : (
        ""
      );

      cells = (
        <React.Fragment>
          {nameCell}
          {descriptionCell}
          {assetTypeCell}
          {industryCell}
          {locationCell}
          {marketDataCells}
        </React.Fragment>
      );
    } else {
      cells = (
        <React.Fragment>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Name</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Description</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Asset Type</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Industry</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Location</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">Price</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">1D High</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">1D Low</div>
          </th>
        </React.Fragment>
      );
    }

    return cells;
  };

  renderAssetData = (asset, index) => {
    const number = index + 1;

    return (
      <tr
        className="asset-data-row"
        key={asset.name}
        onClick={() => this.handleRouteChange(asset.name)}
      >
        {this.renderBodyCells(asset)}
      </tr>
    );
  };

  renderBodyCells = asset => {
    const {
      name,
      assetType,
      description,
      industry,
      location,
      _id,
      image,
      template_id,
      prices
    } = asset;
    const price = prices ? `$ ${prices.price}` : "";
    const high = prices ? `$ ${prices.high}` : "";
    const low = prices ? `$ ${prices.low}` : "";

    let desc;
    if (description) {
      if (description.length > 105) {
        desc = description.substr(0, 105) + "...";
      } else {
        desc = description;
      }
    }

    let templateName;
    if (template_id) {
      templateName = template_id.name;
    } else {
      templateName = "";
    }

    let cells;
    if (this.state.listOfFiltersForAssetType.loaded) {
      const preview = this.state.listOfFiltersForAssetType.preview;
      const nameCell = preview.includes("name") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">{name}</div>
        </th>
      ) : (
        ""
      );
      const descriptionCell = preview.includes("description") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">{description}</div>
        </th>
      ) : (
        ""
      );

      const assetTypeCell = preview.includes("assetType") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">{assetType}</div>
        </th>
      ) : (
        ""
      );

      const industryCell = preview.includes("industry") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">{industry}</div>
        </th>
      ) : (
        ""
      );

      const locationCell = preview.includes("location") ? (
        <th className="search-assets-table-cell">
          <div className="search-assets-table-cell-div">{location}</div>
        </th>
      ) : (
        ""
      );

      const marketDataCells = preview.includes("marketData") ? (
        <React.Fragment>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{price}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{high}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{low}</div>
          </th>
        </React.Fragment>
      ) : (
        ""
      );

      cells = (
        <React.Fragment>
          {nameCell}
          {descriptionCell}
          {assetTypeCell}
          {industryCell}
          {locationCell}
          {marketDataCells}
        </React.Fragment>
      );
    } else {
      cells = (
        <React.Fragment>
          <th className="search-assets-table-cell">
            <Row noGutters className="search-assets-table-row">
              <Image src={image} className="asset-type-table-image" />
              {name}
            </Row>
          </th>
          <th className="search-assets-table-cell description">{desc}</th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{templateName}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{industry}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{location}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{price}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{high}</div>
          </th>
          <th className="search-assets-table-cell">
            <div className="search-assets-table-cell-div">{low}</div>
          </th>
        </React.Fragment>
      );
    }

    return cells;
  };

  renderAssetTypeOption = option => {
    const { assetType, _id } = option;
    return (
      <option key={_id} value={_id}>
        {assetType}
      </option>
    );
  };

  renderFiltersForAssetType = () => {
    const { loaded, filters, loading } = this.state.listOfFiltersForAssetType;

    let renderedFilters;
    if (loaded && filters.length > 0)
      renderedFilters = filters.map(filter =>
        this.renderIndividualFilter(filter)
      );
    else if (loading) renderedFilters = this.renderLoading();

    return renderedFilters;
  };

  renderIndividualFilter = filter => {
    const { fieldName, options, fieldType } = filter;

    let content;
    let renderedOptions;
    if (fieldType == "dropdown") {
      renderedOptions = options.map(option =>
        this.renderIndividualFilterOption(option)
      );
      content = (
        <div className="filter-container">
          {fieldName}:
          <FormControl
            name={fieldName}
            as="select"
            key={fieldName}
            onChange={this.handleChangeProfileFilter}
            className="rendered-asset-filter"
          >
            <option value="">Select an option...</option>
            {renderedOptions}
          </FormControl>
        </div>
      );
    } else if (fieldType == "location") {
      content = (
        <div className="filter-container">
          {fieldName}:
          <Typeahead
            className="rendered-asset-filter location-filter"
            onChange={e => this.handleChangeProfileFilterLocation(e, fieldName)}
            options={this.locationOptions}
          />
        </div>
      );
    }

    return content;
  };

  renderIndividualFilterOption = option => (
    <option value={option} key={option}>
      {option}
    </option>
  );

  renderMetaTags = () => {
    return (
      <MetaTags>
        <title>Asset Builder</title>
        <meta name="description" content="Search assets" />
        <meta name="title" property="og:title" content="Explore Assets" />
        <meta property="og:description" content="Search assets" />
        <meta property="og:image" content={LogoImg} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:title" content="Explore Assets" />
        <meta property="twitter:description" content="Search assets" />
        <meta property="twitter:image" content={LogoImg} />
        <meta property="twitter:site" content="Explore Assets" />
        <meta name="robots" content="nofollow" />
      </MetaTags>
    );
  };

  handleDetermineProfilePreview = () => {
    let content = this.renderSearchAssetsList();
    if (!this.props.weakWebsiteConfig) {
      return;
    }

    if (this.props.weakWebsiteConfig == "tile") {
      content = this.renderSearchAssetsTiles();
    } else if (this.props.weakWebsiteConfig == "list") {
      content = this.renderSearchAssetsList();
    } else if (this.props.weakWebsiteConfig == "slide") {
      content = this.renderSwiper();
    }

    return content;
  };

  handleSwitchCurrentView = view => {
    this.props.softUpdateWebsiteConfig(view);
  };

  renderSwiper = () => {
    const {
      assetTypeSearchQuery,
      listOfAssetTypes,
      listOfAssetsForSearch,
      profileFilterValues,
      listOfFiltersForAssetType
    } = this.state;

    let content;
    if (listOfAssetsForSearch.loaded) {
      if (listOfAssetsForSearch.assets.docs.length > 0) {
        content = listOfAssetsForSearch.assets.docs.map((asset, index) =>
          this.renderSwiperItem(asset, index, "asset")
        );
      } else {
        content = (
          <Swiper className="swiper-loading-container">
            <SwiperSlide className="swiper-loading-slide">
              No items were found
            </SwiperSlide>
          </Swiper>
        );
      }
    } else if (listOfAssetsForSearch.loading) {
      content = (
        <Swiper className="swiper-loading-container">
          <SwiperSlide className="swiper-loading-slide">
            <Spinner animation="border" variant="light" />
          </SwiperSlide>
        </Swiper>
      );
    }

    let allContent;
    if (this.props.allItems.loaded) {
      if (this.props.allItems.data.news.length > 0) {
        allContent = allContent = this.props.allItems.data.news.map(
          (item, index) => this.renderSwiperItem(item, index, "news")
        );
      } else {
        allContent = (
          <Swiper className="swiper-loading-container">
            <SwiperSlide className="swiper-loading-slide">
              No items were found
            </SwiperSlide>
          </Swiper>
        );
      }
    } else if (this.props.allItems.loading) {
      allContent = (
        <Swiper className="swiper-loading-container">
          <SwiperSlide className="swiper-loading-slide">
            <Spinner animation="border" variant="light" />
          </SwiperSlide>
        </Swiper>
      );
    }

    return (
      <div className="sa-big-container">
        <div className="sa-content-section">
          <h3 className="sa-section-header">Current Positions</h3>
          <Row noGutters>
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 3
                },
                960: {
                  slidesPerView: 3
                },
                1300: {
                  slidesPerView: 3
                },
                1500: {
                  slidesPerVIew: 4
                },
                1800: {
                  slidesPerView: 5
                }
              }}
              className="assets-swiper"
              scrollbar={{ draggable: true }}
            >
              {content}
            </Swiper>
          </Row>
        </div>
        <div className="sa-content-section">
          <h3 className="sa-section-header">Ideas You Might Like</h3>
          <Row noGutters>
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 3
                },
                960: {
                  slidesPerView: 3
                },
                1300: {
                  slidesPerView: 3
                },
                1500: {
                  slidesPerVIew: 4
                },
                1800: {
                  slidesPerView: 5
                }
              }}
              className="assets-swiper"
              scrollbar={{ draggable: true }}
            >
              {content}
            </Swiper>
          </Row>
        </div>
        <div className="sa-content-section">
          <h3 className="sa-section-header">All Content</h3>
          <Row noGutters>
            <Swiper
              breakpoints={{
                500: {
                  slidesPerView: 2
                },
                640: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 4
                },
                960: {
                  slidesPerView: 5
                },
                1300: {
                  slidesPerView: 6
                },
                1500: {
                  slidesPerVIew: 7
                },
                1800: {
                  slidesPerView: 8
                }
              }}
              className="assets-swiper"
              scrollbar={{ draggable: true }}
            >
              {allContent}
            </Swiper>
          </Row>
        </div>
      </div>
    );
  };

  renderSwiperItem = (item, index, itemType) => {
    let itemContent;
    if (itemType == "news") {
      const { date, image, link, publisher, title, content, _id } = item;
      itemContent = (
        <SwiperSlide zoom={true} className="search-all-assets-tile" key={_id}>
          <Card className="news-card">
            <a href={link} target="_blank">
              <Card.Img
                onError={this.handleImageError}
                variant="top"
                src={image}
              />
            </a>
            <Card.Title>
              <a href={link} target="_blank">
                {title}
              </a>
            </Card.Title>
            <a target="_blank" href={link} className="news-card-footer">
              Read more
              <IoIosArrowRoundForward />
            </a>
          </Card>
        </SwiperSlide>
      );

      return itemContent;
    }

    const { image, name, _id, industry, location, symbol } = item;
    let newImage;
    if (image)
      newImage = (
        <Card.Img
          variant="top"
          src={image}
          onClick={() => this.handleRouteChange(name)}
        />
      );

    return (
      <SwiperSlide zoom={true} className="search-all-assets-tile" key={_id}>
        {this.renderTile(item, index)}
      </SwiperSlide>
    );
  };

  renderMenu = () => {
    let options;
    if (this.state.listOfAssetTypes.loaded) {
      options = this.state.listOfAssetTypes.assetTypes.docs.map(assetType =>
        this.renderAssetTypeOption(assetType)
      );
    } else if (this.state.listOfAssetTypes.loading) {
      options = this.renderLoading();
    }

    const slideActiveId =
      this.props.weakWebsiteConfig == "slide" ? "active" : "";
    const listActiveId = this.props.weakWebsiteConfig == "list" ? "active" : "";
    const cardsActiveId =
      this.props.weakWebsiteConfig == "tile" ? "active" : "";

    return (
      <div className="search-assets-background-container">
        <Row noGutters className="search-assets-query-row">
          <div className="saq-item">
            <div className="saq-label">Search Assets:</div>
            <FormControl
              name="q"
              className="search-assets-form-control-new"
              placeholder="Search..."
              size="sm"
              onChange={this.handleChangeProfileFilter}
              value={this.state.profileFilterValues.q}
            />
          </div>
          <div className="saq-item">
            <div className="saq-label">Select an Asset Type:</div>
            <FormControl
              custom
              as="select"
              size="sm"
              className="search-assets-form-control-new"
              onChange={this.handleSearchAssetTypes}
            >
              <option value="">Select an Asset Type...</option>
              {options}
            </FormControl>
          </div>
        </Row>

        <Row noGutters className="news-menu-row">
          <div className="news-menu-control-container">
            <MdViewHeadline
              onClick={() => this.handleSwitchCurrentView("list")}
              className="news-menu-item icon"
              id={listActiveId}
            />
            <MdViewModule
              onClick={() => this.handleSwitchCurrentView("tile")}
              className="news-menu-item icon"
              id={cardsActiveId}
            />
            <MdViewStream
              onClick={() => this.handleSwitchCurrentView("slide")}
              className="news-menu-item icon"
              id={slideActiveId}
            />
          </div>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <Container fluid className="search-assets-top-container">
        {this.renderMetaTags()}
        {this.renderMenu()}
        <Row noGutters className="search-assets-container">
          {this.handleDetermineProfilePreview()}
        </Row>
      </Container>
    );
  }
}

export default SearchAssets;
