import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { history } from "../../configureStore";
import { Link, NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Image,
  Button,
  Dropdown,
  NavDropdown
} from "react-bootstrap";
import jwt from "jsonwebtoken";
import {
  AiOutlineUser,
  AiOutlineUnorderedList,
  AiOutlineSetting,
  AiOutlineLogout,
  AiOutlineDashboard
} from "react-icons/ai";
import LogoImg from "../../images/HomePage/FI_LOGO.png";
import { MdSupervisorAccount } from "react-icons/md";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountData: {
        authenticated: false,
        data: {},
        loaded: false,
        loading: false,
        error: ""
      }
    };
  }

  componentDidMount() {
    this.handleSetAccountData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        accountData: nextProps.accountData
      });
    }
  }

  handleSetAccountData = () => {
    if (this.props.accountData) {
      if (this.props.accountData.loaded) {
        this.setState({
          accountData: this.props.accountData
        });
      }
    }
  };

  handleLogout = () => {
    this.props.logout();
  };

  renderNewsButton = () => (
    <NavLink to="/news" className="navigation-bar-item">
      News
    </NavLink>
  );

  renderTokensButton = () => (
    <NavLink to="/assets/search-assets" className="navigation-bar-item">
      Asset Profiles
    </NavLink>
  );

  renderUnauthResearchButton = () => (
    <NavDropdown title="Research" className="navigation-bar-dropdown">
      <ul>
        <NavLink to="/research">Latest</NavLink>
      </ul>
    </NavDropdown>
  );

  renderNonContributorResearchButton = () => (
    <NavDropdown title="Research" className="navigation-bar-dropdown">
      <ul>
        <NavLink to="/research">Latest</NavLink>
      </ul>
    </NavDropdown>
  );

  renderContributorResearchButton = () => (
    <NavDropdown title="Research" className="navigation-bar-dropdown">
      <ul>
        <NavLink to="/research">Latest</NavLink>
      </ul>
    </NavDropdown>
  );

  renderLoginRegisterButtons = () => (
    <NavLink to="/login" className="nav-bar-item">
      <Button className="teal-button" size="sm">
        Login
      </Button>
    </NavLink>
  );

  renderAdminButton = () => (
    <NavLink to="/admin" className="navigation-bar-item">
      Admin Portal
    </NavLink>
  );

  renderLogoButton = () => (
    <Navbar.Brand>
      <Link to="/">
        <Image className="fi-logo" src={LogoImg} />
      </Link>
    </Navbar.Brand>
  );

  renderAccountButton = () => {
    const { accountData } = this.state;
    let displayName;
    if (accountData.loaded) {
      displayName =
        accountData.data.first_name + " " + accountData.data.last_name;
    }

    const title = (
      <React.Fragment>
        {displayName}
        <AiOutlineUser className="user-topbar-user-icon" />
      </React.Fragment>
    );

    return (
      <NavDropdown className="user-topbar-nav" title={title}>
        {this.renderPortalButton()}
        <NavDropdown.Divider />
        <NavDropdown.Item
          className="user-topbar-item"
          onClick={this.props.logout}
        >
          <AiOutlineLogout className="topbar-icon" />
          <div className="user-topbar-text">Log out</div>
        </NavDropdown.Item>
      </NavDropdown>
    );
  };

  renderPortalButton = () => {
    const { accountData } = this.state;

    let href;
    if (accountData.loaded) {
      if (accountData.data.isAdmin || accountData.data.isIssuer) {
        href = "/";
      } else {
        href = "/";
      }
    }

    return (
      <NavDropdown.Item href={href} className="user-topbar-item">
        <AiOutlineUnorderedList className="topbar-icon" />
        <div className="user-topbar-text">Portal</div>
      </NavDropdown.Item>
    );
  };

  renderAdminAccountButton = () => {
    const { accountData } = this.state;
    let displayName;
    if (accountData.loaded) {
      displayName =
        accountData.data.first_name + " " + accountData.data.last_name;
    }

    const title = (
      <React.Fragment>
        {displayName}
        <AiOutlineUser className="user-topbar-user-icon" />
      </React.Fragment>
    );

    return (
      <NavDropdown className="user-topbar-nav" title={title}>
        {this.renderPortalButton()}
        <NavDropdown.Item href="/admin" className="admin-topbar-login">
          <AiOutlineSetting className="topbar-icon" />
          <div className="user-topbar-text">Administration</div>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          className="admin-topbar-login"
          onClick={this.props.logout}
        >
          <AiOutlineLogout className="topbar-icon" />
          <div className="user-topbar-text">Log out</div>
        </NavDropdown.Item>
      </NavDropdown>
    );
  };

  renderContributorAccountButton = () => {
    const { accountData } = this.state;
    let displayName;
    if (accountData.loaded) {
      displayName =
        accountData.data.first_name + " " + accountData.data.last_name;
    }

    const title = (
      <React.Fragment>
        {displayName}
        <AiOutlineUser className="user-topbar-user-icon" />
      </React.Fragment>
    );

    return (
      <NavDropdown className="user-topbar-nav" title={title}>
        {this.renderPortalButton()}
        <NavDropdown.Divider />
        <NavDropdown.Item
          className="admin-topbar-login"
          onClick={this.props.logout}
        >
          <AiOutlineLogout className="topbar-icon" />
          <div className="user-topbar-text">Log out</div>
        </NavDropdown.Item>
      </NavDropdown>
    );
  };

  renderUnauthenticatedLinks = () => (
    <div className="navigation-bar-links-container">
      {this.renderNewsButton()}
      {this.renderTokensButton()}
      {this.renderUnauthResearchButton()}
      {this.renderLoginRegisterButtons()}
    </div>
  );

  renderAuthenticatedLinks = () => (
    <div className="navigation-bar-links-container">
      {this.renderNewsButton()}
      {this.renderTokensButton()}
      {this.renderNonContributorResearchButton()}
      {this.renderAccountButton()}
    </div>
  );

  renderAdminLinks = () => (
    <div className="navigation-bar-links-container">
      {this.renderNewsButton()}
      {this.renderTokensButton()}
      {this.renderNonContributorResearchButton()}
      {this.renderAdminAccountButton()}
    </div>
  );

  renderAdminContributorLinks = () => (
    <div className="navigation-bar-links-container">
      {this.renderNewsButton()}
      {this.renderTokensButton()}
      {this.renderContributorResearchButton()}
      {this.renderAdminAccountButton()}
    </div>
  );

  renderContributorLinks = () => (
    <div className="navigation-bar-links-container">
      {this.renderNewsButton()}
      {this.renderTokensButton()}
      {this.renderContributorResearchButton()}
      {this.renderAccountButton()}
    </div>
  );

  renderLinksBasedOnUserStatus = () => {
    const { authenticated, data } = this.state.accountData;
    let content;
    if (authenticated) {
      if (data.isAdmin && data.isContributor) {
        content = this.renderAdminContributorLinks();
      } else if (data.isContributor) {
        content = this.renderContributorLinks();
      } else if (data.isAdmin) {
        content = this.renderAdminLinks();
      } else {
        content = this.renderAuthenticatedLinks();
      }
    } else {
      content = this.renderUnauthenticatedLinks();
    }

    return (
      <Navbar className="navigation-bar">
        {this.renderLogoButton()}
        {content}
      </Navbar>
    );
  };

  render() {
    return this.renderLinksBasedOnUserStatus();
  }
}

export default Topbar;
