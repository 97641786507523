import * as CONSTANTS from "../constants/announcements";

const initialState = {
  announcements: { data: {}, loaded: false, loading: false, error: "" },
  currentAnnouncement: {
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  attachmentsLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        currentAnnouncement: {
          ...state.currentAnnouncement,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.CREATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        currentAnnouncement: {
          ...state.currentAnnouncement,
          loaded: true,
          loading: false,
          data: action.data
        }
      };

    case CONSTANTS.CREATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        currentAnnouncement: {
          ...state.currentAnnouncement,
          loaded: false,
          loading: false,
          data: {},
          error: action.error
        }
      };

    case CONSTANTS.CLEAR_CURRENT_ANNOUNCEMENT:
      return {
        ...state,
        currentAnnouncement: {
          data: {},
          loaded: false,
          loading: false,
          error: ""
        }
      };

    case CONSTANTS.SEARCH_ANNOUNCEMENTS_REQUEST:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.SEARCH_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case CONSTANTS.SEARCH_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: false,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.CLEAR_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: {
          data: {},
          loaded: false,
          loading: false,
          error: ""
        }
      };

    case CONSTANTS.GET_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        currentAnnouncement: {
          ...state.currentAnnouncement,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.GET_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        currentAnnouncement: {
          ...state.currentAnnouncement,
          loaded: true,
          loading: false,
          data: action.data
        }
      };

    case CONSTANTS.GET_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        currentAnnouncement: {
          ...state.currentAnnouncement,
          loaded: false,
          loading: false,
          data: {},
          error: action.error
        }
      };

    case CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        attachmentsLoading: true
      };

    case CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        attachmentsLoading: false
      };

    case CONSTANTS.ATTACH_FILE_TO_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        attachmentsLoading: false
      };

    case CONSTANTS.REMOVE_FILE_FROM_ANNOUNCEMENT_SUCCESS:
      return {
        ...state
      };

    // case CONSTANTS.EDIT_ANNOUNCEMENT_SUCCESS:
    // let attachments = state.attachments
    //
    //
    //   return {
    //     ...state,
    //
    //   }

    default:
      return state;
  }
};
