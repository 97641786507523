import { connect } from "react-redux";
import AssetProfiles from "./AssetProfiles";
import "./AssetProfiles.css";
import {
  getPublishedTemplatesRequest,
  createTokenFormFromTemplateRequest,
  resetTokenFormParams,
  uploadTokenFormImageRequest,
  uploadTokenFormDocumentRequest,
  editTokenFormRequest,
  getTokenProfilesListRequest,
  selectAssetProfileRequest,
  publishAssetProfileRequest,
  searchAssetProfilesRequest,
  deleteAssetProfileRequest,
  unpublishAssetProfileRequest,
  createAssetProfileRequest,
  assetProfileUploadFileRequest,
  assetProfileDeleteFileRequest,
  uploadAssetProfileImageRequest,
  deleteAssetProfileImageRequest,
  updateAssetProfileOfferingRequest,
  uploadOfferingDocumentRequest,
  clearOfferingDocument,
  importFromExcelRequest,
  cloneProfileRequest
} from "../../../redux/actions/tokenform";
import {
  getTradedSymbolsRequest,
  createOfferingRequest,
  getOfferRequest,
  updateOfferRequest
} from "../../../redux/actions/admin";

const industries = [
  "--Communication Services--",
  "Services",
  "Advertising",
  "Alternative Carriers",
  "Broadcasting Cable & Satellite",
  "Integrated Telecommunication Services",
  "Interactive Home Entertainment",
  "Interactive Media & Services",
  "Movies & Entertainment",
  "Publishing & Printing",
  "Wireless Telecommunication Services",
  "--Consumer Discretionary--",
  "Apparel Retail",
  "Apparel, Accessories & Luxury Goods",
  "Auto Parts & Equipment",
  "Automobile Manufacturers",
  "Automotive Retail",
  "Casinos & Gaming",
  "Computer & Electronics Retail",
  "Consumer Electronics",
  "Department Stores",
  "Distributors",
  "Footwear",
  "General Merchandise Stores",
  "Home Furnishings",
  "Home Improvement Retail",
  "Homebuilding",
  "Hotels, Resorts & Cruise Lines",
  "Household Appliances",
  "Housewares & Specialties",
  "Internet & Direct Marketing Retail",
  "Leisure Products",
  "Restaurants",
  "Specialized Consumer Services",
  "Specialty Stores",
  "--Consumer Staples--",
  "Agricultural Products",
  "Brewers",
  "Distillers & Vintners",
  "Drug Retail",
  "Food Distributors",
  "Food Retail",
  "Household Products",
  "HyperMarkets & Super Centers",
  "Packaged Foods & Meats",
  "Personal Products",
  "Soft Drinks",
  "Tobacco",
  "--Energy--",
  "Integrated Oil & Gas",
  "Oil & Gas Equipment & Services",
  "Oil & Gas Exploration & Production",
  "Oil & Gas Refining & Marketing",
  "Oil & Gas Storage & Transportation",
  "--Financials--",
  "Asset Management & Custody Banks",
  "Consumer Finance",
  "Diversified Banks",
  "Financial Exchanges & Data",
  "Insurance Brokers",
  "Investment Banking & Brokerage",
  "Life & Health Insurance",
  "Multi-line Insurance",
  "Multi-Sector Holdings",
  "Property & Casualty Insurance",
  "Regional Banks",
  "Reinsurance",
  "Health Care",
  "Biotechnology",
  "Health Care Distributors",
  "Health Care Equipment",
  "Health Care Facilities",
  "Health Care Services",
  "Health Care Supplies",
  "Health Care Technology",
  "Life Sciences Tools & Services",
  "Managed Health Care",
  "Pharmaceuticals",
  "--Industrials--",
  "Aerospace & Defense",
  "Agricultural & Farm Machinery",
  "Air Freight & Logistics",
  "Airlines",
  "Building Products",
  "Construction & Engineering",
  "Construction Machinery & Heavy Trucks",
  "Diversified Support Services",
  "Electrical Components & Equipment",
  "Environmental & Facilities Services",
  "Human Resource & Employment Services",
  "Industrial Conglomerates",
  "Industrial Machinery",
  "Railroads",
  "Research & Consulting Services",
  "Trading Companies & Distributors",
  "Trucking",
  "--Information Technology--",
  "Application Software",
  "Communications Equipment",
  "Data Processing & Outsourced Services",
  "Electronic Components",
  "Electronic Equipment & Instruments",
  "Electronic Manufacturing Services",
  "Internet Services & Infrastructure",
  "IT Consulting & Other Services",
  "Semiconductor Equipment",
  "Semiconductors",
  "Systems Software",
  "Technology Distributors",
  "Technology Hardware, Storage & Peripherals",
  "--Materials--",
  "Commodity Chemicals",
  "Construction Materials",
  "Copper",
  "Diversified Chemicals",
  "Fertilizers & Agricultural Chemicals",
  "Gold",
  "Industrial Gases",
  "Metal & Glass Containers",
  "Paper Packaging",
  "Specialty Chemicals",
  "Steel",
  "--Real Estate--",
  "Health Care REITs",
  "Hotel & Resort REITs",
  "Industrial REITs",
  "Office REITs",
  "Real Estate Services",
  "Residential REITs",
  "Retail REITs",
  "Specialized REITs",
  "Utilities",
  "Electric Utilities",
  "Gas Utilities",
  "Independent Power Producers & Energy Traders",
  "Water Utilities",
  "Multi-Utilities"
];

const mapStateToProps = state => ({
  assetTypes: state.token.publishedTemplates,
  assetProfileToEdit: state.token.tokenForm,
  tokenFormImage: state.token.tokenFormImage,
  tokenFormDocument: state.token.tokenFormDocument,
  tokenProfilesList: state.token.tokenProfilesList,
  savingForm: state.token.savingForm,
  unpublishAssetProfile: state.token.unpublishAssetProfile,
  tradedSymbols: state.admin.tradedSymbols,
  offer: state.admin.offer,
  industries: industries
});

const mapDispatchToProps = {
  getPublishedTemplatesRequest,
  createTokenFormFromTemplateRequest,
  resetTokenFormParams,
  uploadTokenFormImageRequest,
  uploadTokenFormDocumentRequest,
  editTokenFormRequest,
  getTokenProfilesListRequest,
  selectAssetProfileRequest,
  publishAssetProfileRequest,
  searchAssetProfilesRequest,
  deleteAssetProfileRequest,
  unpublishAssetProfileRequest,
  createAssetProfileRequest,
  assetProfileUploadFileRequest,
  assetProfileDeleteFileRequest,
  uploadAssetProfileImageRequest,
  deleteAssetProfileImageRequest,
  updateAssetProfileOfferingRequest,
  uploadOfferingDocumentRequest,
  clearOfferingDocument,
  getTradedSymbolsRequest,
  createOfferingRequest,
  getOfferRequest,
  updateOfferRequest,
  importFromExcelRequest,
  cloneProfileRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetProfiles);
