import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Card,
  Image
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../images/HomePage/FI_LOGO.png";

class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      requestSent: this.props.requestSent
    };
  }

  componentDidMount() {
    this.props.forgotPasswordReset();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        requestSent: nextProps.requestSent
      });
    }
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleForgot = () => {
    this.props.forgotPasswordRequest(this.state.email);
  };

  renderLogo = () => (
    <div className="login-logo-row">
      <div className="login-logo-box">
        <img src={logo} className="login-logo" />
      </div>
    </div>
  );

  renderForgotPassword = () => {
    const { email } = this.state;
    const disabled = email ? false : true;

    return (
      <Card className="login-box">
        <Row noGutters>
          <Col className="login-content-col">
            <Row noGutters className="login-header">
              <Image fluid src={logo} className="login-logo" />
            </Row>

            <Row noGutters className="login-box-padding login-formcontrol-row">
              <Col className="login-box-col">
                <Row noGutters className="login-welcome">
                  <Col>
                    <h1>Welcome</h1>
                    <p>
                      Please enter your email and a reset link will be sent to
                      you
                    </p>
                  </Col>
                </Row>

                <Row noGutters className="login-formcontrol-container">
                  <div className="login-formcontrol-box">
                    <div className="login-formcontrol-text">Email</div>
                    <FormControl
                      size="sm"
                      name="email"
                      value={email}
                      onChange={this.handleOnChange}
                      className="login-formcontrol"
                    />
                  </div>
                </Row>

                <Row noGutters className="login-button-row">
                  <Button
                    size="sm"
                    disabled={disabled}
                    onClick={this.handleForgot}
                    className="teal-button"
                  >
                    SEND
                  </Button>
                </Row>

                <div className="login-navlinks">
                  <NavLink to="/login">Log in</NavLink>
                  <NavLink to="/register">Create an Account</NavLink>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  renderRequestSent = () => {
    return (
      <Card className="login-box">
        <Row noGutters>
          <Col className="login-content-col">
            <Row noGutters className="login-header">
              <Image fluid src={logo} className="login-logo" />
            </Row>

            <Row noGutters className="login-box-padding login-formcontrol-row">
              <Col className="login-box-col">
                <Row noGutters className="login-welcome">
                  <h5>A reset link has been sent to your email.</h5>
                </Row>

                <div className="login-navlinks">
                  <NavLink to="/login">Log in</NavLink>
                  <NavLink to="/register">Create an Account</NavLink>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    let content;
    if (this.state.requestSent) {
      content = this.renderRequestSent();
    } else {
      content = this.renderForgotPassword();
    }

    return (
      <Container fluid className="new-login-container">
        {content}
      </Container>
    );
  }
}

export default ForgotPassword;
