import React from "react";
import {
  Row,
  Col,
  Container,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Button
} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { AiOutlineFile } from "react-icons/ai";
import he from "he";

export default class RenderAnnouncement extends React.Component {
  constructor(props) {
    super(props);
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  };

  renderAttachments = attachments => {
    let content;
    if (attachments.length > 0) {
      console.log(attachments);
      content = attachments.map((item, i) => {
        if (item.fileName && item.url) {
          return this.renderAttachment(item, i);
        }
      });
    }

    return content;
  };

  renderAttachment = (item, i) => {
    const { fileName, url } = item;
    const substrFileName = fileName.substr(0, 15) + "\u2026";
    return (
      <a
        key={`Individual Attachment - ${i}`}
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        <AiOutlineFile />
        {fileName}
      </a>
    );
  };

  render() {
    const {
      body,
      datePublished,
      firm,
      summary,
      title,
      user_id,
      _id,
      attachments
    } = this.props.data;

    return (
      <article
        className="announcement-block"
        key={`announcement-${_id}`}
        style={{ minWidth: "31.25rem" }}
      >
        <h3 style={{ color: "black" }}>{title}</h3>
        <h5 style={{ color: "rgba(0,0,0,0.5)" }}>{summary}</h5>
        <Row noGutters style={{ marginBottom: "16px" }}>
          <div className="announcement-info" style={{ marginRight: "16px" }}>
            {this.formatDate(datePublished)}
          </div>
          <div className="announcement-info">{user_id._id}</div>
        </Row>
        <div>{body ? ReactHtmlParser(he.decode(body)) : ""}</div>
        <section className="attachments-section">
          <h4>Attached Documents</h4>
          {this.renderAttachments(attachments)}
        </section>
      </article>
    );
  }
}
