import React, { Component } from "react";
import { createChart } from "lightweight-charts";

export default class LightWeightChart extends Component {
  static defaultProps = {
    containerId: "lightweight_chart_container"
  };

  componentDidMount() {
    const lightTheme = {
      chart: {
        layout: {
          backgroundColor: "#FFFFFF",
          lineColor: "#2B2B43",
          textColor: "#191919"
        },
        watermark: {
          color: "rgba(0, 0, 0, 0)"
        },
        grid: {
          vertLines: {
            visible: false
          },
          horzLines: {
            color: "#f0f3fa"
          }
        }
      },
      series: {
        topColor: "rgba(33, 150, 243, 0.56)",
        bottomColor: "rgba(33, 150, 243, 0.04)",
        lineColor: "rgba(33, 150, 243, 1)"
      }
    };

    const darkTheme = {
      chart: {
        layout: {
          backgroundColor: "#2B2B43",
          lineColor: "#2B2B43",
          textColor: "#D9D9D9"
        },
        watermark: {
          color: "rgba(0, 0, 0, 0)"
        },
        crosshair: {
          color: "#758696"
        },
        grid: {
          vertLines: {
            color: "#2B2B43"
          },
          horzLines: {
            color: "#363C4E"
          }
        }
      },
      series: {
        topColor: "rgba(32, 226, 47, 0.56)",
        bottomColor: "rgba(32, 226, 47, 0.04)",
        lineColor: "rgba(32, 226, 47, 1)"
      }
    };

    const chart = createChart(this.props.containerId, {
      width: 830,
      height: 300
    });
    chart.applyOptions(darkTheme.chart);

    this.chart = chart;

    const lineSeries = chart.addLineSeries();

    lineSeries.setData(this.props.data.chart); // provided by component
  }

  componentWillUnmount() {
    if (this.chart !== null) {
      this.chart.remove();
      this.chart = null;
    }
  }

  render() {
    return <div id={this.props.containerId} className="LightweightChart" />;
  }
}
