import React, { Component } from "react";
import { Container, Image, Row, Col, Card } from "react-bootstrap";
import "./NotFound.css";
import logo from "../../images/HomePage/FI_LOGO.png";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  render() {
    const pathname = this.props.history.location.pathname;
    const account = this.props.account;
    if (!account) {
      return;
    }

    let className = "not-found-container";
    let content;
    let innerContent;
    if (account.authenticated) {
      // if this user is logged in
      if (account.data.isAdmin) {
        // if the user is an admin and logged in
        innerContent = (
          <React.Fragment>
            <Row noGutters className="not-found-header-row">
              PAGE NOT FOUND
            </Row>
            <Row noGutters className="not-found-content-row">
              The link you clicked may be broken or the page may have been
              removed.
            </Row>
            <Row noGutters className="not-found-links-row">
              <p>VISIT THE</p>&nbsp;
              <Link to="/">HOMEPAGE</Link>&nbsp;
              <p>OR</p>&nbsp;
              <Link to="/contact">CONTACT US</Link>&nbsp;
              <p>ABOUT THE PROBLEM</p>
            </Row>
          </React.Fragment>
        );
      } else {
        // if the user is not an admin but logged in
        innerContent = (
          <Row noGutters className="not-found-content-row">
            Your account does not have access to this feature. Please contact an
            administrator
          </Row>
        );
      }
    } else {
      if (pathname.includes("/admin")) {
        innerContent = (
          <Row noGutters className="not-found-content-row">
            Please{" "}
            <Link className="not-found-link" to="/login">
              Log in
            </Link>{" "}
            to access this feature
          </Row>
        );
        className = className + " admin-type";
      } else {
        innerContent = (
          <React.Fragment>
            <Row noGutters className="not-found-header-row">
              PAGE NOT FOUND
            </Row>
            <Row noGutters className="not-found-content-row">
              The link you clicked may be broken or the page may have been
              removed
            </Row>
          </React.Fragment>
        );
      }
    }

    return (
      <Container fluid className="new-login-container">
        <Card className="set-width-login-card login-box">
          <Row noGutters>
            <Col className="login-content-col">
              <Row noGutters className="login-header">
                <Link to={"/"}>
                  <Image fluid src={logo} className="login-logo" />
                </Link>
              </Row>

              <Row
                noGutters
                className="login-box-padding login-formcontrol-row"
              >
                <Col className="login-box-col">{innerContent}</Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
} // end of NotFound extends Component
