import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  FormControl,
  Spinner,
  Table,
  Button,
  Modal
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSearch,
  AiOutlineReload,
  AiOutlinePlus
} from "react-icons/ai";
import {
  searchUsersRequest,
  searchUsersClear
} from "../../../redux/actions/admin";
import {
  searchAnnouncementsRequest,
  clearAnnouncements,
  deleteAnnouncementRequest,
  publishAnnouncementRequest,
  unpublishAnnouncementRequest
} from "../../../redux/actions/announcements";

const mapStateToProps = state => ({
  users: state.admin.users,
  announcements: state.announcements.announcements
});

const mapDispatchToProps = {
  searchUsersClear,
  searchUsersRequest,
  searchAnnouncementsRequest,
  clearAnnouncements,
  deleteAnnouncementRequest,
  publishAnnouncementRequest,
  unpublishAnnouncementRequest
};

class Announcements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        author: "",
        title: "",
        symbol: "",
        published: ""
      },
      show: false,
      deleteDraftData: {},
      showPublishModal: false,
      publishDraftData: {}
    };
  }

  componentDidMount() {
    this.resetParams();
  }

  componentWillUnmount() {
    this.resetParams();
  }

  getAllAnnouncements = () => {
    this.props.searchAnnouncementsRequest();
  };

  resetParams = () => {
    this.setState(
      {
        params: {
          author: "",
          title: "",
          symbol: "",
          published: ""
        }
      },
      () => {
        this.props.searchUsersClear();
        this.props.clearAnnouncements();
        this.props.searchAnnouncementsRequest();
      }
    );
  };

  handleSelectUser = id => {
    this.setState(
      {
        params: {
          ...this.state.params,
          author: id
        }
      },
      () => this.props.searchAnnouncementsRequest(this.state.params)
    );
  };

  editAnnouncement = id => {
    this.props.history.push(`/admin/create-announcement?id=${id}`);
  };

  changeParams = e => {
    const { name, value } = e.target;
    this.setState(
      {
        params: {
          ...this.state.params,
          [name]: value
        }
      },
      () => {
        if (name == "author") {
          this.props.searchUsersRequest(this.state.params);
        } else {
          this.props.searchAnnouncementsRequest(this.state.params);
        }
      }
    );
  };

  deleteDraft = (id, params) => {
    this.hideModal();
    this.props.deleteAnnouncementRequest(id, params);
  };

  hideModal = () => {
    this.setState({
      show: false,
      deleteDraftData: {}
    });
  };

  showModal = data => {
    this.setState({
      show: true,
      deleteDraftData: data
    });
  };

  hidePublishModal = () => {
    this.setState({
      showPublishModal: false,
      publishDraftData: {}
    });
  };

  showPublishModal = data => {
    this.setState({
      showPublishModal: true,
      publishDraftData: data
    });
  };

  handlePublish = id => {
    this.props.publishAnnouncementRequest(id, this.state.params);
    this.hidePublishModal();
  };

  handleUnpublish = id => {
    this.props.unpublishAnnouncementRequest(id, this.state.params);
    this.hidePublishModal();
  };

  /*****************************************************************************/

  renderResetButton = () => {
    return (
      <AiOutlineReload
        onClick={this.resetParams}
        className="individual-form-icon"
        style={{ marginRight: "16px" }}
      />
    );
  };

  renderCreateButton = () => {
    return (
      <AiOutlinePlus
        onClick={this.resetParams}
        className="individual-form-icon"
      />
    );
  };

  renderSearchbar = () => {
    const { author, title, status, symbol } = this.state.params;

    return (
      <Row noGutters className="submissions-search-bar">
        <div className="reset-icon">{this.renderResetButton()}</div>

        <Col>
          <label className="submission-form-control-label">Author:</label>
          <FormControl
            size="sm"
            name="author"
            value={author}
            onChange={this.changeParams}
            className="submission-form-control"
          />
        </Col>
        <Col>
          <label className="submission-form-control-label">Title:</label>
          <FormControl
            size="sm"
            onChange={this.changeParams}
            name="title"
            value={title}
            className="submission-form-control"
          />
        </Col>
        <Col>
          <label className="submission-form-control-label">Symbol:</label>
          <FormControl
            size="sm"
            onChange={this.changeParams}
            name="symbol"
            value={symbol}
            className="submission-form-control"
          />
        </Col>
        <Col>
          <label className="submission-form-control-label">Status:</label>
          <FormControl
            size="sm"
            onChange={this.changeParams}
            name="published"
            as="select"
            value={status}
            className="submission-form-control"
          >
            <option value="">Published & Draft</option>
            <option value="true">Published</option>
            <option value="false">Draft</option>
          </FormControl>
        </Col>
      </Row>
    );
  };

  renderUsers = () => {
    if (this.props.users) {
      const { data, loaded, loading } = this.props.users;
      let content;
      if (loaded) {
        if (data.total > 0) {
          content = data.docs.map(user => this.renderUser(user));
        } else {
          content = (
            <Row noGutters className="no-users-found-row">
              No users found
            </Row>
          );
        }
      } else if (loading) {
        content = this.renderUsersLoading();
      }
      return (
        <Row noGutters className="view-reports-users-row">
          {content}
        </Row>
      );
    }
  };

  renderUser = user => {
    const { email, _id, role } = user;

    let className;
    if (_id === this.state.params.author) {
      className = "submission-user-selector selected";
    } else {
      className = "submission-user-selector";
    }

    return (
      <div
        key={_id}
        onClick={() => this.handleSelectUser(_id)}
        className={className}
      >
        {_id}
      </div>
    );
  };

  renderUsersLoading = () => (
    <Row noGutters className="no-users-found-row">
      <Spinner
        className="asset-profile-loading-spinner"
        animation="border"
        role="status"
        variant="primary"
      />
    </Row>
  );

  renderTable = () => {
    if (!this.props.announcements) {
      return;
    }
    const { data, loaded, loading } = this.props.announcements;
    let content;

    if (loading) {
      content = (
        <tr>
          <td colSpan="4">
            <Row noGutters className="table-loading-row">
              <Spinner
                className="asset-profile-loading-spinner"
                animation="border"
              />
            </Row>
          </td>
        </tr>
      );
    } else if (loaded) {
      if (data.total > 0) {
        content = data.docs.map((announcement, index) =>
          this.renderAnnouncement(announcement, index)
        );
      } else {
        content = (
          <tr>
            <td colSpan="4">
              <Row noGutters className="table-loading-row">
                No announcements found
              </Row>
            </td>
          </tr>
        );
      }
    } else {
      content = (
        <tr>
          <td colSpan="4">
            <Row noGutters className="table-loading-row">
              Search for announcements
            </Row>
          </td>
        </tr>
      );
    }

    return (
      <Table bordered className="admin-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Symbol</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>{content}</tbody>
      </Table>
    );
  };

  renderAnnouncement = (announcement, index) => {
    const { title, user_id, published, _id, symbol } = announcement;

    let status, publishBtn, viewOrEditBtn;
    if (published) {
      status = "Published";
      publishBtn = (
        <Button
          onClick={() => this.showPublishModal(announcement)}
          className="teal-button table-button"
          size="sm"
          style={{ marginRight: 4 }}
        >
          Unpublish
        </Button>
      );

      viewOrEditBtn = (
        <NavLink to={`/announcements/${_id}`}>
          <Button
            className="teal-button table-button"
            size="sm"
            style={{ marginRight: 4 }}
          >
            View
          </Button>
        </NavLink>
      );
    } else {
      status = "Draft";
      publishBtn = (
        <Button
          onClick={() => this.showPublishModal(announcement)}
          className="teal-button table-button"
          size="sm"
          style={{ marginRight: 4 }}
        >
          Publish
        </Button>
      );
      viewOrEditBtn = (
        <Button
          className="teal-button table-button"
          size="sm"
          style={{ marginRight: 4 }}
          onClick={() => this.editAnnouncement(_id)}
          disabled={published}
        >
          Edit
        </Button>
      );
    }

    const deleteBtn = (
      <Button
        onClick={() => this.showModal(announcement)}
        className="teal-button table-button"
        size="sm"
      >
        Delete
      </Button>
    );

    return (
      <tr key={`Announcement - id: ${_id} - index: ${index}`}>
        <td>{title || "Untitled Announcement"}</td>
        <td>{user_id._id}</td>
        <td>{symbol || "No symbol assigned"}</td>
        <td>
          <Row noGutters className="td-row" style={{ alignItems: "center" }}>
            {status}
            <div style={{}}>
              {publishBtn}
              {viewOrEditBtn}
              {deleteBtn}
            </div>
          </Row>
        </td>
      </tr>
    );
  };

  renderModal = () => {
    const { show, deleteDraftData } = this.state;

    const displayName = deleteDraftData.title
      ? deleteDraftData.title
      : deleteDraftData._id;

    return (
      <Modal
        centered
        className="admin-modal"
        show={show}
        onHide={this.hideModal}
      >
        <Modal.Header>Delete Draft</Modal.Header>

        <Modal.Body>
          <Row noGutters className="confirm-delete-modal-row">
            Are you sure you want to delete this draft?
          </Row>

          <Row noGutters className="confirm-delete-modal-row email-row">
            <b>{displayName}</b>
          </Row>

          <Row noGutters className="confirm-delete-modal-row">
            <Button
              onClick={() => this.deleteDraft(deleteDraftData._id)}
              className="delete-button"
              size="sm"
            >
              Delete
            </Button>

            <Button onClick={this.hideModal} className="teal-button" size="sm">
              Cancel
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  renderPublishModal = () => {
    const { showPublishModal, publishDraftData } = this.state;
    const displayName = publishDraftData.title || publishDraftData._id;

    let publishBtnText, titleText, func;
    if (publishDraftData.published) {
      publishBtnText = "Unpublish";
      titleText = "unpublish";
      func = () => this.handleUnpublish(publishDraftData._id);
    } else {
      publishBtnText = "Publish";
      titleText = "publish";
      func = () => this.handlePublish(publishDraftData._id);
    }

    return (
      <Modal
        centered
        className="admin-modal"
        show={showPublishModal}
        onHide={this.hidePublishModal}
      >
        <Modal.Header>{publishBtnText} Announcement</Modal.Header>

        <Modal.Body>
          <Row noGutters className="confirm-delete-modal-row">
            Are you sure you want to {titleText} this announcement?
          </Row>

          <Row noGutters className="confirm-delete-modal-row email-row">
            <b>{displayName}</b>
          </Row>

          <Row noGutters className="confirm-delete-modal-row">
            <Button onClick={func} className="delete-button" size="sm">
              {publishBtnText}
            </Button>

            <Button
              onClick={this.hidePublishModal}
              className="teal-button"
              size="sm"
            >
              Cancel
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return (
      <Container fluid className="admin-content-container">
        {this.renderModal()}
        {this.renderPublishModal()}
        <Row noGutters className="forms-header">
          <h4>Announcements</h4>
        </Row>

        <div className="forms-table-box">
          {this.renderSearchbar()}
          {this.renderUsers()}
          {this.renderTable()}
        </div>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
