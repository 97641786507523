import * as CONSTANTS from "../constants/admin";

export const getTradedSymbolsRequest = params => ({
  type: CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_REQUEST,
  params
});

export const getTradedSymbolsSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_SUCCESS,
  data
});

export const getTradedSymbolsError = error => ({
  type: CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_ERROR,
  error
});

/***********************************************************/

export const createTradedSymbolRequest = data => ({
  type: CONSTANTS.ADMIN_CREATE_TRADED_SYMBOL_REQUEST,
  data
});

export const createTradedSymbolSuccess = data => ({
  type: CONSTANTS.ADMIN_CREATE_TRADED_SYMBOL_SUCCESS,
  data
});

export const createTradedSymbolError = error => ({
  type: CONSTANTS.ADMIN_CREATE_TRADED_SYMBOL_ERROR,
  error
});

/***********************************************************/

export const searchUsersRequest = params => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_REQUEST,
  params
});

export const searchUsersSuccess = data => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_SUCCESS,
  data
});

export const searchUsersError = error => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_ERROR,
  error
});

export const searchUsersClear = () => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_CLEAR
});

/***********************************************************/

export const searchReportsRequest = params => ({
  type: CONSTANTS.ADMIN_SEARCH_REPORTS_REQUEST,
  params
});

export const searchReportsSuccess = data => ({
  type: CONSTANTS.ADMIN_SEARCH_REPORTS_SUCCESS,
  data
});

export const searchReportsError = error => ({
  type: CONSTANTS.ADMIN_SEARCH_REPORTS_ERROR,
  error
});

export const searchReportsClear = () => ({
  type: CONSTANTS.ADMIN_SEARCH_REPORTS_CLEAR
});

/***********************************************************/

export const createOfferingRequest = profileId => ({
  type: CONSTANTS.ADMIN_CREATE_OFFERING_REQUEST,
  profileId
});

export const createOfferingSuccess = data => ({
  type: CONSTANTS.ADMIN_CREATE_OFFERING_SUCCESS,
  data
});

export const createOfferingError = error => ({
  type: CONSTANTS.ADMIN_CREATE_OFFERING_ERROR,
  error
});

/***********************************************************/

export const getOfferRequest = offerId => ({
  type: CONSTANTS.ADMIN_GET_OFFER_REQUEST,
  offerId
});

export const getOfferSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_OFFER_SUCCESS,
  data
});

export const getOfferError = error => ({
  type: CONSTANTS.ADMIN_GET_OFFER_ERROR,
  error
});

/***********************************************************/

export const updateOfferRequest = params => ({
  type: CONSTANTS.ADMIN_UPDATE_OFFER_REQUEST,
  params
});

export const updateOfferSuccess = data => ({
  type: CONSTANTS.ADMIN_UPDATE_OFFER_SUCCESS,
  data
});

export const updateOfferError = error => ({
  type: CONSTANTS.ADMIN_UPDATE_OFFER_ERROR,
  error
});

/***********************************************************/

export const getWebsiteConfigRequest = () => ({
  type: CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_REQUEST
});

export const getWebsiteConfigSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_SUCCESS,
  data
});

export const getWebsiteConfigError = error => ({
  type: CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_ERROR,
  error
});

/***********************************************************/

export const updateWebsiteConfigRequest = data => ({
  type: CONSTANTS.ADMIN_UPDATE_WEBSITE_CONFIG_REQUEST,
  data
});

export const updateWebsiteConfigSuccess = data => ({
  type: CONSTANTS.ADMIN_UPDATE_WEBSITE_CONFIG_SUCCESS,
  data
});

export const updateWebsiteConfigError = error => ({
  type: CONSTANTS.ADMIN_UPDATE_WEBSITE_CONFIG_ERROR,
  error
});

export const softUpdateWebsiteConfig = data => ({
  type: CONSTANTS.SOFT_UPDATE_WEBSITE_CONFIG,
  data
});
