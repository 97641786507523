import React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import { Container, Row, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../images/HomePage/FI_LOGO.png";

export default () => (
  <Container fluid className="tos-overhead-container">
    <Row noGutters className="tos-container-row">
      <div className="tos-container-div">
        <h1 className="tos-header">Privacy Policy</h1>
        <div className="tos-container">To be determined</div>
      </div>
    </Row>
  </Container>
);
