import React, { Fragment, PureComponent } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Row,
  Col,
  Image,
  Nav,
  Modal,
  Container,
  Spinner
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as CONTAINER from "../containers";
import * as COMPONENT from "../components";

import jwt from "jsonwebtoken";
import { createBrowserHistory } from "history";

class Routes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accountData: {
        authenticated: false,
        data: {},
        loaded: false,
        loading: false,
        error: ""
      },
      currentRoutes: "",
      websiteConfig: this.props.websiteConfig
    };
  }

  componentDidMount() {
    this.props.autoLoginRequest();
    this.props.getWebsiteConfigRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        accountData: nextProps.accountData,
        websiteConfig: nextProps.websiteConfig
      });
    }
  }

  unauthenticatedRoutes = () => {
    return (
      <Switch onUpdate={window.scrollTo(0, 0)}>
        <Route exact path="/" component={CONTAINER.SearchAssets} />
        <Route exact path="/login" component={CONTAINER.Login} />
        <Route
          exact
          path="/two-factor-auth"
          component={CONTAINER.TwoFactorAuth}
        />
        <Route exact path="/forgot" component={CONTAINER.ForgotPassword} />
        <Route exact path="/reset" component={CONTAINER.Reset} />
        <Route exact path="/register" component={CONTAINER.Register} />
        <Route exact path="/about" component={CONTAINER.HomePage} />
        <Route
          exact
          path="/privacy_policy"
          component={CONTAINER.PrivacyPolicy}
        />
        <Route
          exact
          path="/forgot-password"
          component={CONTAINER.ForgotPassword}
        />
        <Route
          exact
          path="/assets/:asset_name"
          component={CONTAINER.UserTokenDetailPage}
        />
        <Route
          exact
          path="/announcements/:announcement_id"
          component={CONTAINER.Announcements}
        />
        <Route component={CONTAINER.NotFound} />
      </Switch>
    );
  }; // end of unauthenticatedRoutes()

  authenticatedRoutes = () => (
    <Switch onUpdate={window.scrollTo(0, 0)}>
      <Route exact path="/" component={CONTAINER.SearchAssets} />
      <Route exact path="/login" component={CONTAINER.SearchAssets} />
      <Route exact path="/register" component={CONTAINER.SearchAssets} />
      <Route exact path="/about" component={CONTAINER.HomePage} />
      <Route exact path="/privacy_policy" component={CONTAINER.PrivacyPolicy} />
      <Route
        exact
        path="/forgot-password"
        component={CONTAINER.ForgotPassword}
      />
      <Route
        exact
        path="/assets/:asset_name"
        component={CONTAINER.UserTokenDetailPage}
      />
      <Route
        exact
        path="/announcements/:announcement_id"
        component={CONTAINER.Announcements}
      />
      <Route component={CONTAINER.NotFound} />
    </Switch>
  ); // end of authenticatedRoutes()

  adminRoutes = () => (
    <Switch onUpdate={window.scrollTo(0, 0)}>
      <Route exact path="/" component={CONTAINER.SearchAssets} />
      <Route exact path="/login" component={CONTAINER.SearchAssets} />
      <Route exact path="/register" component={CONTAINER.SearchAssets} />
      <Route exact path="/about" component={CONTAINER.HomePage} />
      <Route exact path="/privacy_policy" component={CONTAINER.PrivacyPolicy} />
      <Route
        exact
        path="/forgot-password"
        component={CONTAINER.ForgotPassword}
      />
      <Route
        exact
        path="/assets/:asset_name"
        component={CONTAINER.UserTokenDetailPage}
      />
      <Route
        exact
        path="/announcements/:announcement_id"
        component={CONTAINER.Announcements}
      />
      <Route path="/admin" component={CONTAINER.Admin} />
      <Route component={CONTAINER.NotFound} />
    </Switch>
  ); // end of adminRoutes()

  adminContributorRoutes = () => (
    <Switch onUpdate={window.scrollTo(0, 0)}>
      <Route exact path="/" component={CONTAINER.SearchAssets} />
      <Route exact path="/login" component={CONTAINER.SearchAssets} />
      <Route exact path="/register" component={CONTAINER.SearchAssets} />
      <Route exact path="/about" component={CONTAINER.HomePage} />
      <Route exact path="/privacy_policy" component={CONTAINER.PrivacyPolicy} />
      <Route
        exact
        path="/forgot-password"
        component={CONTAINER.ForgotPassword}
      />
      <Route
        exact
        path="/assets/:asset_name"
        component={CONTAINER.UserTokenDetailPage}
      />
      <Route
        exact
        path="/announcements/:announcement_id"
        component={CONTAINER.Announcements}
      />
      <Route path="/admin" component={CONTAINER.Admin} />
      <Route component={CONTAINER.NotFound} />
    </Switch>
  );

  contributorRoutes = () => (
    <Switch onUpdate={window.scrollTo(0, 0)}>
      <Route exact path="/" component={CONTAINER.SearchAssets} />
      <Route exact path="/login" component={CONTAINER.SearchAssets} />
      <Route exact path="/register" component={CONTAINER.SearchAssets} />
      <Route exact path="/about" component={CONTAINER.HomePage} />
      <Route exact path="/privacy_policy" component={CONTAINER.PrivacyPolicy} />
      <Route
        exact
        path="/forgot-password"
        component={CONTAINER.ForgotPassword}
      />
      <Route
        exact
        path="/assets/:asset_name"
        component={CONTAINER.UserTokenDetailPage}
      />
      <Route
        exact
        path="/announcements/:announcement_id"
        component={CONTAINER.Announcements}
      />
      <Route component={CONTAINER.NotFound} />
    </Switch>
  ); // end of contributorRoutes()

  renderRoutesBasedOnUserStatus = () => {
    const { data, authenticated, loaded } = this.state.accountData;
    if (authenticated && loaded) {
      if (data.isAdmin && data.isContributor === true) {
        // if user is an admin & contributor
        console.log("adminContributorRoutes");
        this.setState({
          currentRoutes: "adminContributorRoutes"
        });
        return this.adminContributorRoutes();
      } else if (data.isContributor === true) {
        // if user is a contributor
        console.log("contributorRoutes");
        this.setState({
          currentRoutes: "contributorRoutes"
        });
        return this.contributorRoutes();
      } else if (data.isAdmin) {
        // if user is an admin
        console.log("adminRoutes");
        this.setState({
          currentRoutes: "adminRoutes"
        });
        return this.adminRoutes();
      } else {
        // if user has an accessToken (logged in)
        console.log("authenticatedRoutes");
        this.setState({
          currentRoutes: "authenticatedRoutes"
        });
        return this.authenticatedRoutes();
      }
    } else {
      // everything else
      console.log("unauthenticatedRoutes");
      this.setState({
        currentRoutes: "unauthenticatedRoutes"
      });
      return this.unauthenticatedRoutes();
    }
  }; // end of renderRoutesBasedOnUserStatus()

  renderLoginModal = () => {
    return (
      <Modal
        show={this.state.showLoginModalStatus}
        className="login-modal"
        onHide={this.handleCloseModal}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Row className="no-padding">
            <div className="login-modal-header">
              Create an account to engage with Velocity Channel content, follow
              content contributors, create personalized lists of research, and
              more!
            </div>
          </Row>
          <Row>
            <Button
              className="sign-up-button"
              onClick={e => this.handleModalButton(e, "/register")}
            >
              Sign Up
            </Button>
          </Row>
          <Row className="no-padding">
            <div className="login-modal-bottom-text">
              Already have an account?
              <div
                className="login-link"
                onClick={e => this.handleModalButton(e, "/login")}
              >
                Sign in
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  renderPane = () => {
    const currentLocation = this.props.history.location;
    const { loading, loaded, error } = this.state.accountData;

    let content;
    if (loading) {
      content = (
        <Container fluid className="app-container">
          <Row noGutters className="app-row">
            <Col className="content-pane">
              <Row noGutters className="test-row">
                {this.renderLoading()}
              </Row>
            </Col>
          </Row>
        </Container>
      );
    } else {
      if (currentLocation.pathname.includes("/admin")) {
        content = (
          <Container fluid className="app-container">
            {this.renderRoutesBasedOnUserStatus()}
          </Container>
        );
      } else if (
        currentLocation.pathname.includes("/login") ||
        currentLocation.pathname.includes("/register") ||
        currentLocation.pathname.includes("/forgot") ||
        currentLocation.pathname.includes("/two-factor-auth")
      ) {
        content = (
          <Container fluid className="app-container">
            <Row noGutters className="app-row">
              <Col>
                {this.renderTopbar()}
                {this.renderRoutesBasedOnUserStatus()}
                {this.renderFooter()}
              </Col>
            </Row>
          </Container>
        );
      } else {
        const paneContainerClassName = this.props.sidebarOpened
          ? "pane-container"
          : "pane-container-small-sidebar";

        content = (
          <Container fluid className="app-container">
            <Row noGutters className="app-row">
              <COMPONENT.Sidebar />
              <div className="new-pane-content">
                <COMPONENT.Topbar />
                <Container fluid className={paneContainerClassName}>
                  {this.renderRoutesBasedOnUserStatus()}
                </Container>
              </div>
            </Row>
          </Container>
        );
      }
    }

    return content;
  };

  renderLoading = () => (
    <Spinner className="render-spinner" animation="border" />
  );

  renderTopbar = () => {
    const currentLocation = this.props.history.location.pathname;
    let content = <CONTAINER.Topbar accountData={this.state.accountData} />;

    if (this.state.currentRoutes == "unauthenticatedRoutes") {
      if (
        currentLocation.includes("/login") ||
        currentLocation.includes("/forgot") ||
        currentLocation.includes("/register")
      ) {
        content = "";
      }
    }

    return content;
  };

  renderFooter = () => {
    let content = <CONTAINER.Footer />;
    // if (this.state.currentRoutes == 'unauthenticatedRoutes') {
    //   content = ''
    // }

    return content;
  };

  render() {
    return (
      <Fragment>
        <div className="app-content">
          <ToastContainer hideProgressBar={true} autoClose={3000} />
          {this.renderPane()}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Routes);
