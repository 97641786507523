export const CREATE_ANNOUNCEMENT_REQUEST = "CREATE_ANNOUNCEMENT_REQUEST";
export const CREATE_ANNOUNCEMENT_SUCCESS = "CREATE_ANNOUNCEMENT_SUCCESS";
export const CREATE_ANNOUNCEMENT_ERROR = "CREATE_ANNOUNCEMENT_ERROR";

export const DELETE_ANNOUNCEMENT_REQUEST = "DELETE_ANNOUNCEMENT_REQUEST";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_ERROR = "DELETE_ANNOUNCEMENT_ERROR";

export const EDIT_ANNOUNCEMENT_REQUEST = "EDIT_ANNOUNCEMENT_REQUEST";
export const EDIT_ANNOUNCEMENT_SUCCESS = "EDIT_ANNOUNCEMENT_SUCCESS";
export const EDIT_ANNOUNCEMENT_ERROR = "EDIT_ANNOUNCEMENT_ERROR";

export const PUBLISH_ANNOUNCEMENT_REQUEST = "PUBLISH_ANNOUNCEMENT_REQUEST";
export const PUBLISH_ANNOUNCEMENT_SUCCESS = "PUBLISH_ANNOUNCEMENT_SUCCESS";
export const PUBLISH_ANNOUNCEMENT_ERROR = "PUBLISH_ANNOUNCEMENT_ERROR";

export const UNPUBLISH_ANNOUNCEMENT_REQUEST = "UNPUBLISH_ANNOUNCEMENT_REQUEST";
export const UNPUBLISH_ANNOUNCEMENT_SUCCESS = "UNPUBLISH_ANNOUNCEMENT_SUCCESS";
export const UNPUBLISH_ANNOUNCEMENT_ERROR = "UNPUBLISH_ANNOUNCEMENT_ERROR";

export const SEARCH_ANNOUNCEMENTS_REQUEST = "SEARCH_ANNOUNCEMENTS_REQUEST";
export const SEARCH_ANNOUNCEMENTS_SUCCESS = "SEARCH_ANNOUNCEMENTS_SUCCESS";
export const SEARCH_ANNOUNCEMENTS_ERROR = "SEARCH_ANNOUNCEMENTS_ERROR";

export const GET_ANNOUNCEMENT_REQUEST = "GET_ANNOUNCEMENT_REQUEST";
export const GET_ANNOUNCEMENT_SUCCESS = "GET_ANNOUNCEMENT_SUCCESS";
export const GET_ANNOUNCEMENT_ERROR = "GET_ANNOUNCEMENT_ERROR";

export const CLEAR_ANNOUNCEMENTS = "CLEAR_ANNOUNCEMENTS";
export const CLEAR_CURRENT_ANNOUNCEMENT = "CLEAR_CURRENT_ANNOUNCEMENTS";

export const ATTACH_FILE_TO_ANNOUNCEMENT_REQUEST =
  "ATTACH_FILE_TO_ANNOUNCEMENT_REQUEST";
export const ATTACH_FILE_TO_ANNOUNCEMENT_SUCCESS =
  "ATTACH_FILE_TO_ANNOUNCEMENT_SUCCESS";
export const ATTACH_FILE_TO_ANNOUNCEMENT_ERROR =
  "ATTACH_FILE_TO_ANNOUNCEMENT_ERROR";

export const REMOVE_FILE_FROM_ANNOUNCEMENT_REQUEST =
  "REMOVE_FILE_FROM_ANNOUNCEMENT_REQUEST";
export const REMOVE_FILE_FROM_ANNOUNCEMENT_SUCCESS =
  "REMOVE_FILE_FROM_ANNOUNCEMENT_SUCCESS";
export const REMOVE_FILE_FROM_ANNOUNCEMENT_ERROR =
  "REMOVE_FILE_FROM_ANNOUNCEMENT_ERROR";
