import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import { SIGNUP_REQUEST } from "../../constants/signup";
import { signupError, signupSuccess } from "../../actions/signup";
import { loginError, loginSuccess, loginRequest } from "../../actions/login";
import { getMyAccountRequest, clearUserCache } from "../../actions/account";
import request from "../../../utils/apiRequest";

function* signup(action) {
  try {
    let requestData = {
      username: action.username,
      email: action.email,
      password: action.password,
      cpassword: action.cpassword
    };
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("source")) {
      // on register component - check for a source query string parameter
      // if set - log the register event with a source field
      const source = urlParams.get("source");
      requestData.source = source;
    }
    const responseData = yield call(
      request,
      "/account/register",
      "POST",
      requestData,
      true
    );
    const loginData = {
      username: action.username,
      password: action.password
    };
    const loginResponseData = yield call(
      request,
      "/account/login",
      "POST",
      loginData,
      true
    );
    localStorage.setItem("refreshToken", loginResponseData.data.refreshToken);
    const accessData = yield call(
      request,
      "/account/accessToken",
      "POST",
      loginResponseData.data
    );
    localStorage.setItem("accessToken", accessData.data.access_token);
    yield put(loginSuccess(accessData));
    yield put(getMyAccountRequest());
    // const analyticsBody = {
    //   type: 'register',
    //   userId: responseData.data.userId,
    //   username: requestData.username,
    // }
    // const analyticsEventData = yield call(
    //   request,
    //   'analytics/event',
    //   'POST',
    //   analyticsBody,
    //   true,
    // )
    if (window.location.pathname === "/research/apply") {
      // this occurs on the contributor application
      // window.location.reload()
      toast.info(`Account created! Welcome ${action.username}`);
    } else if (localStorage.redirectPathname !== "/news") {
      history.push(localStorage.redirectPathname);
    } else {
      localStorage.setItem("redirectPathname", "/news");
      history.push("/news");
    }
    localStorage.setItem("redirectPathname", "/news");
  } catch (err) {
    toast.error(err.reason.message);
    yield put(signupError(err));
  }
}

export default function* signupSaga() {
  yield takeLatest(SIGNUP_REQUEST, signup);
}
