import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  Jumbotron,
  Spinner,
  Modal,
  Table,
  FormCheck,
  Pagination,
  Accordion,
  Card
} from "react-bootstrap";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineSetting,
  AiOutlineUp,
  AiOutlineDown,
  AiOutlineCopy
} from "react-icons/ai";
import { IoIosArrowRoundBack } from "react-icons/io";
const arrayMove = require("array-move");

class TokenTemplates extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tokenTemplates: {
        templates: {},
        loaded: false,
        loading: false,
        error: ""
      },
      tokenTemplateToEdit: {
        template: {},
        loading: false,
        loaded: false,
        error: ""
      },
      params: {
        offset: 0,
        q: "",
        isPublished: ""
      },
      confirmDeleteModal: {
        show: false,
        id: ""
      },
      configureModal: {
        show: false,
        fieldIndex: "",
        sectionIndex: ""
      },
      currentPage: 1,
      configurePreviewModal: {
        show: false
      }
    };
  }

  componentDidMount() {
    this.handleSearch();
    this.props.handleClearTokenTemplateToEdit();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        tokenTemplates: nextProps.tokenTemplates,
        tokenTemplateToEdit: nextProps.tokenTemplateToEdit,
        savingTemplate: nextProps.savingTemplate
      });
    }
  }

  handleSearch = () => {
    this.props.getTokenTemplatesRequest(this.state.params);
  };

  handleChangeParams = e => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value
        }
      },
      () => this.handleSearch()
    );
  };

  handleCreateTemplate = () => {
    this.props.createTokenTemplateRequest();
  };

  handleDeleteTemplate = id => {
    this.handleHideConfirmDeleteModal();
    this.props.deleteTokenTemplateRequest(id, this.state.params);
  };

  handleGetTemplateToEdit = id => {
    this.props.getTokenTemplateToEditRequest(id);
  };

  handleShowConfirmDeleteModal = id => {
    this.setState({
      confirmDeleteModal: {
        show: true,
        id: id
      }
    });
  };

  handleHideConfirmDeleteModal = () => {
    this.setState({
      confirmDeleteModal: {
        show: false
      }
    });
  };

  handleAddSection = () => {
    const singleSection = {
      name: "",
      fields: [],
      display: "List"
    };
    const newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections.push(singleSection);

    this.handleSetStateAndSaveTemplate();
  };

  handleSetStateAndSaveTemplate = () => {
    this.setState(
      {
        tokenTemplateToEdit: {
          ...this.state.tokenTemplateToEdit
        }
      },
      () =>
        this.props.editTokenTemplateRequest(
          this.state.tokenTemplateToEdit.template
        )
    );
  };

  handleAddField = sectionIndex => {
    const singleField = {
      fieldName: "",
      fieldType: "string",
      required: false
    };

    const newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex].fields.push(singleField);

    this.handleSetStateAndSaveTemplate();
  };

  handleEditSection = (e, sectionIndex) => {
    const newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex][e.target.name] = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteSection = sectionIndex => {
    const newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections.splice(sectionIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleChangeAssetType = (e, templateId) => {
    const newTemplate = this.state.tokenTemplateToEdit.template;
    newTemplate.name = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleReturnToAssetTemplates = () => {
    this.handleSearch();
    this.props.handleClearTokenTemplateToEdit();
  };

  handleEditField = (e, fieldIndex, sectionIndex) => {
    let newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex].fields[fieldIndex][e.target.name] =
      e.target.value;

    if (newSections[sectionIndex].fields[fieldIndex].fieldType == "dropdown") {
      newSections[sectionIndex].fields[fieldIndex].options = [""];
    } else if (
      newSections[sectionIndex].fields[fieldIndex].fieldType === "multi"
    ) {
      newSections[sectionIndex].fields[fieldIndex].subfield = {
        fieldType: "",
        fieldName: "",
        value: ""
      };
      newSections[sectionIndex].fields[fieldIndex].subfields = [];
    } else if (
      newSections[sectionIndex].fields[fieldIndex].fieldType === "object"
    ) {
      newSections[sectionIndex].fields[fieldIndex].subfields = [
        { fieldName: "", fieldType: "" }
      ];
    }

    this.handleSetStateAndSaveTemplate();
  };

  handleChangeRequired = (requiredValue, fieldIndex, sectionIndex) => {
    let newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex].fields[fieldIndex].required = !requiredValue;

    this.handleSetStateAndSaveTemplate();
  };

  handleChangePrivate = (privateValue, fieldIndex, sectionIndex) => {
    let newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex].fields[fieldIndex].private = !privateValue;

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteField = (fieldIndex, sectionIndex) => {
    const newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex].fields.splice(fieldIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleHideConfigureModal = () => {
    this.setState({
      configureModal: {
        show: false,
        fieldIndex: "",
        sectionIndex: ""
      }
    });
  };

  handleShowConfigureModal = (fieldIndex, sectionIndex) => {
    this.setState({
      configureModal: {
        show: true,
        fieldIndex: fieldIndex,
        sectionIndex: sectionIndex
      }
    });
  };

  handleConfigureMultiSubfieldType = (e, fieldIndex, sectionIndex) => {
    const field = this.state.tokenTemplateToEdit.template.sections[sectionIndex]
      .fields[fieldIndex];

    if (e.target.value == "dropdown") {
      field.subfield = {
        options: [""]
      };
    } else if (e.target.value == "object") {
      field.subfield = {
        fieldName: "",
        subfields: [{ fieldName: "", fieldType: "" }]
      };
    }

    field.subfield.fieldType = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleAddSubfieldDropdownOption = (fieldIndex, sectionIndex) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.options.push("");

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteSubfieldDropdownOption = (
    optionIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.options.splice(optionIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleAddSubfieldObject = (fieldIndex, sectionIndex) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.subfields.push({ fieldName: "", fieldType: "" });

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteSubfieldObject = (subfieldIndex, fieldIndex, sectionIndex) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.subfields.splice(subfieldIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleChangeSubfieldObjectName = (
    e,
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.subfields[subfieldIndex].fieldName = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleChangeSubfieldObjectFieldType = (
    e,
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.subfields[subfieldIndex].fieldType = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleChangeSubfieldDropdownOption = (
    e,
    optionIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const subfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    subfield.options[optionIndex] = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleEditSubfield = (e, optionIndex, fieldIndex, sectionIndex) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].fields;
    subfields[optionIndex] = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleEditObjectSubfieldFieldName = (
    e,
    optionIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfields;
    subfields[optionIndex].fieldName = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleEditObjectSubfieldFieldType = (
    e,
    optionIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfields;
    subfields[optionIndex].fieldType = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleAddSubfield = (fieldIndex, sectionIndex) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].fields;
    subfields.push("");

    this.handleSetStateAndSaveTemplate();
  };

  handleAddObjectSubfield = (fieldIndex, sectionIndex) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfields;
    subfields.push({ fieldName: "", fieldType: "" });

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteSubfield = (subfieldIndex, fieldIndex, sectionIndex) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].fields;
    subfields.splice(subfieldIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteObjectSubfield = (subfieldIndex, fieldIndex, sectionIndex) => {
    const subfields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfields;
    subfields.splice(subfieldIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleEditDropdownOption = (e, optionIndex, fieldIndex, sectionIndex) => {
    const options = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].options;
    options[optionIndex] = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  handleAddDropdownOption = (fieldIndex, sectionIndex) => {
    const options = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].options;
    options.push("");

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteDropdownOption = (subfieldIndex, fieldIndex, sectionIndex) => {
    const options = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].options;
    options.splice(subfieldIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleMoveSectionUp = sectionIndex => {
    const { sections } = this.state.tokenTemplateToEdit.template;
    const nextSectionIndex = sectionIndex - 1;
    const newSections = arrayMove(sections, sectionIndex, nextSectionIndex);

    this.state.tokenTemplateToEdit.template.sections = newSections;

    this.handleSetStateAndSaveTemplate();
  };

  handleMoveSectionDown = sectionIndex => {
    const { sections } = this.state.tokenTemplateToEdit.template;
    const nextSectionIndex = sectionIndex + 1;
    const newSections = arrayMove(sections, sectionIndex, nextSectionIndex);

    this.state.tokenTemplateToEdit.template.sections = newSections;

    this.handleSetStateAndSaveTemplate();
  };

  handleChangeFilterOnThisField = (filter, fieldIndex, sectionIndex) => {
    let newSections = this.state.tokenTemplateToEdit.template.sections;
    newSections[sectionIndex].fields[fieldIndex].filter = !filter;

    this.handleSetStateAndSaveTemplate();
  };

  handleMoveFieldUp = (fieldIndex, sectionIndex) => {
    const { fields } = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ];
    const nextFieldIndex = fieldIndex - 1;
    const newFields = arrayMove(fields, fieldIndex, nextFieldIndex);

    this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields = newFields;

    this.handleSetStateAndSaveTemplate();
  };

  handleMoveFieldDown = (fieldIndex, sectionIndex) => {
    const { fields } = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ];
    const nextFieldIndex = fieldIndex + 1;
    const newFields = arrayMove(fields, fieldIndex, nextFieldIndex);

    this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields = newFields;

    this.handleSetStateAndSaveTemplate();
  };

  handleClickPage = number => {
    this.setState(
      {
        currentPage: number,
        params: {
          ...this.state.params,
          offset: (number - 1) * 10
        }
      },
      () => this.handleSearch()
    );
  };

  handleChangeConfigurePreview = value => {
    const { preview } = this.state.tokenTemplateToEdit.template;

    if (preview.includes(value)) {
      for (let i = 0; i < preview.length; i++) {
        if (preview[i] == value) {
          preview.splice(i, 1);
        }
      }
    } else {
      preview.push(value);
    }

    this.handleSetStateAndSaveTemplate();
  };

  handleAddDropdownOptionToObjectSubfield = (
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const objectSubfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield.subfields[subfieldIndex];
    if (!objectSubfield.options) {
      objectSubfield.options = [];
    }

    objectSubfield.options.push("");

    this.handleSetStateAndSaveTemplate();
  };

  handleDeleteDropdownOptionFromObjectSubfield = (
    optionIndex,
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const objectSubfield = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield.subfields[subfieldIndex];
    objectSubfield.options.splice(optionIndex, 1);

    this.handleSetStateAndSaveTemplate();
  };

  handleEditDropdownOptionInObjectSubfield = (
    e,
    optionIndex,
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const options = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield.subfields[subfieldIndex].options;
    options[optionIndex] = e.target.value;

    this.handleSetStateAndSaveTemplate();
  };

  /*****************************************************************/

  renderTable = () => {
    const { templates, loading, loaded } = this.state.tokenTemplates;
    let content;

    if (loading) {
      content = this.renderTableLoading();
    } else if (loaded) {
      if (templates.total > 0) {
        content = templates.docs.map(template => this.renderTemplate(template));
      } else {
        content = (
          <tr>
            <td colSpan="3">
              <Row noGutters className="table-loading-row">
                No templates found
              </Row>
            </td>
          </tr>
        );
      }
    }

    return (
      <Table bordered className="admin-table">
        <thead>
          <tr>
            <th>Asset Type</th>
            <th>Id</th>
            <th>Published Status</th>
          </tr>
        </thead>

        <tbody>{content}</tbody>
      </Table>
    );
  };

  renderTemplate = template => {
    const { name, _id, isPublished } = template;

    let isPublishedView;
    let deleteButton;
    if (isPublished) {
      isPublishedView = <div id="true-content">Published</div>;
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled no-margin" />
      );
    } else {
      isPublishedView = <div id="false-content">Not Published</div>;
      deleteButton = (
        <AiOutlineDelete
          onClick={() => this.handleShowConfirmDeleteModal(_id)}
          className="individual-form-icon"
        />
      );
    }

    return (
      <tr key={_id}>
        <td>{name || "No type"}</td>
        <td>{_id}</td>
        <td>
          <Row noGutters className="td-row">
            {isPublishedView}
            <div>
              <AiOutlineEdit
                onClick={() => this.handleGetTemplateToEdit(_id)}
                className="individual-form-icon"
              />
              <AiOutlineCopy
                onClick={() => this.props.cloneTemplateRequest(_id)}
                className="individual-form-icon"
              />
              {deleteButton}
            </div>
          </Row>
        </td>
      </tr>
    );
  };

  renderSearchbar = () => {
    const { q, isPublished } = this.state.params;
    return (
      <Row noGutters className="submissions-search-bar">
        <Col>
          <label className="submission-form-control-label">Search:</label>
          <FormControl
            size="sm"
            name="q"
            value={q}
            onChange={this.handleChangeParams}
            placeholder="Search..."
            className="submission-form-control"
          />
        </Col>
        <Col>
          <label className="submission-form-control-label">
            Published Status:
          </label>
          <FormControl
            size="sm"
            name="isPublished"
            as="select"
            value={isPublished}
            onChange={this.handleChangeParams}
            className="submission-form-control"
          >
            <option value="">Select a Published Status...</option>
            <option value="false">False</option>
            <option value="true">True</option>
          </FormControl>
        </Col>
        <Col sm={2}>
          <Row noGutters className="token-template-create-row">
            <Button
              onClick={this.handleCreateTemplate}
              className="teal-button"
              size="sm"
            >
              Create Template
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  renderTableLoading = () => (
    <tr>
      <td colSpan="3">
        <Row noGutters className="table-loading-row">
          <Spinner
            className="asset-profile-loading-spinner"
            animation="border"
          />
        </Row>
      </td>
    </tr>
  );

  renderSelectedTemplate = () => {
    const { loaded, loading, template } = this.state.tokenTemplateToEdit;

    let content;
    if (loaded) {
      const { name, _id, isPublished } = template;

      let publishButton;
      let disabled;
      if (isPublished) {
        publishButton = (
          <Button
            onClick={() => this.props.unpublishTokenTemplateRequest(_id)}
            className="teal-button"
            size="sm"
          >
            Unpublish
          </Button>
        );
        disabled = true;
      } else {
        publishButton = (
          <Button
            onClick={() => this.props.publishTokenTemplateRequest(_id)}
            className="teal-button"
            size="sm"
          >
            Publish
          </Button>
        );
        disabled = false;
      }

      let configurePreviewButton;
      if (template.preview) {
        configurePreviewButton = (
          <Button
            onClick={this.handleShowConfigurePreviewModal}
            className="teal-button"
            size="sm"
          >
            Configure Preview
          </Button>
        );
      }

      content = (
        <React.Fragment>
          <Row noGutters className="forms-header edit-forms-header">
            <h4
              className="return-to-header"
              onClick={this.handleReturnToAssetTemplates}
            >
              <IoIosArrowRoundBack /> Return to Asset Templates
            </h4>
          </Row>

          <div className="forms-table-box">
            <Row noGutters className="submissions-search-bar">
              <Col>
                <label className="submission-form-control-label">
                  Asset Type:
                </label>
                <FormControl
                  size="sm"
                  name="name"
                  value={name}
                  disabled={disabled}
                  onChange={e => this.handleChangeAssetType(e, _id)}
                  placeholder="Asset Type..."
                  className="submission-form-control"
                />
              </Col>
              <Col>
                <Row noGutters className="token-template-create-row">
                  {configurePreviewButton}
                  <Button
                    disabled={disabled}
                    onClick={() => this.handleAddSection(template)}
                    className="teal-button"
                    size="sm"
                  >
                    Add Section
                  </Button>
                  {publishButton}
                </Row>
              </Col>
            </Row>
            {template.sections.map((section, index) =>
              this.renderSection(section, index, disabled)
            )}
          </div>
        </React.Fragment>
      );
    }

    return content;
  };

  renderSection = (section, sectionIndex, disabled) => {
    const { name, fields, display } = section;

    let upButton;
    if (sectionIndex !== 0) {
      upButton = (
        <AiOutlineUp
          onClick={() => this.handleMoveSectionUp(sectionIndex)}
          className="individual-form-icon up-button direction-button"
        />
      );
    }

    const sections = this.state.tokenTemplateToEdit.template.sections;
    let downButton;
    if (sectionIndex !== sections.length - 1 && sections.length > 1) {
      downButton = (
        <AiOutlineDown
          onClick={() => this.handleMoveSectionDown(sectionIndex)}
          className="individual-form-icon down-button direction-button"
        />
      );
    }

    return (
      <Row noGutters className="admin-edit-content-section" key={sectionIndex}>
        <Col>
          <Row noGutters className="admin-edit-content-row">
            <Col className="admin-edit-content-col">
              <label className="submission-form-control-label">
                Section Name:
              </label>
              <FormControl
                size="sm"
                name="name"
                value={name}
                disabled={disabled}
                onChange={e => this.handleEditSection(e, sectionIndex)}
                placeholder="Section Name..."
                className="submission-form-control"
              />
            </Col>
            <Col sm={4} className="admin-edit-content-col">
              <Row noGutters className="token-template-create-row">
                <div className="direction-button-container">
                  {upButton}
                  {downButton}
                </div>

                <Button
                  disabled={disabled}
                  onClick={() =>
                    this.handleToggleListAndTile(display, sectionIndex)
                  }
                  size="sm"
                  className="teal-button"
                >
                  {display || "List"}
                </Button>

                <Button
                  disabled={disabled}
                  onClick={() => this.handleAddField(sectionIndex)}
                  size="sm"
                  className="teal-button"
                >
                  Add Field
                </Button>
                <Button
                  disabled={disabled}
                  onClick={() => this.handleDeleteSection(sectionIndex)}
                  size="sm"
                  className="delete-button"
                >
                  Delete Section
                </Button>
              </Row>
            </Col>
          </Row>
          {fields.map((field, fieldIndex) =>
            this.renderField(field, fieldIndex, sectionIndex, disabled)
          )}
        </Col>
      </Row>
    );
  };

  handleToggleListAndTile = (display, sectionIndex) => {
    const newSections = this.state.tokenTemplateToEdit.template.sections;

    if (display == "List") {
      newSections[sectionIndex].display = "Tile";
    } else if (display == "Tile") {
      newSections[sectionIndex].display = "List";
    } else {
      newSections[sectionIndex].display = "Tile";
    }

    this.handleSetStateAndSaveTemplate();
  };

  renderField = (field, fieldIndex, sectionIndex, disabled) => {
    const { fieldName, fieldType, required, filter } = field;

    let configureButton;
    if (
      fieldType == "multi" ||
      fieldType == "dropdown" ||
      fieldType == "object"
    ) {
      configureButton = (
        <Button
          onClick={() =>
            this.handleShowConfigureModal(fieldIndex, sectionIndex)
          }
          className="teal-button configure-button"
          size="sm"
        >
          Configure
        </Button>
      );
    }

    let filterOnThisField;
    if (fieldType == "location" || fieldType == "dropdown") {
      filterOnThisField = (
        <FormCheck
          checked={filter}
          disabled={disabled}
          onChange={() =>
            this.handleChangeFilterOnThisField(filter, fieldIndex, sectionIndex)
          }
          className="dropdown-checkbox"
          size="sm"
          label="Filter on this field?"
        />
      );
    }

    let deleteButton;
    if (disabled) {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete
          disabled={disabled}
          onClick={() => this.handleDeleteField(fieldIndex, sectionIndex)}
          className="individual-form-icon"
        />
      );
    }

    let upButton;
    if (fieldIndex !== 0) {
      upButton = (
        <AiOutlineUp
          onClick={() => this.handleMoveFieldUp(fieldIndex, sectionIndex)}
          className="individual-form-icon up-button direction-button"
        />
      );
    }

    const fields = this.state.tokenTemplateToEdit.template.sections[
      sectionIndex
    ].fields;
    let downButton;
    if (fieldIndex !== fields.length - 1 && fields.length > 1) {
      downButton = (
        <AiOutlineDown
          onClick={() => this.handleMoveFieldDown(fieldIndex, sectionIndex)}
          className="individual-form-icon down-button direction-button"
        />
      );
    }

    return (
      <Row
        noGutters
        className="admin-edit-individual-field"
        key={`${fieldIndex} + ${sectionIndex}`}
      >
        <Col sm={3} className="admin-edit-content-col">
          <label className="submission-form-control-label">Field Name:</label>
          <FormControl
            size="sm"
            placeholder="Field Name..."
            value={fieldName}
            name="fieldName"
            disabled={disabled}
            onChange={e => this.handleEditField(e, fieldIndex, sectionIndex)}
            className="submission-form-control"
          />
        </Col>

        <Col sm={3} className="admin-edit-content-col">
          <label className="submission-form-control-label">Field Type:</label>
          <FormControl
            size="sm"
            as="select"
            value={fieldType}
            name="fieldType"
            disabled={disabled}
            onChange={e => this.handleEditField(e, fieldIndex, sectionIndex)}
            className="submission-form-control"
          >
            <option value="">Field Type...</option>
            <option value="string">String</option>
            <option value="paragraph">Paragraph</option>
            <option value="location">Location</option>
            <option value="number">Number</option>
            <option value="image">Image upload</option>
            <option value="video">Video embed</option>
            <option value="document">Document upload</option>
            <option value="dropdown">Dropdown</option>
            <option value="date">Date</option>
            <option value="link">Link</option>
            <option value="social">Social </option>
            <option value="multi">Multi-field</option>
            <option value="object">Object</option>
          </FormControl>
        </Col>

        <Col className="admin-edit-content-col">
          <Row noGutters className="token-template-create-row">
            {configureButton}
            {filterOnThisField}

            <FormCheck
              checked={field.private}
              name="private"
              disabled={disabled}
              onChange={() =>
                this.handleChangePrivate(
                  field.private,
                  fieldIndex,
                  sectionIndex
                )
              }
              className="dropdown-checkbox"
              size="sm"
              label="Private?"
            />

            <FormCheck
              checked={required}
              name="required"
              disabled={disabled}
              onChange={() =>
                this.handleChangeRequired(required, fieldIndex, sectionIndex)
              }
              className="dropdown-checkbox"
              size="sm"
              label="Required?"
            />

            {deleteButton}

            <div className="direction-button-container">
              {upButton}
              {downButton}
            </div>
          </Row>
        </Col>
      </Row>
    );
  };

  renderConfigureModal = () => {
    const { show, fieldIndex, sectionIndex } = this.state.configureModal;

    let content;
    let addButton;
    if (show) {
      const isPublished = this.state.tokenTemplateToEdit.template.isPublished;
      const field = this.state.tokenTemplateToEdit.template.sections[
        sectionIndex
      ].fields[fieldIndex];

      let disabled;
      if (isPublished) {
        disabled = true;
      } else {
        disabled = false;
      }

      let fieldsRender;
      let extraOptions;
      if (field.fieldType == "dropdown") {
        fieldsRender = field.options.map((option, optionIndex) =>
          this.renderDropdownOption(
            option,
            optionIndex,
            fieldIndex,
            sectionIndex,
            disabled
          )
        );
        addButton = (
          <Button
            disabled={disabled}
            onClick={() =>
              this.handleAddDropdownOption(fieldIndex, sectionIndex)
            }
            className="teal-button"
            size="sm"
          >
            Add Dropdown Option
          </Button>
        );
      } else if (field.fieldType == "multi") {
        const subfield = this.state.tokenTemplateToEdit.template.sections[
          sectionIndex
        ].fields[fieldIndex].subfield;
        if (subfield.fieldType == "dropdown") {
          extraOptions = (
            <>
              <Row noGutters className="tt-subfield-button-row">
                <Button
                  disabled={disabled}
                  onClick={() =>
                    this.handleAddSubfieldDropdownOption(
                      fieldIndex,
                      sectionIndex
                    )
                  }
                  className="teal-button"
                  size="sm"
                >
                  Add Dropdown Option
                </Button>
              </Row>
              {subfield.options.map((option, optionIndex) =>
                this.renderSubfieldOption(
                  option,
                  optionIndex,
                  fieldIndex,
                  sectionIndex,
                  disabled
                )
              )}
            </>
          );
        } else if (subfield.fieldType == "object") {
          extraOptions = (
            <>
              <Row noGutters className="tt-subfield-button-row">
                <Button
                  disabled={disabled}
                  onClick={() =>
                    this.handleAddSubfieldObject(fieldIndex, sectionIndex)
                  }
                  className="teal-button"
                  size="sm"
                >
                  Add Subfield Object
                </Button>
              </Row>
              {subfield.subfields.map((subfield, subfieldIndex) =>
                this.renderSubfieldObject(
                  subfield,
                  subfieldIndex,
                  fieldIndex,
                  sectionIndex,
                  disabled
                )
              )}
            </>
          );
        }
        fieldsRender = (
          <>
            <Row noGutters className="tt-subfield-configure-button-row">
              <label className="submission-form-control-label crucial-width">
                Subfield Type:
              </label>
            </Row>

            <FormControl
              size="sm"
              as="select"
              disabled={disabled}
              value={subfield.fieldType}
              onChange={e =>
                this.handleConfigureMultiSubfieldType(
                  e,
                  fieldIndex,
                  sectionIndex
                )
              }
              className="submission-form-control"
            >
              <option value="">Subfield Type...</option>
              <option value="string">String</option>
              <option value="paragraph">Paragraph</option>
              <option value="location">Location</option>
              <option value="number">Number</option>
              <option value="image">Image upload</option>
              <option value="video">Video embed</option>
              <option value="document">Document upload</option>
              <option value="dropdown">Dropdown</option>
              <option value="date">Date</option>
              <option value="link">Link</option>
              <option value="social">Social </option>
              <option value="object">Object</option>
            </FormControl>

            {extraOptions}
          </>
        );
      } else if (field.fieldType == "object") {
        fieldsRender = field.subfields.map((option, optionIndex) =>
          this.renderObjectOption(
            option,
            optionIndex,
            fieldIndex,
            sectionIndex,
            disabled
          )
        );
        let objectDisabled = false;
        if (disabled || field.subfields.length == 4) {
          objectDisabled = true;
        }
        addButton = (
          <Button
            disabled={objectDisabled}
            onClick={() =>
              this.handleAddObjectSubfield(fieldIndex, sectionIndex)
            }
            className="teal-button"
            size="sm"
          >
            Add Subfield (max of 4 subfields)
          </Button>
        );
      }

      content = (
        <Row noGutters className="admin-edit-configure-modal-content">
          <Col>{fieldsRender}</Col>
        </Row>
      );
    }

    return (
      <Modal
        centered
        className="admin-modal"
        show={show}
        onHide={this.handleHideConfigureModal}
      >
        <Modal.Header closeButton>Configure</Modal.Header>

        <Modal.Body>{content}</Modal.Body>

        <Modal.Footer>
          <Row noGutters className="admin-edit-configure-modal-header">
            {addButton}
            <Button
              onClick={this.handleHideConfigureModal}
              className="teal-button"
              size="sm"
            >
              Close
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  };

  renderSubfieldOption = (
    option,
    optionIndex,
    fieldIndex,
    sectionIndex,
    disabled
  ) => {
    let deleteButton;
    if (!disabled) {
      deleteButton = (
        <AiOutlineDelete
          className="individual-form-icon"
          onClick={() =>
            this.handleDeleteSubfieldDropdownOption(
              optionIndex,
              fieldIndex,
              sectionIndex
            )
          }
        />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    }
    return (
      <Row noGutters className="tt-subfield-row">
        <FormControl
          disabled={disabled}
          className="submission-form-control"
          size="sm"
          value={option}
          placeholder="Dropdown option..."
          onChange={e =>
            this.handleChangeSubfieldDropdownOption(
              e,
              optionIndex,
              fieldIndex,
              sectionIndex
            )
          }
        />
        {deleteButton}
      </Row>
    );
  };

  renderSubfieldObject = (
    subfield,
    subfieldIndex,
    fieldIndex,
    sectionIndex,
    disabled
  ) => {
    let deleteButton;
    if (!disabled) {
      deleteButton = (
        <AiOutlineDelete
          className="individual-form-icon"
          onClick={() =>
            this.handleDeleteSubfieldObject(
              subfieldIndex,
              fieldIndex,
              sectionIndex
            )
          }
        />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    }

    let configureButton;
    if (subfield.fieldType == "dropdown") {
      configureButton = (
        <Button
          className="teal-button tt-subfield-options-configure-button"
          size="sm"
        >
          Configure
        </Button>
      );
    }

    let dropdownAccordion;
    if (subfield.fieldType == "dropdown") {
      dropdownAccordion = (
        <Accordion className="tt-subfield-configure-accordion">
          <Button
            className="teal-button tt-subfield-add-button"
            disabled={disabled}
            onClick={() =>
              this.handleAddDropdownOptionToObjectSubfield(
                subfieldIndex,
                fieldIndex,
                sectionIndex
              )
            }
          >
            Add Dropdown Option
          </Button>
          <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
            Configure Dropdown Options
          </Accordion.Toggle>
          <Accordion.Collapse className="content" eventKey="0">
            <Row noGutters>
              <Col>
                {subfield.options ? (
                  subfield.options.map((option, optionIndex) => {
                    return (
                      <Row
                        noGutters
                        className="tt-subfield-dropdown-formControl"
                      >
                        <FormControl
                          disabled={disabled}
                          value={option}
                          onChange={e =>
                            this.handleEditDropdownOptionInObjectSubfield(
                              e,
                              optionIndex,
                              subfieldIndex,
                              fieldIndex,
                              sectionIndex
                            )
                          }
                          placeholder="Dropdown option..."
                          className="submission-form-control"
                          size="sm"
                        />
                        {!disabled ? (
                          <AiOutlineDelete
                            className="individual-form-icon"
                            onClick={() =>
                              this.handleDeleteDropdownOptionFromObjectSubfield(
                                optionIndex,
                                subfieldIndex,
                                fieldIndex,
                                sectionIndex
                              )
                            }
                          />
                        ) : (
                          <AiOutlineDelete className="individual-form-icon-disabled" />
                        )}
                      </Row>
                    );
                  })
                ) : (
                  <div className="no-dropdown-options-added">
                    No dropdown options added
                  </div>
                )}
              </Col>
            </Row>
          </Accordion.Collapse>
        </Accordion>
      );
    }

    return (
      <Row noGutters>
        <Col>
          <Row noGutters className="tt-subfield-options-row">
            <FormControl
              disabled={disabled}
              className="submission-form-control"
              size="sm"
              value={subfield.fieldName}
              onChange={e =>
                this.handleChangeSubfieldObjectName(
                  e,
                  subfieldIndex,
                  fieldIndex,
                  sectionIndex
                )
              }
              placeholder="Subfield name..."
            />
            <FormControl
              disabled={disabled}
              className="submission-form-control"
              size="sm"
              value={subfield.fieldType}
              placeholder="Subfield type..."
              as="select"
              onChange={e =>
                this.handleChangeSubfieldObjectFieldType(
                  e,
                  subfieldIndex,
                  fieldIndex,
                  sectionIndex
                )
              }
            >
              <option value="">Subfield Type...</option>
              <option value="string">String</option>
              <option value="paragraph">Paragraph</option>
              <option value="location">Location</option>
              <option value="number">Number</option>
              <option value="image">Image upload</option>
              <option value="video">Video embed</option>
              <option value="document">Document upload</option>
              <option value="dropdown">Dropdown</option>
              <option value="date">Date</option>
              <option value="link">Link</option>
              <option value="social">Social</option>
            </FormControl>
            {deleteButton}
          </Row>
          {dropdownAccordion}
        </Col>
      </Row>
    );
  };

  renderObjectOption = (
    option,
    optionIndex,
    fieldIndex,
    sectionIndex,
    disabled
  ) => {
    let deleteButton;
    if (disabled) {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete
          onClick={() =>
            this.handleDeleteObjectSubfield(
              optionIndex,
              fieldIndex,
              sectionIndex
            )
          }
          className="individual-form-icon"
        />
      );
    }

    return (
      <Row
        noGutters
        className="admin-edit-content-subsection"
        key={`${optionIndex} + ${fieldIndex} + ${sectionIndex}`}
      >
        <Col>
          <Row noGutters>
            <Col>
              <FormControl
                size="sm"
                value={option.fieldName}
                disabled={disabled}
                onChange={e =>
                  this.handleEditObjectSubfieldFieldName(
                    e,
                    optionIndex,
                    fieldIndex,
                    sectionIndex
                  )
                }
                placeholder="Subfield Name..."
                className="submission-form-control"
              />
            </Col>
            <Col>
              <FormControl
                size="sm"
                as="select"
                value={option.fieldType}
                disabled={disabled}
                onChange={e =>
                  this.handleEditObjectSubfieldFieldType(
                    e,
                    optionIndex,
                    fieldIndex,
                    sectionIndex
                  )
                }
                placeholder="Subfield Type..."
                className="submission-form-control"
              >
                <option value="">Subfield Type...</option>
                <option value="string">String</option>
                <option value="paragraph">Paragraph</option>
                <option value="location">Location</option>
                <option value="number">Number</option>
                <option value="image">Image upload</option>
                <option value="video">Video embed</option>
                <option value="document">Document upload</option>
                <option value="date">Date</option>
              </FormControl>
            </Col>
          </Row>
        </Col>
        <Col sm={1}>
          <Row noGutters className="admin-edit-configure-modal-header">
            {deleteButton}
          </Row>
        </Col>
      </Row>
    );
  };

  renderMultiOption = (
    option,
    optionIndex,
    fieldIndex,
    sectionIndex,
    disabled
  ) => {
    let deleteButton;
    if (disabled) {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete
          onClick={() =>
            this.handleDeleteSubfield(optionIndex, fieldIndex, sectionIndex)
          }
          className="individual-form-icon"
        />
      );
    }

    return (
      <Row
        noGutters
        className="admin-edit-content-subsection"
        key={`${optionIndex} + ${fieldIndex} + ${sectionIndex}`}
      >
        <Col>
          <FormControl
            size="sm"
            value={option.name}
            disabled={disabled}
            onChange={e =>
              this.handleEditSubfield(e, optionIndex, fieldIndex, sectionIndex)
            }
            placeholder="Subfield Name..."
            className="submission-form-control"
          />
        </Col>
        <Col sm={1}>
          <Row noGutters className="admin-edit-configure-modal-header">
            {deleteButton}
          </Row>
        </Col>
      </Row>
    );
  };

  renderDropdownOption = (
    option,
    optionIndex,
    fieldIndex,
    sectionIndex,
    disabled
  ) => {
    let deleteButton;
    if (disabled) {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete
          onClick={() =>
            this.handleDeleteDropdownOption(
              optionIndex,
              fieldIndex,
              sectionIndex
            )
          }
          className="individual-form-icon"
        />
      );
    }

    return (
      <Row
        noGutters
        className="admin-edit-content-subsection"
        key={`${optionIndex} + ${fieldIndex} + ${sectionIndex}`}
      >
        <Col>
          <FormControl
            size="sm"
            value={option}
            disabled={disabled}
            onChange={e =>
              this.handleEditDropdownOption(
                e,
                optionIndex,
                fieldIndex,
                sectionIndex
              )
            }
            placeholder="Dropdown Option..."
            className="submission-form-control"
          />
        </Col>
        <Col sm={1}>
          <Row noGutters className="admin-edit-configure-modal-header">
            {deleteButton}
          </Row>
        </Col>
      </Row>
    );
  };

  renderSubfield = (subfield, sectionIndex, subfieldIndex) => {
    return <Row className="template-dropdown-option" key={1}></Row>;
  };

  renderContent = () => {
    return (
      <React.Fragment>
        <Row noGutters className="forms-header">
          <h4>Asset Templates</h4>
        </Row>
        <div className="forms-table-box">
          {this.renderSearchbar()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </React.Fragment>
    );
  };

  renderConfirmDeleteModal = () => {
    const { show, id } = this.state.confirmDeleteModal;

    return (
      <Modal
        centered
        className="admin-modal"
        show={show}
        onHide={this.handleHideConfirmDeleteModal}
      >
        <Modal.Header closeButton>User Data</Modal.Header>

        <Modal.Body>
          <Row noGutters className="confirm-delete-modal-row">
            Are you sure you want to delete this template?
          </Row>

          <Row noGutters className="confirm-delete-modal-row email-row">
            <b>{id}</b>
          </Row>

          <Row noGutters className="confirm-delete-modal-row">
            <Button
              onClick={() => this.handleDeleteTemplate(id)}
              className="delete-button"
              size="sm"
            >
              Delete
            </Button>

            <Button
              onClick={this.handleHideConfirmDeleteModal}
              className="teal-button"
              size="sm"
            >
              Cancel
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  renderPagination = () => {
    const { docs, limit, total } = this.state.tokenTemplates.templates;
    const { currentPage } = this.state;
    const numberOfPages = Math.ceil(total / limit);
    let pages = [];

    for (let number = 1; number <= numberOfPages; number++) {
      pages.push(
        <Pagination.Item
          onClick={() => this.handleClickPage(number)}
          key={number}
          active={number === currentPage}
        >
          {number}
        </Pagination.Item>
      );
    }

    const first =
      Math.abs(currentPage - 1) > 3 ? (
        <Pagination.First onClick={() => this.handleClickPage(1)} />
      ) : (
        ""
      );
    const last =
      Math.abs(currentPage - numberOfPages) > 3 ? (
        <Pagination.Last onClick={() => this.handleClickPage(numberOfPages)} />
      ) : (
        ""
      );

    const next =
      currentPage !== numberOfPages ? (
        <Pagination.Next
          onClick={() => this.handleClickPage(currentPage + 1)}
        />
      ) : (
        ""
      );
    const prev =
      currentPage !== 1 ? (
        <Pagination.Prev
          onClick={() => this.handleClickPage(currentPage - 1)}
        />
      ) : (
        ""
      );

    return (
      <Row noGutters className="pagination-row">
        <Pagination size="sm" className="common-pagination">
          {first}
          {prev}
          {pages}
          {next}
          {last}
        </Pagination>
      </Row>
    );
  };

  handleHideConfigurePreviewModal = () => {
    this.setState({
      configurePreviewModal: {
        show: false
      }
    });
  };

  handleShowConfigurePreviewModal = () => {
    this.setState({
      configurePreviewModal: {
        show: true
      }
    });
  };

  renderConfigurePreviewModal = () => {
    const { show } = this.state.configurePreviewModal;

    if (show) {
      const {
        filters,
        preview,
        isPublished
      } = this.state.tokenTemplateToEdit.template;
      const disabled = isPublished ? true : false;

      const closeButtonText = isPublished ? "Close" : "Save";

      return (
        <Modal
          centered
          className="admin-modal"
          show={show}
          onHide={this.handleHideConfigurePreviewModal}
        >
          <Modal.Header closeButton>Configure Preview</Modal.Header>

          <Modal.Body>
            <Row noGutters>
              Please select the fields that show appear as part of the preview:
            </Row>

            <Row noGutters className="configure-preview-row">
              <div>
                <FormCheck
                  label="Image"
                  disabled={disabled}
                  checked={preview.includes("image")}
                  onClick={() => this.handleChangeConfigurePreview("image")}
                />
                <FormCheck
                  label="Name"
                  disabled={disabled}
                  checked={preview.includes("name")}
                  onClick={() => this.handleChangeConfigurePreview("name")}
                />
                <FormCheck
                  label="Description"
                  disabled={disabled}
                  checked={preview.includes("description")}
                  onClick={() =>
                    this.handleChangeConfigurePreview("description")
                  }
                />
                <FormCheck
                  label="Asset Type"
                  disabled={disabled}
                  checked={preview.includes("assetType")}
                  onClick={() => this.handleChangeConfigurePreview("assetType")}
                />
                <FormCheck
                  label="Location"
                  disabled={disabled}
                  checked={preview.includes("location")}
                  onClick={() => this.handleChangeConfigurePreview("location")}
                />
                <FormCheck
                  label="Industry"
                  disabled={disabled}
                  checked={preview.includes("industry")}
                  onClick={() => this.handleChangeConfigurePreview("industry")}
                />
                <FormCheck
                  label="Market Data"
                  disabled={disabled}
                  checked={preview.includes("marketData")}
                  onClick={() =>
                    this.handleChangeConfigurePreview("marketData")
                  }
                />
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={this.handleHideConfigurePreviewModal}
              className="teal-button"
              size="sm"
            >
              {closeButtonText}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  render() {
    const { loaded, loading } = this.state.tokenTemplateToEdit;

    let content;
    if (loaded) {
      content = this.renderSelectedTemplate();
    } else {
      content = this.renderContent();
    }

    return (
      <Container fluid className="admin-content-container">
        {this.renderConfigurePreviewModal()}
        {this.renderConfirmDeleteModal()}
        {this.renderConfigureModal()}
        {content}
      </Container>
    );
  }
}

export default TokenTemplates;
