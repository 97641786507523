import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Row,
  Col,
  Image,
  FormControl,
  Spinner,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { history } from "../../../configureStore";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { ImageDrop } from "quill-image-drop-module";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";
import { IoIosAdd } from "react-icons/io";
import {
  createAnnouncementRequest,
  clearCurrentAnnouncement,
  searchAnnouncementsRequest,
  getAnnouncementRequest,
  editAnnouncementRequest,
  publishAnnouncementRequest,
  attachFileToAnnouncementRequest,
  removeFileFromAnnouncementRequest
} from "../../../redux/actions/announcements";
import { getTradedSymbolsRequest } from "../../../redux/actions/admin";
import RenderAnnouncement from "../../Announcements/RenderAnnouncement.js";

let timeout;

const mapStateToProps = state => ({
  currentDraft: state.announcements.currentAnnouncement,
  allDrafts: state.announcements.announcements,
  account: state.login.accountData,
  tradedSymbols: state.admin.tradedSymbols,
  attachmentsLoading: state.announcements.attachmentsLoading,
  globalAnnouncement: state.announcements
});

const mapDispatchToProps = {
  createAnnouncementRequest,
  clearCurrentAnnouncement,
  searchAnnouncementsRequest,
  getAnnouncementRequest,
  editAnnouncementRequest,
  publishAnnouncementRequest,
  getTradedSymbolsRequest,
  attachFileToAnnouncementRequest,
  removeFileFromAnnouncementRequest
};

class CreateAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: "false"
    };

    Quill.register("modules/imageDrop", ImageDrop);
    var bold = Quill.import("formats/bold");
    bold.tagName = "b";
    Quill.register(bold, true);

    this.modules = {
      toolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"]
      ],
      imageDrop: {},
      clipboard: {
        matchVisual: true
      }
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "blockquote",
      "code-block",
      "indent",
      "link",
      "image",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];

    this.myImageInput = React.createRef();
    this.myDocumentInput = React.createRef();
  }

  componentDidMount() {
    // this.props.getTopicsListRequest();
    this.props.clearCurrentAnnouncement();
    this.getSymbols();
    this.handleParseUrl();
  }

  getSymbols = () => {
    if (!this.props.tradedSymbols.loaded) {
      this.props.getTradedSymbolsRequest();
    }
  };

  handleParseUrl = () => {
    if (this.props.history.location.search) {
      const search = this.props.history.location.search;
      let newSearch = search.split("?id=");
      this.props.getAnnouncementRequest(newSearch[1]);
    }
  };

  handleEditDraft = e => {
    const draft = this.props.currentDraft.data.docs[0];
    draft[e.target.name] = e.target.value;

    this.handleSetStateAndSave();
  };

  handleSetStateAndSave = () => {
    this.setState(
      {
        currentDraft: {
          ...this.state.currentDraft
        }
      },
      () => this.props.editAnnouncementRequest(this.props.currentDraft.data)
    );
  };

  handleRteChange = (content, delta, source, editor) => {
    const draft = this.props.currentDraft.data.docs[0];
    draft.body = editor.getHTML();

    this.handleSetStateAndSave();
  };

  handleChangeCategories = categories => {
    const draft = this.props.currentDraft.data.docs[0];
    draft.categories = categories;

    this.handleSetStateAndSave();
  };

  handleAddCategoryFromDropdown = e => {
    const draft = this.props.currentDraft.data.docs[0];
    draft.categories.push(e.target.value);
    this.handleSetStateAndSave();
  };

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    // const year = date.getFullYear()
    return month + " " + day;
  };

  handlePublish = () => {
    this.props.publishAnnouncementRequest(
      this.props.currentDraft.data.docs[0]._id
    );
  };

  handleImageSelect = e => {
    this.props.articleImageUploadRequest(
      e.target.files[0],
      this.props.currentDraft.data
    );
  };

  handleDocumentSelect = e => {
    this.props.attachFileToAnnouncementRequest(
      e.target.files[0],
      this.props.currentDraft.data
    );
  };

  handleHidePreview = () => {
    this.setState({
      currentModal: ""
    });
  };

  handleShowPreview = () => {
    this.setState({
      currentModal: "preview"
    });
  };

  /****************************************************************************/

  renderForm = () => {
    const { data, loading, loaded } = this.props.currentDraft;

    let content;
    if (loaded) {
      const { title, summary, body, image } = data.docs[0];

      content = (
        <Col className="create-report-form-col">
          {this.renderTopbar()}
          <Row className="rte-title-row">
            <h1>
              <FormControl
                className="rte-content-title"
                name="title"
                value={title}
                onChange={this.handleEditDraft}
                placeholder="Title..."
                autoComplete="off"
              />
            </h1>
          </Row>
          <Row className="rte-summary-row">
            <h2>
              <FormControl
                className="rte-content-summary"
                name="summary"
                value={summary}
                onChange={this.handleEditDraft}
                placeholder="Summary..."
                autoComplete="off"
              />
            </h2>
          </Row>
          <Row className="rte-quill-row">
            <ReactQuill
              className="rte-quill"
              value={body || ""}
              onChange={this.handleRteChange}
              modules={this.modules}
              formats={this.formats}
              placeholder="Start writing your report..."
            />
          </Row>
        </Col>
      );
    } else if (loading) {
    }

    return content;
  };

  renderDocumentUploader = () => {
    let documentUploader;

    if (this.props.currentDraft.loaded) {
      documentUploader = (
        <Row noGutters className="create-report-sidebar-row">
          <Col>
            <label className="create-report-sidebar-label">
              Import text from .doc or .docx file
            </label>
            <div
              onClick={e => this.myDocumentInput.click()}
              className="no-article-image"
            >
              Browse files
            </div>
            <input
              ref={ref => (this.myDocumentInput = ref)}
              style={{ display: "none" }}
              type="file"
              onChange={this.handleDocumentSelect}
            />
          </Col>
        </Row>
      );
    }

    return documentUploader;
  };

  renderSidebar = () => {
    return (
      <Col sm={2} className="create-report-sidebar-col">
        {this.renderAddCategories()}
        {this.renderAttachments()}
      </Col>
    );
  };

  renderAddCategories = () => {
    let content;
    const categories = this.props.currentDraft.data.docs[0].categories || [];
    let tradedSymbols = this.props.tradedSymbols.loaded
      ? this.props.tradedSymbols.data
      : [];
    if (this.props.currentDraft.loaded) {
      function autocompleteRenderInput({ addTag, ...props }) {
        const handleOnChange = (e, { newValue, method }) => {
          if (method === "enter") {
            e.preventDefault();
          } else {
            props.onChange(e);
          }
        };

        const inputValue =
          (props.value && props.value.trim().toLowerCase()) || "";
        const inputLength = inputValue.length;

        let suggestions = tradedSymbols.filter(symbol => {
          return (
            symbol.security.toLowerCase().slice(0, inputLength) === inputValue
          );
        });

        return (
          <Autosuggest
            ref={props.ref}
            suggestions={suggestions}
            shouldRenderSuggestions={value => value && value.trim().length > 0}
            getSuggestionValue={suggestion => suggestion.security}
            renderSuggestion={suggestion => <span>{suggestion.security}</span>}
            inputProps={{ ...props, onChange: handleOnChange }}
            onSuggestionSelected={(e, { suggestion }) => {
              addTag(suggestion.security);
            }}
            onSuggestionsClearRequested={() => {}}
            onSuggestionsFetchRequested={() => {}}
          />
        );
      }

      content = (
        <Row noGutters className="create-report-sidebar-row">
          <Col>
            <label className="create-report-sidebar-label">
              Add/change Categories
            </label>
            <FormControl
              as="select"
              style={{
                fontSize: 14,
                padding: 4,
                marginBottom: 8,
                borderWidth: 0
              }}
              onChange={this.handleAddCategoryFromDropdown}
            >
              <option value="">Add a symbol...</option>
              {this.props.tradedSymbols.loaded
                ? this.props.tradedSymbols.data.map((symbol, i) => {
                    return (
                      <option key={`symbol for dropdown - ${i}`}>
                        {symbol.security}
                      </option>
                    );
                  })
                : ""}
            </FormControl>
            <TagsInput
              renderInput={autocompleteRenderInput}
              value={categories}
              onChange={this.handleChangeCategories}
            />
          </Col>
        </Row>
      );
    }

    return content;
  };

  renderAttachments = () => {
    let input, attachments;
    const attachmentsArray = this.props.currentDraft.data.docs[0].attachments;

    if (this.props.attachmentsLoading) {
      input = (
        <div className="no-article-image" style={{ marginBottom: 8 }}>
          <Spinner
            animation="border"
            style={{ width: 30, height: 30, color: "#858796" }}
          />
        </div>
      );
    } else {
      input = (
        <>
          <div
            onClick={e => this.myDocumentInput.click()}
            className="no-article-image"
            style={{ marginBottom: 8 }}
          >
            Browse files
          </div>
          <input
            ref={ref => (this.myDocumentInput = ref)}
            style={{ display: "none" }}
            type="file"
            onChange={this.handleDocumentSelect}
          />
        </>
      );
    }

    if (attachmentsArray.length > 0) {
      attachments = attachmentsArray.map((attachment, i) => {
        if (attachment.url) {
          const { url, fileName } = attachment;
          const draft = this.props.currentDraft.data;
          const substrFileName = fileName.substr(0, 15) + "\u2026";
          return (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="tooltip-teams">{fileName}</Tooltip>}
            >
              <div
                className="rendered-attachment-box"
                key={`Individual attachment - ${i}`}
              >
                <a className="rendered-attachment-a" target="_blank" href={url}>
                  {substrFileName}
                </a>
                <Button
                  onClick={() =>
                    this.props.removeFileFromAnnouncementRequest(url, draft, i)
                  }
                  className="teal-button xs-del-btn"
                  size="sm"
                >
                  Delete
                </Button>
              </div>
            </OverlayTrigger>
          );
        }
      });
    }

    return (
      <div>
        <Col style={{ margin: 0, padding: 0 }}>
          <label className="create-report-sidebar-label">Add Attachments</label>
          {input}
          {attachments}
        </Col>
      </div>
    );
  };

  renderTopbar = () => {
    return (
      <div className="create-report-topbar-container">
        <Button
          onClick={this.handleSetStateAndSave}
          className="teal-button create-report-button"
          size="sm"
        >
          Save
        </Button>
        <Button
          onClick={this.handleShowPreview}
          className="teal-button create-report-button"
          size="sm"
        >
          Preview
        </Button>
      </div>
    );
  };

  renderCurrentDraft = () => {
    return (
      <div className="admin-create-report-container" style={{}}>
        {this.renderPreview()}
        <Row noGutters>
          {this.renderForm()}
          {this.renderSidebar()}
        </Row>
      </div>
    );
  };

  renderCreateDraft = () => {
    return (
      <Container className="admin-create-draft-container">
        <Button
          className="rte-no-drafts-button"
          onClick={this.props.createAnnouncementRequest}
        >
          <IoIosAdd className="rte-no-drafts-add-icon" />
          Create Announcement
        </Button>
      </Container>
    );
  };

  renderPreview = () => {
    if (this.props.currentDraft.loaded) {
      const show = this.state.currentModal == "preview" ? true : false;
      return (
        <Modal
          centered
          size="lg"
          onHide={this.handleHidePreview}
          show={show}
          className="admin-modal preview-article-modal"
        >
          <Modal.Header closeButton>
            <Row noGutters className="preview-article-modal-header">
              Preview
              <Button
                onClick={this.handlePublish}
                className="teal-button"
                size="sm"
              >
                Publish
              </Button>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <RenderAnnouncement data={this.props.currentDraft.data.docs[0]} />
          </Modal.Body>
        </Modal>
      );
    }
  };

  renderLoading = () => {
    return (
      <Row noGutters className="create-report-loading-row">
        <Spinner
          animation="border"
          variant="light"
          className="create-report-spinner"
        />
      </Row>
    );
  };

  render() {
    const { currentDraft, allDrafts } = this.props;
    let content;

    if (currentDraft.loading) {
      content = this.renderLoading();
    } else if (currentDraft.loaded) {
      content = this.renderCurrentDraft();
    } else {
      content = this.renderCreateDraft();
    }

    return content;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnnouncement);
