import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Row, Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { AiOutlineWifi, AiFillDelete } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { toggleSidebar } from "../../redux/actions/options";
import { logout } from "../../redux/actions/login";
import { updateWatchlistRequest } from "../../redux/actions/account";

const mapStateToProps = state => ({
  sidebarOpened: state.options.sidebarOpened,
  account: state.login.accountData
});

const mapDispatchToProps = {
  toggleSidebar,
  logout,
  updateWatchlistRequest
};

class Topbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  renderUserDropdown = () => {
    let content;

    if (this.props.account) {
      if (this.props.account.authenticated) {
        let userId =
          this.props.account.data.userid || this.props.account.data.userId;
        content = (
          <Dropdown className="user-dropdown">
            <Dropdown.Toggle>
              <AiOutlineWifi className="connection-icon" />
              <div className="username">{userId}</div>
              <BiUserCircle className="portrait" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <NavLink className="sidebar-navLink" to="/admin">
                  Admin
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item onClick={this.props.logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        //render login button
        content = (
          <NavLink to="/login" className="tb-navlink">
            <Button className="dark-button topbar-register-button">
              Login
            </Button>
          </NavLink>
        );
      }
    }

    return content;
  };

  renderTradingInterfaceBtn = () => (
    <a href={"https://ws.fi-edition.com/"} target="_blank">
      <Button className="dark-button">Trading Interface</Button>
    </a>
  );

  handleUpdateWatchlist = index => {
    let watchlist = this.props.account.data.watch_list || [];
    watchlist.splice(index, 1);

    this.props.updateWatchlistRequest(watchlist);
  };

  renderStatsContainer = () => {
    let content;

    if (this.props.account) {
      if (this.props.account.loaded) {
        if (this.props.account.data.watch_list) {
          content = (
            <div className="market-stats">
              {this.props.account.data.watch_list.map((item, index) => {
                return (
                  <div
                    className="market-statistics-container"
                    style={{ position: "relative" }}
                    key={`market-stats-item - ${item} - ${index}`}
                  >
                    <AiFillDelete
                      className="market-stats-delete-btn"
                      style={{
                        position: "absolute",
                        top: "4px",
                        right: "4px"
                      }}
                      onClick={() => this.handleUpdateWatchlist(index)}
                    />
                    <div className="title">{item}</div>
                    <div className="content">
                      <div className="first">3</div>
                      <div className="second">-98.75</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
      } else {
        content = (
          <div className="market-stats posRel">
            <NavLink to="/login" className="clean-nav">
              <div className="overlay centered">
                To view market statistics please log in
              </div>
            </NavLink>

            <div className="market-statistics-container blur1">
              <div className="tiztle">SEC</div>
              <div className="content">
                <div className="first">3</div>
                <div className="second">-98.75</div>
              </div>
            </div>
            <div className="market-statistics-container blur1">
              <div className="title">TESTGUILLE</div>
              <div className="content">
                <div className="first">15</div>
                <div className="second">-95.37</div>
              </div>
            </div>
            <div className="market-statistics-container blur1">
              <div className="title">TESTA</div>
              <div className="content">
                <div className="first">6.00</div>
                <div className="second">-99.40</div>
              </div>
            </div>
          </div>
        );
      }
    }

    return content;
  };

  render() {
    const className = this.props.sidebarOpened
      ? "new-topbar"
      : "new-topbar-closed";

    return (
      <div className={className} style={{ justifyContent: "flex-end" }}>
        {this.renderUserDropdown()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
