import * as CONSTANTS from "../constants/admin";

const initialState = {
  tradedSymbols: {
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  users: {
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  reports: {
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  offer: {
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  websiteConfig: {
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  weakWebsiteConfig: "slide"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_REQUEST:
      return {
        ...state,
        tradedSymbols: {
          ...state.tradedSymbols,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_SUCCESS:
      return {
        ...state,
        tradedSymbols: {
          ...state.tradedSymbols,
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_ERROR:
      return {
        ...state,
        tradedSymbols: {
          ...state.tradedSymbols,
          loading: false,
          loaded: false,
          error: ""
        }
      };

    /****************************************************/

    case CONSTANTS.ADMIN_SEARCH_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.ADMIN_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case CONSTANTS.ADMIN_SEARCH_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.ADMIN_SEARCH_USERS_CLEAR:
      return {
        ...state,
        users: {
          data: {},
          loaded: false,
          loading: false,
          error: ""
        }
      };

    /****************************************************/

    case CONSTANTS.ADMIN_SEARCH_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.ADMIN_SEARCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case CONSTANTS.ADMIN_SEARCH_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.ADMIN_SEARCH_REPORTS_CLEAR:
      return {
        ...state,
        reports: {
          data: {},
          loaded: false,
          loading: false,
          error: ""
        }
      };

    /****************************************************/

    case CONSTANTS.ADMIN_GET_OFFER_REQUEST:
      return {
        ...state,
        offer: {
          ...state.offer,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.ADMIN_GET_OFFER_SUCCESS:
      return {
        ...state,
        offer: {
          ...state.offer,
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case CONSTANTS.ADMIN_GET_OFFER_ERROR:
      return {
        ...state,
        offer: {
          ...state.offer,
          loading: false,
          loaded: false,
          error: ""
        }
      };

    /****************************************************/

    case CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_REQUEST:
      return {
        ...state,
        websiteConfig: {
          ...state.websiteConfig,
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_SUCCESS:
      return {
        ...state,
        websiteConfig: {
          ...state.websiteConfig,
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_ERROR:
      return {
        ...state,
        websiteConfig: {
          ...state.websiteConfig,
          loading: false,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.SOFT_UPDATE_WEBSITE_CONFIG:
      return {
        ...state,
        weakWebsiteConfig: action.data
      };
    default:
      return state;
  }
};
