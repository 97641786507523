import { connect } from "react-redux";
import UserTokenDetailPage from "./UserTokenDetailPage";
import {
  getTokenDetailRequest,
  getTokenSpecificNewsRequest
} from "../../../redux/actions/tokenlist";
import { userViewAssetRequest } from "../../../redux/actions/tokenform";
import {
  getNewsRequest,
  searchNewsRequest,
  saveNewsRequest,
  unSavedNewIdRequest
} from "../../../redux/actions/news";
import {
  getAssetResearchRequest,
  getAssetPricingDataRequest
} from "../../../redux/actions/researches";
import "./UserTokenDetailPage.css";

const mapStateToProps = state => ({
  asset: state.token.userAssetToView,
  news: state.tokens.searchItemList,
  research: state.research.assetResearch,
  pricing: state.research.assetPricingData,
  account: state.login.accountData
});

const mapDispatchToProps = {
  getTokenDetailRequest,
  getTokenSpecificNewsRequest,
  userViewAssetRequest,
  getAssetResearchRequest,
  getAssetPricingDataRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTokenDetailPage);
