import * as CONSTANTS from '../constants/tokenlist';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  tokens: {
    tokenList: {
      Items: [],
    },
    error: '',
  },
  token: {
    detail: {},
    loading: false,
    loaded: false,
    error: '',
  },
  reportComment: {
    comments: [],
    error: '',
  },
  replyReportComments: {
    replyComments: [],
    error: '',
  },
  comments: {
    comments: [],
    error: '',
  },
  replyComments: {
    replyComments: [],
  },
  newsComments: {
    newsComments: [],
  },
  news: {
    comments: [],
  },
  searchTokens: {
    tokens: {
        hits: [],
    },
    error: '',
    loading: false,
  },
  commentType: '',
  tokenProfile: {
    loading: false,
    tokenList: [],
    tokenType: '',
    error: '',
  },
  stats: {
    stats: [],
    loading: false,
  },
  tokensByDate: {
    tokens: [],
    loading: false,
    error: '',
  },
  pressReleases: {
    pressReleasesList: [],
    loading: false,
    loaded: false,
    error: '',
  },
  chartData: {
    data: [],
    error: '',
    loading: false,
    loaded: false,
  },
  pieChartData: {
    data: [],
    error: '',
    loading: false,
    loaded: false,
  },
  searchItemList: {
    searchList: {},
    error: "",
    loading: false,
    loaded: false,
  },
  assetTypes: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  profileFilters: {
    filters: [],
    loading: false,
    loaded: false,
    error: ''
  },
  profileFilter: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  listOfAssetTypes: {
    assetTypes: [],
    loading: false,
    loaded: false,
    error: ''
  },
  listOfAssets: {
    assets: [],
    loading: false,
    loaded: false,
    error: ''
  },
  listOfAssetsForSearch: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  listOfFiltersForAssetType: {
    filters: [],
    preview: [],
    loading: false,
    loaded: false,
    error: ''
  },
  allItems: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.GET_TOKEN_LIST_SUCCESS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          tokenList: action.tokenList,
        },
      };
    case CONSTANTS.GET_TOKEN_LIST_ERROR:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          error: action.error,
        },
      };
    case CONSTANTS.GET_TOKEN_DETAIL_REQUEST:
      return {
        ...state,
        token: {
          ...state.token,
          detail: {},
          loading: true,
          loaded: false,
        },
      };
    case CONSTANTS.GET_TOKEN_DETAIL_SUCCESS:
      return {
        ...state,
        token: {
          ...state.token,
          detail: action.detail,
          loading: false,
          loaded: true,
        },
      };
    case CONSTANTS.GET_TOKEN_DETAIL_ERROR:
      return {
        ...state,
        token: {
          ...state.token,
          error: action.error,
          loading: false,
        },
      };
    case CONSTANTS.GET_COMMENTS_REQUEST:
      switch (action.commentType) {
        case 'token_comment':
          return {
            ...state,
            comments: [],
            commentType: '',
          };
        case 'reply_comment':
          return {
            ...state,
            replyComments: [],
            commentType: '',
          };
        case 'report_comment':
          return {
            ...state,
            reportComment: [],
            commentType: '',
          };
        case 'report_reply_comment':
          return {
            ...state,
            replyReportComments: [],
            commentType: '',
          };
        default:
          break;
      }
      break;
    case CONSTANTS.GET_REPLY_COMMENTS_SUCCESS:
      return {
        ...state,
        replyComments: {
          ...state.replyComments,
          replyComments: action.comments,
        },
        commentType: action.commentType,
      };
    case CONSTANTS.GET_TOKEN_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          comments: action.comments,
        },
        commentType: action.commentType,
      };
    case CONSTANTS.GET_REPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        reportComment: {
          ...state.reportComment,
          comments: action.comments,
        },
        commentType: action.commentType,
      };
    case CONSTANTS.GET_REPORT_REPLY_COMMENT_SUCCESS:
      return {
        ...state,
        replyReportComments: {
          replyComments: {
            ...state.replyComments,
            replyComments: action.comments,
          },
        },
      };
    case CONSTANTS.GET_COMMENTS_ERROR:
      return {
        ...state,
        comments: {
          ...state.comments,
          error: action.error,
        },
      };
    case CONSTANTS.GET_TOKEN_NEWS_SUCCESS:
      return {
        ...state,
        newsComments: {
          ...state.newsComments,
          newsComments: action.comments,
        },
      };
    case CONSTANTS.GET_TOKEN_NEWS_ERROR:
      return {
        ...state,
        newsComments: {
          ...state.newsComments,
          error: action.error,
        },
      };
    case CONSTANTS.GET_NEWS_COMMENTS_SUCCESS:
      return {
        ...state,
        news: {
          ...state.news,
          comments: action.comments,
        },
        commentType: action.commentType,
      };
    case CONSTANTS.SEARCH_TOKEN_REQUEST:
        return {
          ...state,
          searchTokens: {
              ...state.searchTokens
          },
          loading: true,
        }
    case CONSTANTS.SEARCH_TOKEN_SUCCESS:
      return {
        ...state,
        searchTokens: {
          ...state.searchTokens,
          tokens: action.tokenList,
        },
        loading: false,
      };
    case CONSTANTS.SEARCH_TOKEN_ERROR:
      return {
        ...state,
        searchTokens: {
          ...state.searchTokens,
          error: action.error,
          loading: false,
        },
      };
    case CONSTANTS.GET_SEE_MORE_COMMENTS_SUCCESS:
      if (action.commentType === 'token_comment') {
        return {
          ...state,
          comments: {
            ...state.comments,
            comments: {
              ...action.comments,
              LastEvaluatedKey: action.comments.LastEvaluatedKey,
              Items: state.comments.comments.Items.concat(
                action.comments.Items,
              ),
            },
          },
        };
      } else if (action.commentType === 'report_comment') {
        return {
          ...state,
          reportComment: {
            ...state.reportComment,
            comments: {
              ...action.comments,
              LastEvaluatedKey: action.comments.LastEvaluatedKey,
              Items: state.reportComment.comments.Items.concat(
                action.comments.Items,
              ),
            },
          },
        };
      } else {
        return {
          ...state,
          replyComments: {
            ...state.replyComments,
            replyComments: {
              ...action.comments,
              LastEvaluatedKey: action.comments.LastEvaluatedKey,
              Items: state.replyComments.replyComments.Items.concat(
                action.comments.Items,
              ),
            },
          },
        };
      }
    case CONSTANTS.GET_TOKEN_DETAILS_REQUEST:
      return {
        ...state,
        tokenProfile: {
          ...state.tokenProfile,
          tokenList: [],
          error: '',
          loading: true,
        },
      };
    case CONSTANTS.GET_TOKEN_DETAILS_SUCCESS:
      return {
        ...state,
        tokenProfile: {
          ...state.tokenProfile,
          loading: false,
          tokenList: action.tokenList,
          tokenType: action.tokenType,
        },
      };
    case CONSTANTS.GET_MARKET_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          stats: [],
          loading: true,
        },
      };
    case CONSTANTS.GET_MARKET_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          stats: action.stats,
          loading: false,
        },
      };
    case CONSTANTS.GET_TOKENS_BY_DATE_SUCCESS:
      return {
        ...state,
        tokensByDate: {
          ...state.tokensByDate,
          tokens: action.tokens,
        },
      }
    case CONSTANTS.GET_PRESS_RELEASES_REQUEST:
    return {
      ...state,
      pressReleases: {
        ...state.pressReleases,
        loading: true,
        loaded: false
      },
    };
    case CONSTANTS.GET_PRESS_RELEASES_SUCCESS:
    return {
      ...state,
      pressReleases: {
        ...state.pressReleases,
        pressReleasesList: action.pressReleases,
        loading: false,
        loaded: true,
      },
    }
    /************************** CHOROPLETH REDUCERS ******************************/
        case CONSTANTS.GET_CHOROPLETH_DATA_REQUEST:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    loading: true,
                    loaded: false,
                },
            }
        case CONSTANTS.GET_CHOROPLETH_DATA_SUCCESS:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    data: action.data,
                    loading: false,
                    loaded: true,

                },
            }
        case CONSTANTS.GET_CHOROPLETH_DATA_ERROR:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    error: action.error,
                },
            }
/************************** END OF CHOROPLETH REDUCERS ***********************/


/************************** PIE_CHART REDUCERS ******************************/
        case CONSTANTS.GET_PIE_CHART_DATA_REQUEST:
            return {
                ...state,
                pieChartData: {
                    ...state.pieChartData,
                    loading: true,
                    loaded: false,
                },
            }
        case CONSTANTS.GET_PIE_CHART_DATA_SUCCESS:

            return {
                ...state,
                pieChartData: {
                    ...state.pieChartData,
                    data: action.data,
                    loading: false,
                    loaded: true,
                },
            }
        case CONSTANTS.GET_PIE_CHART_DATA_ERROR:
            return {
                ...state,
                pieChartData: {
                    ...state.pieChartData,
                    error: action.error,
                    loading: false,
                },
            }
        case CONSTANTS.GET_TOKEN_SPECIFIC_NEWS_REQUEST:
          return {
            ...state,
            searchItemList: {
              ...state.searchItemList,
              loaded: false,
              loading: true,
            }
          }
        case CONSTANTS.GET_TOKEN_SPECIFIC_NEWS_SUCCESS:
          return {
            ...state,
            searchItemList: {
              ...state.searchItemList,
              searchList: action.data,
              loading: false,
              loaded: true,
            }
          };
        case CONSTANTS.GET_TOKEN_SPECIFIC_NEWS_ERROR:
          return {
            ...state,
            searchItemList: {
              ...state.searchItemList,
              error: action.error,
              loading: false,

            }
          };
/************************** END OF PIE_CHART REDUCERS ***********************/

    case CONSTANTS.GET_PROFILE_FILTERS_REQUEST:
      return {
        ...state,
        profileFilters: {
          ...state.profileFilters,
          loading: true,
          loaded: false,
        }
      }
    case CONSTANTS.GET_PROFILE_FILTERS_SUCCESS:
      return {
        ...state,
        profileFilters: {
          ...state.profileFilters,
          loading: false,
          loaded: true,
          filters: action.data,
        }
      }
    case CONSTANTS.GET_PROFILE_FILTERS_ERROR:
      return {
        ...state,
        profileFilters: {
          ...state.profileFilters,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.GET_LIST_OF_ASSET_TYPES_REQUEST:
      return {
        ...state,
        listOfAssetTypes: {
          ...state.listOfAssetTypes,
          loading: true,
          loaded: false,
        }
      }
    case CONSTANTS.GET_LIST_OF_ASSET_TYPES_SUCCESS:
      return {
        ...state,
        listOfAssetTypes: {
          ...state.listOfAssetTypes,
          loading: false,
          loaded: true,
          assetTypes: action.data
        }
      }
    case CONSTANTS.GET_LIST_OF_ASSET_TYPES_ERROR:
      return {
        ...state,
        listOfAssetTypes: {
          ...state.listOfAssetTypes,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.GET_LIST_OF_ASSETS_REQUEST:
      return {
        ...state,
        listOfAssets: {
          ...state.listOfAssets,
          loading: true,
          loaded: false,
        }
      }
    case CONSTANTS.GET_LIST_OF_ASSETS_SUCCESS:
      return {
        ...state,
        listOfAssets: {
          ...state.listOfAssets,
          loading: false,
          loaded: true,
          assets: action.data
        }
      }
    case CONSTANTS.GET_LIST_OF_ASSETS_ERROR:
      return {
        ...state,
        listOfAssets: {
          ...state.listOfAssets,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_REQUEST:
      return {
        ...state,
        listOfFiltersForAssetType: {
          ...state.listOfFiltersForAssettype,
          loading: true,
          loaded: false,
        }
      }
    case CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        listOfFiltersForAssetType: {
          ...state.listOfFiltersForAssetType,
          loading: false,
          loaded: true,
          filters: action.data.filters,
          preview: action.data.preview
        }
      }
    case CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_ERROR:
      return {
        ...state,
        listOfFiltersForAssetType: {
          ...state.listOfFiltersForAssetType,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.RESET_LIST_OF_FILTERS_FOR_ASSET_TYPE:
      return {
        ...state,
        listOfFiltersForAssetType: {
          filters: [],
          loading: false,
          loaded: false,
          error: ''
        },
      }
      case CONSTANTS.GET_ASSETS_WITH_PARAMS_REQUEST:
        return {
          ...state,
          listOfAssetsForSearch: {
            ...state.listOfAssetsForSearch,
            loading: true,
            loaded: false,
          }
        }
      case CONSTANTS.GET_ASSETS_WITH_PARAMS_SUCCESS:
        return {
          ...state,
          listOfAssetsForSearch: {
            ...state.listOfAssetsForSearch,
            loading: false,
            loaded: true,
            assets: action.data
          }
        }
      case CONSTANTS.GET_ASSETS_WITH_PARAMS_ERROR:
        return {
          ...state,
          listOfAssetsForSearch: {
            ...state.listOfAssetsForSearch,
            loading: false,
            loaded: false,
            error: action.error,
          }
        }

        case CONSTANTS.GET_ALL_ITEMS_SEARCH_REQUEST:
          return {
            ...state,
            allItems: {
              ...state.allItems,
              loading: true,
              loaded: false,
            }
          }
        case CONSTANTS.GET_ALL_ITEMS_SEARCH_SUCCESS:
          return {
            ...state,
            allItems: {
              ...state.allItems,
              loading: false,
              loaded: true,
              data: action.data,
            }
          }
        case CONSTANTS.GET_ALL_ITEMS_SEARCH_ERROR:
          return {
            ...state,
            allItems: {
              ...state.allItems,
              loading: false,
              loaded: false,
              error: action.error,
            }
          }
    default:
    return state;
  }
};
