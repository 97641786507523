import * as CONSTANTS from "../constants/login";
import * as ACCOUNT_CONSTANTS from "../constants/account";
import jwt from "jsonwebtoken";
import WebWorker from "../../WebWorker";
import WebWorkerEnabler from "../../WebWorkerEnabler";
import { CLEAR_USER_CACHE } from "../constants/account";
import { history } from "../../configureStore";

let workerInstance = {
  currentlyOperating: false,
  webWorker: new WebWorkerEnabler(WebWorker)
};
workerInstance.webWorker.addEventListener("message", e => {
  orderHandler(e);
});

const initialState = {
  authenticated: false,
  userData: {},
  loggedIn: false,
  showLoginModalStatus: false,
  pathname: "",
  accountData: {
    authenticated: false,
    data: {},
    loaded: false,
    loading: false,
    error: ""
  },
  loginData: {
    email: "",
    password: ""
  }
};

function operationsHandler() {
  /*
    Handles messages based on whether the workerInstance is currentlyOperating.
    If workerInstance is currentlyOperating,
      send message of 'refreshToken' w/ a refreshToken payload to WebWorker.js.
      (this triggers the webWorker to send an accessToken back to login.js)
    If workerInstance is not currentlyOperating,
      send message of 'stop' to WebWorker.js.
      (this triggers the webWorker to send an order of 'terminate' to login.js)
  */
  const refreshToken = { refreshToken: localStorage.refreshToken };
  let message = { order: "", payload: {} };

  if (workerInstance.currentlyOperating) {
    message = { order: "refreshToken", payload: refreshToken };
    workerInstance.webWorker.postMessage(message);
  } else {
    message = { order: "stop", payload: {} };
    workerInstance.webWorker.postMessage(message);
  }
} // end of operationsHandler(state)

function orderHandler(e) {
  /*
    Listens for specific 'orders' that are sent from WebWorker.js.
    This is called in the eventHandler for the webWorker.
    If an order of 'accessToken' is received,
      set the accessToken in localStorage,
      then send a message back to WebWorker with the refreshToken.
      (this starts the process over again)
    If an order of 'terminate' is received,
      terminate the webWorker.
  */
  let message = { order: "", payload: {} };
  let order = e.data.order;
  if (order === "accessToken") {
    localStorage.setItem("accessToken", e.data.payload.access_token);
    const refreshToken = { refreshToken: localStorage.refreshToken };
    message = { order: "refreshToken", payload: refreshToken };
    workerInstance.webWorker.postMessage(message);
  } else if (order === "terminate") {
    workerInstance.webWorker.terminate();
  }
} // end of orderHandler(e)

function webWorkerEnabler(loggedIn) {
  /*
    Enables the webWorker while the user is browsing & currently loggedIn.
    This is called on componentDidMount() & componentDidUpdate() of Routes.js.
    If a user is currently loggedIn & workerInstance is not currentlyOperating,
      change currentlyOperating to true & run operationsHandler().
    (this is a workaround to trigger the webWorker w/o a user hitting login)
  */
  if (loggedIn && !workerInstance.currentlyOperating) {
    workerInstance.currentlyOperating = true;
    operationsHandler();
  }
} // end of webWorkerEnabler(loggedIn)

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.AUTO_LOGIN_REQUEST:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          authenticated: false,
          loading: true,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.LOGIN_REQUEST:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          authenticated: false,
          loading: true,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.LOGIN_WITH_TOKEN:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          authenticated: false,
          loading: true,
          loaded: false,
          error: ""
        }
      };

    case CONSTANTS.LOGIN_SUCCESS:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          authenticated: true,
          data: action.data,
          loading: false,
          loaded: true
        }
      };

    case ACCOUNT_CONSTANTS.UPDATE_WATCHLIST_SUCCESS:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          data: {
            ...state.accountData.data,
            watch_list: action.watch_list
          }
        }
      };

    case CONSTANTS.LOGIN_NEED_TWO_FACTOR_AUTH:
      return {
        ...state,
        loginData: {
          ...state.loginData,
          email: action.data.email,
          password: action.data.password
        },
        accountData: {
          ...state.accountData,
          loading: false,
          loaded: false
        }
      };

    case CONSTANTS.CLEAR_LOGIN_PARAMS:
      return {
        ...state,
        loginData: {
          email: "",
          password: ""
        }
      };

    case CONSTANTS.LOGIN_ERROR:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          authenticated: false,
          loading: false,
          loaded: false,
          error: action.error
        }
      };

    case CONSTANTS.ENABLE_WEB_WORKER:
      webWorkerEnabler(action.data);
      return state;
    case CONSTANTS.DISABLE_WEB_WORKER:
      return state;
    case CONSTANTS.SHOW_LOGIN_MODAL:
      localStorage.setItem("redirectPathname", action.pathname);
      return {
        ...state,
        authenticated: false,
        showLoginModalStatus: true,
        pathname: action.pathname
      };
    case CONSTANTS.HIDE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModalStatus: false,
        pathname: ""
      };
    case CONSTANTS.SET_USER_AUTHENTICATED_STATUS:
      return {
        ...state,
        authenticated: action.status
      };
    case CONSTANTS.HIDE_LOGIN_MODAL_AND_WIPE_LOCAL_STORAGE:
      localStorage.removeItem("refreshToken");
      // localStorage.removeItem('accessToken')
      return {
        ...state,
        authenticated: false,
        showLoginModalStatus: false,
        pathname: ""
      };
    case CONSTANTS.LOGIN_FROM_MODAL_SUCCESS:
      // if (!workerInstance.currentlyOperating) {
      //   workerInstance = { currentlyOperating: true, webWorker: new WebWorkerEnabler(WebWorker) }
      //   workerInstance.webWorker.addEventListener('message', e => { orderHandler(e) })
      //   operationsHandler()
      // } else {
      //   operationsHandler()
      // }
      let decodedToken2 = jwt.decode(localStorage.accessToken);
      return {
        ...state,
        authenticated: true,
        userData: decodedToken2,
        loggedIn: true
      };
    case CONSTANTS.LOGIN_FROM_MODAL_ERROR:
      return {
        ...state,
        authenticated: false,
        loggedIn: false
      };
    default:
      return state;
  } // end of switch
}; // end of export
