import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import {
  Form,
  Row,
  Col,
  Container,
  FormControl,
  NavLink,
  Card,
  Image
} from "react-bootstrap";
import { history } from "../../configureStore";
import { Link } from "react-router-dom";
import "./Register.css";
import { toast } from "react-toastify";
import { initGA, logPageView, trackEvent } from "../../utils/analytics";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../images/HomePage/FI_LOGO.png";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      countryOfOrigin: "",
      notARobot: false,
      privacyStatement: false
    };
  }

  componentDidMount() {
    initGA();
    logPageView();
  }

  componentWillUnmount() {}

  handleAnswerPrivacyStatement = value => {
    this.setState({
      privacyStatement: value
    });
  };

  onChange = value => {
    if (value) {
      this.setState({ notARobot: true });
    } else {
      this.setState({ notARobot: false });
    }
  };

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value
    });
  };

  handleUsernameContainsDisallowedCharacters = username => {
    const disallowedCharacters = "!@#$%^&*(),.?:{}|<>";
    let validation = false;
    for (let i = 0; i < username.length; i++) {
      if (disallowedCharacters.indexOf(username.charAt(i)) != -1) {
        validation = true;
      }
    }
    return validation;
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, password, userName, firstName, lastName } = this.state;
    const { signupRequest } = this.props;

    const usernameContainsSpecialCharacters = this.handleUsernameContainsDisallowedCharacters(
      userName
    );
    if (usernameContainsSpecialCharacters) {
      toast.info(
        "Your username cannot contain special characters. Please remove them and try again."
      );
    } else {
      signupRequest(email, password, password, userName, firstName, lastName);
      trackEvent("User", "Registration", userName);
    }
  };

  handleCancel = e => {
    this.setState({
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      countryOfOrigin: ""
    });
  };

  handleOnChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  render() {
    const {
      userName,
      password,
      email,
      firstName,
      lastName,
      privacyStatement
    } = this.state;
    const registerButton = this.state.notARobot ? (
      <Button
        onClick={this.handleSubmit}
        className="login-button"
        variant="primary"
      >
        Register
      </Button>
    ) : (
      <Button disabled className="login-button">
        Register
      </Button>
    );

    const disabled = email && password && privacyStatement ? false : true;

    return (
      <Container fluid className="new-login-container">
        <Card className="login-box">
          <Row noGutters>
            <Col className="login-content-col">
              <Row noGutters className="login-header">
                <Link to={"/"}>
                  <Image fluid src={logo} className="login-logo" />
                </Link>
              </Row>

              <Row
                noGutters
                className="login-box-padding login-formcontrol-row"
              >
                <Col className="login-box-col">
                  <Row noGutters className="login-welcome">
                    <Col>
                      <h1>Welcome</h1>
                      <p>Register your account</p>
                    </Col>
                  </Row>

                  <Row noGutters className="login-formcontrol-container">
                    <div className="login-formcontrol-box">
                      <div className="login-formcontrol-text">Username</div>
                      <FormControl
                        size="sm"
                        name="userName"
                        value={userName}
                        onChange={this.handleOnChange}
                        className="login-formcontrol"
                      />
                    </div>
                  </Row>

                  <Row noGutters className="login-formcontrol-container">
                    <div className="login-formcontrol-box">
                      <div className="login-formcontrol-text">Password</div>
                      <FormControl
                        size="sm"
                        name="password"
                        type="password"
                        value={password}
                        onChange={this.handleOnChange}
                        className="login-formcontrol"
                      />
                    </div>
                  </Row>

                  <Row noGutters className="login-formcontrol-container">
                    <div className="login-formcontrol-box">
                      <div className="login-formcontrol-text">Email</div>
                      <FormControl
                        size="sm"
                        name="email"
                        value={email}
                        onChange={this.handleOnChange}
                        className="login-formcontrol"
                      />
                    </div>
                  </Row>

                  <Row noGutters className="login-formcontrol-container">
                    <Form.Group className="prereg-form-group">
                      <p className="privacy-statement-p">
                        I have read and agree to the
                        <Link
                          className="privacy-statement-text"
                          to="/privacy_policy"
                        >
                          Privacy Policy
                        </Link>
                      </p>

                      <Row noGutters className="prereg-row" id="checkbox-row">
                        <div id="shortbox">
                          <Form.Check
                            label="Yes"
                            className="prereg-radio"
                            onClick={() =>
                              this.handleAnswerPrivacyStatement(true)
                            }
                            name="privacyStatement"
                            checked={privacyStatement === true ? true : false}
                            type="radio"
                          />
                          <Form.Check
                            label="No"
                            className="prereg-radio"
                            onClick={() =>
                              this.handleAnswerPrivacyStatement(false)
                            }
                            name="privacyStatement"
                            checked={privacyStatement === false ? true : false}
                            type="radio"
                          />
                        </div>
                      </Row>
                    </Form.Group>
                  </Row>

                  <Row noGutters className="login-formcontrol-container">
                    <ReCAPTCHA
                      className="recaptcha"
                      sitekey={this.props.siteKey}
                      onChange={this.onChange}
                    />
                  </Row>

                  <Row noGutters className="login-button-row">
                    <Button
                      size="sm"
                      disabled={disabled}
                      onClick={this.handleSubmit}
                      className="teal-button"
                    >
                      REGISTER
                    </Button>
                  </Row>

                  <div className="login-navlinks">
                    <Link to="/forgot">Forgot Password</Link>
                    <Link className="textAlignRight" to="/login">
                      Already have an account? Log in
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}

export default Register;
