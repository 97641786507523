import React, { Component } from "react";
import Avatar from "react-avatar-edit";
import queryString from "query-string";
import Files from "react-butterfiles";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  FormControl
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import countryList from "react-select-country-list";
import ReactQuill from "react-quill";
import DatePicker from "react-date-picker";
import { IoIosAddCircle, IoMdRemoveCircle } from "react-icons/io";
import { v1, v4 } from "uuid";

class TokenForm extends Component {
  constructor(props) {
    super(props);

    this.issuanceOptions = [
      { value: "", name: "Token Type..." },
      { value: "Equity Token", name: "Equity Token" },
      { value: "Real Estate Token", name: "Real Estate Token" },
      { value: "Fund Security", name: "Fund Security" },
      { value: "Debt Security", name: "Debt Security" }
    ];
    this.statusOptions = [
      { value: "", name: "Current Status..." },
      { value: "Upcoming", name: "Upcoming" },
      { value: "Open", name: "Open" },
      { value: "Closed", name: "Closed" }
    ];
    this.legalFormOptions = [
      { value: "", name: "Legal Form..." },
      { value: "Holding Company", name: "Holding Company" },
      { value: "LLC", name: "LLC" },
      { value: "Corporation", name: "Corporation" }
    ];
    this.industryOptions = [
      { value: "", name: "Industry..." },
      { value: "Art", name: "Art" },
      { value: "Banking", name: "Banking" },
      { value: "Blockchain", name: "Blockchain" },
      { value: "Cryptocurrency", name: "Cryptocurrency" },
      { value: "Custody", name: "Custody" },
      { value: "Energy", name: "Energy" },
      { value: "Entertainment", name: "Entertainment" },
      { value: "Financial Tech", name: "Financial Tech" },
      { value: "Gaming", name: "Gaming" },
      { value: "Healthcare", name: "Healthcare" },
      { value: "Investment Fund", name: "Investment Fund" },
      { value: "Legal", name: "Legal" },
      { value: "Manufacturing", name: "Manufacturing" },
      { value: "Real Estate", name: "Real Estate" },
      { value: "Retail", name: "Retail" },
      { value: "Software/Technology", name: "Software/Technology" },
      { value: "Sports", name: "Sports" },
      { value: "Supply Chain", name: "Supply Chain" },
      { value: "Travel", name: "Travel" }
    ];
    this.locationOptions = countryList().getLabels();
    this.state = {
      // video + brief
      id: "",
      image: "", //required
      name: "", //required
      video: "",
      brief: "", //required

      // profile (all)
      description: "", //required
      industry: "", //required
      team: [{ name: "", link: "" }],
      team_keys: [v4("teams")],
      issuance_type: "", //required

      employees: "", //specific to equity
      address: "", // specific to debt & real estate
      interest_type: "", //specific to debt
      loan_term: "", //specific to debt
      loan_purpose: "", //specific to debt
      fund_focus: "", //specific to fund
      developer: "", //specific to real estate

      //STO details
      token_ticker: "", //required
      status: "", //required
      deal_size: "", //required
      token_price: "", //required
      start_date: "", //required
      end_date: "", //required
      location: "", //required
      minimum_investment: "",
      protocol: "",
      purchase_link: "",
      purchase_requirements: "",

      //social
      website_link: "",
      twitter_link: "",
      linkedin_link: "",
      facebook_link: "",
      medium_link: "",

      //legal (all)
      incorporation_name: "",
      regulation_type: "",
      legal_form: "",

      is_edit: false,
      validated: false,
      src: ""
    };
  }

  componentDidMount() {
    const { search } = window.location;
    if (search) {
      const idObj = queryString.parse(search);
      this.props.getTokenEditRequest(idObj.name);
      this.setState({ is_edit: true });
    } else {
      this.props.resetTokenForm();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        ...this.state,
        image: nextProps.imageUrl
      });
    }

    if (this.state.is_edit) {
      const { token: Items } = nextProps;
      let tokenData = {};
      if (Items.Items && Items.Items[0]) {
        tokenData = Items.Items[0];
        const {
          id,
          image,
          name,
          video,
          brief,
          description,
          industry,
          team,
          token_ticker,
          status,
          deal_size,
          token_price,
          start_date,
          end_date,
          location,
          minimum_investment,
          protocol,
          purchase_link,
          purchase_requirements,
          website_link,
          twitter_link,
          linkedin_link,
          facebook_link,
          medium_link,
          incorporation_name,
          regulation_type,
          legal_form,
          issuance_type,
          //specific
          employees,
          address,
          interest_type,
          loan_term,
          loan_purpose,
          fund_focus,
          developer
        } = tokenData;

        this.setState({
          id: id ? id : "",
          src: image ? image : "",
          image: image ? image : "",
          name: name ? name : "",
          video: video ? video : "",
          brief: brief ? brief : "",
          description: description ? description : "",
          industry: industry ? industry : "",
          team: team ? team : "",
          // team_keys: team_keys ? team_keys : '',
          issuance_type: issuance_type ? issuance_type : "",
          employees: employees ? employees : "",
          address: address ? address : "",
          interest_type: interest_type ? interest_type : "",
          loan_term: loan_term ? loan_term : "",
          loan_purpose: loan_purpose ? loan_purpose : "",
          fund_focus: fund_focus ? fund_focus : "",
          developer: developer ? developer : "",
          token_ticker: token_ticker ? token_ticker : "",
          status: status ? status : "",
          deal_size: deal_size ? deal_size : "",
          token_price: token_price ? token_price : "",
          start_date: start_date ? start_date : "",
          end_date: end_date ? end_date : "",
          location: location ? location : "",
          minimum_investment: minimum_investment ? minimum_investment : "",
          protocol: protocol ? protocol : "",
          purchase_link: purchase_link ? purchase_link : "",
          purchase_requirements: purchase_requirements
            ? purchase_requirements
            : "",
          website_link: website_link ? website_link : "",
          twitter_link: twitter_link ? twitter_link : "",
          linkedin_link: linkedin_link ? linkedin_link : "",
          facebook_link: facebook_link ? facebook_link : "",
          medium_link: medium_link ? medium_link : "",
          incorporation_name: incorporation_name ? incorporation_name : "",
          regulation_type: regulation_type ? regulation_type : "",
          legal_form: legal_form ? legal_form : ""
        });
      }
      if (nextProps.imageUrl !== this.props.imageUrl) {
        this.setState({
          ...this.state,
          image: nextProps.imageUrl
        });
      }
    }
  }

  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }; // end of handleFormChange(e)

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.is_edit) {
      this.props.editTokenRequest(this.state);
    } else {
      this.props.createTokenRequest(this.state);
    }
  }; // end of handleSubmit()

  handleRteChange = (content, delta, source, editor) => {
    this.setState({
      description: editor.getHTML()
    });
  }; // end of handleRteChange()

  handleSelectOptions = options =>
    options.map(option => (
      <option key={option.value} value={option.value}>
        {option.name}
      </option>
    )); // end of handleSelectOptions(options)

  onChangeDate = fieldName => date => {
    this.setState({ [fieldName]: date });
  }; // end of onChangeDate(fieldName, date)

  addTeam = () => {
    const { team, team_keys } = this.state;
    this.setState({
      team: [...team, { name: "", link: "" }],
      team_keys: [...team_keys, v4("team")]
    });
  }; // end of addTeam()

  removeTeam = index => {
    this.setState({
      team: this.state.team.filter((_, i) => i !== index),
      team_keys: this.state.team_keys.filter((_, i) => i !== index)
    });
  }; // end of removeTeam(index)

  handleTeamNameChange = (e, index) => {
    const { team } = this.state;
    const newTeamsObj = [...team];
    newTeamsObj[index] = Object.assign({}, newTeamsObj[index], {
      name: e.target.value
    });
    this.setState({ team: newTeamsObj });
  }; // end of handleTeamNameChange(e, index)

  handleTeamLinkChange = (e, index) => {
    const { team } = this.state;
    const newTeamsObj = [...team];
    newTeamsObj[index] = Object.assign({}, newTeamsObj[index], {
      link: e.target.value
    });
    this.setState({ team: newTeamsObj });
  }; // end of handleTeamLinkChange(e, index)

  handleLocationSelect = event => {
    this.setState({ location: event[0] });
  }; // end of handleLocationSelect(event)

  onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 71680) {
      alert("File is too big!");
      elem.target.value = "";
    }
  }; // end of onBeforeFileLoad(elem)

  onFileLoad = file => {
    this.props.uploadImageRequest(file);
  }; // end of onFileLoad(file)

  renderLogoImageUploader = () => {
    const { src } = this.state;
    const { image } = this.state;
    return (
      <React.Fragment>
        <Avatar
          width={100}
          height={100}
          imageWidth={100}
          imageHeight={100}
          label="Choose an Image"
          labelStyle={{ fontSize: 10 }}
          minCropRadius={100}
          src={image}
          onBeforeFileLoad={this.onBeforeFileLoad}
          onFileLoad={this.onFileLoad}
        />
      </React.Fragment>
    );
  }; // end of renderLogoImageUploader()

  renderTokenForm = () => {
    const { validated } = this.state;
    return (
      <Container className="form-content">
        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
          {this.renderVideoBriefSection()}
          {this.renderSTODetailsSection()}
          {this.renderProfileSection()}
          {this.renderLegalSection()}
          {this.renderSocialSection()}
          <Button type="submit">Submit</Button>
        </Form>
      </Container>
    );
  }; // end of renderTokenForm()

  renderVideoBriefSection = () => {
    const { image, name, video, brief, issuance_type } = this.state;
    return (
      <div className="token-form-sub-section">
        <h2>VIDEO + BRIEF</h2>
        <Form.Group as={Row} controlId="formHorizontalLogoImage">
          <Form.Label column sm={2}>
            Image*
          </Form.Label>
          <Col md={7}>{this.renderLogoImageUploader()}</Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Name*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Name..."
              onChange={this.handleFormChange}
              value={name}
              name="name"
              feedback="test"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Video Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Video Link..."
              onChange={this.handleFormChange}
              value={video}
              name="video"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Brief*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Brief..."
              onChange={this.handleFormChange}
              value={brief}
              name="brief"
              required
            />
          </Col>
        </Form.Group>
      </div>
    );
  }; // end of renderVideoBriefSection()

  renderSTODetailsSection = () => {
    const {
      protocol,
      purchase_link,
      token_ticker,
      status,
      deal_size,
      token_price,
      minimum_investment,
      purchase_requirements,
      location,
      start_date,
      end_date
    } = this.state;
    return (
      <div className="token-form-sub-section">
        <h2>STO DETAILS</h2>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Tech Protocol
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Tech Protocol..."
              onChange={this.handleFormChange}
              value={protocol}
              name="protocol"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Token Ticker*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Token Ticker..."
              onChange={this.handleFormChange}
              value={token_ticker}
              name="token_ticker"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Current Status*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              onChange={this.handleFormChange}
              value={status}
              name="status"
              as="select"
              required
            >
              {this.handleSelectOptions(this.statusOptions)}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalAddress">
          <Form.Label column sm={2}>
            Location*
          </Form.Label>
          <Col sm={8}>
            <Typeahead
              labelKey="value"
              options={this.locationOptions}
              placeholder="Location"
              onChange={event => this.handleLocationSelect(event)}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenPrice">
          <Form.Label column sm={2}>
            Price per Token*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Price per Token..."
              name="token_price"
              onChange={this.handleFormChange}
              value={token_price}
              type="number"
              step="0.01"
              min="0"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalDealSize">
          <Form.Label column sm={2}>
            Deal Size*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Deal Size..."
              name="deal_size"
              onChange={this.handleFormChange}
              value={deal_size}
              type="number"
              step="0.01"
              min="0"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalMinimumInvestment">
          <Form.Label column sm={2}>
            Minimum Investment
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Minimum Investment..."
              name="minimum_investment"
              onChange={this.handleFormChange}
              value={minimum_investment}
              type="number"
              step="0.01"
              min="0"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Purchase Requirements
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Purchase Requirements"
              onChange={this.handleFormChange}
              value={purchase_requirements}
              name="purchase_requirements"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Purchase Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Purchase Link..."
              onChange={this.handleFormChange}
              value={purchase_link}
              name="purchase_link"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalPropertyDate">
          <Form.Label column sm={2}>
            STO Start Date*
          </Form.Label>
          <Col sm={8}>
            <DatePicker
              name="start_date"
              onChange={this.onChangeDate("start_date")}
              value={start_date}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalPropertyDate">
          <Form.Label column sm={2}>
            STO End Date*
          </Form.Label>
          <Col sm={8}>
            <DatePicker
              name="end_date"
              onChange={this.onChangeDate("end_date")}
              value={end_date}
              required
            />
          </Col>
        </Form.Group>
      </div>
    );
  }; // end of renderSTODetailsSection()

  renderProfileSection = () => {
    const { issuance_type, description, industry, team } = this.state;
    return (
      <div className="token-form-sub-section">
        <h2>PROFILE</h2>
        <Form.Group as={Row} controlId="formHorizontalTokenName">
          <Form.Label column sm={2}>
            Token Type*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              onChange={this.handleFormChange}
              value={issuance_type}
              name="issuance_type"
              as="select"
              required
            >
              {this.handleSelectOptions(this.issuanceOptions)}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Description*
          </Form.Label>
          <Col sm={8}>
            <ReactQuill
              // theme={this.state.theme}
              onChange={this.handleRteChange}
              value={description || ""}
              // modules={RealEstateTokenForm.modules}
              // formats={RealEstateTokenForm.formats}
              placeholder="Description"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Industry*
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              onChange={this.handleFormChange}
              value={industry}
              name="industry"
              as="select"
              required
            >
              {this.handleSelectOptions(this.industryOptions)}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalDevelopmentTeam">
          <Form.Label column sm={2}>
            Management Team
          </Form.Label>
          <Col sm={8}>
            {team.map((team, index) => this.renderTeamInputForm(team, index))}
          </Col>
        </Form.Group>
        <Col sm={{ span: 8, offset: 2 }}>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-teams">Add Team</Tooltip>}
          >
            <IoIosAddCircle
              className="add-team-button"
              onClick={this.addTeam}
            />
          </OverlayTrigger>
        </Col>
        {this.renderSpecificProfileFields()}
      </div>
    );
  }; // end of renderProfileSection()

  renderTeamInputForm = (team, index) => {
    const { team_keys } = this.state;
    return (
      <React.Fragment key={`team-${team_keys[index]}`}>
        <Form.Group
          as={Row}
          controlId="formHorizontalCountries"
          className="every-team-info"
        >
          <Form.Label column sm={2} style={{ textAlign: "center" }}>
            Name:
          </Form.Label>
          <Col sm={3}>
            <Form.Control
              placeholder="Name"
              onChange={e => this.handleTeamNameChange(e, index)}
              value={team.name}
            />
          </Col>
          <Form.Label column sm={2} style={{ textAlign: "center" }}>
            LinkedIn:
          </Form.Label>
          <Col sm={3}>
            <Form.Control
              placeholder="Linkedin"
              onChange={e => this.handleTeamLinkChange(e, index)}
              value={team.link}
            />
          </Col>
          <Col sm={2}>
            <IoMdRemoveCircle onClick={() => this.removeTeam(index)} />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  }; // end of renderTeamInputForm(team, index)

  renderSpecificProfileFields = () => {
    const { issuance_type } = this.state;
    if (issuance_type === "Equity Token") {
      return this.renderEquityProfileFields();
    } else if (issuance_type === "Debt Security") {
      return this.renderDebtProfileFields();
    } else if (issuance_type === "Fund Security") {
      return this.renderFundProfileFields();
    } else if (issuance_type === "Real Estate Token") {
      return this.renderRealEstateProfileFields();
    }
  }; // end of renderSpecificProfileFields(issuance_type)

  renderEquityProfileFields = () => {
    const { employees } = this.state;
    return (
      <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
        <Form.Label column sm={2}>
          Number of Employees
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Number of Employees..."
            onChange={this.handleFormChange}
            value={employees}
            name="employees"
            type="number"
          />
        </Col>
      </Form.Group>
    );
  }; // end of renderEquityProfileFields()

  renderDebtProfileFields = () => {
    const { address, interest_type, loan_term, loan_purpose } = this.state;
    return (
      <React.Fragment>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Address of Property
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Address of Property..."
              onChange={this.handleFormChange}
              value={address}
              name="address"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Interest Type
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Interest Type..."
              onChange={this.handleFormChange}
              value={interest_type}
              name="interest_type"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Loan Term
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Loan Term..."
              onChange={this.handleFormChange}
              value={loan_term}
              name="loan_term"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Loan Purpose
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Loan Purpose..."
              onChange={this.handleFormChange}
              value={loan_purpose}
              name="loan_purpose"
            />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  }; // end of renderDebtProfileFields()

  renderRealEstateProfileFields = () => {
    const { address, developer } = this.state;
    return (
      <React.Fragment>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Address of Property
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Address of Property..."
              onChange={this.handleFormChange}
              value={address}
              name="address"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Development Company
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Development Company..."
              onChange={this.handleFormChange}
              value={developer}
              name="developer"
            />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  }; // end of renderRealEstateProfileFields()

  renderFundProfileFields = () => {
    const { fund_focus } = this.state;
    return (
      <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
        <Form.Label column sm={2}>
          Fund Focus
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Fund Focus..."
            onChange={this.handleFormChange}
            value={fund_focus}
            name="fund_focus"
          />
        </Col>
      </Form.Group>
    );
  }; // end of renderFundProfileFields()

  renderLegalSection = () => {
    const { incorporation_name, legal_form, regulation_type } = this.state;
    return (
      <div className="token-form-sub-section">
        <h2>LEGAL</h2>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Company Name
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Company Name..."
              onChange={this.handleFormChange}
              value={incorporation_name}
              name="incorporation_name"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Legal Form
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              onChange={this.handleFormChange}
              value={legal_form}
              name="legal_form"
              as="select"
            >
              {this.handleSelectOptions(this.legalFormOptions)}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Regulation Type
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Regulation Type..."
              onChange={this.handleFormChange}
              value={regulation_type}
              name="regulation_type"
            />
          </Col>
        </Form.Group>
      </div>
    );
  }; // end of renderLegalSection()

  renderSocialSection = () => {
    const {
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link
    } = this.state;
    return (
      <div className="token-form-sub-section">
        <h2>SOCIAL</h2>
        <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
          <Form.Label column sm={2}>
            Website Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Website Link..."
              onChange={this.handleFormChange}
              value={website_link}
              name="website_link"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalTwitter">
          <Form.Label column sm={2}>
            Twitter Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Twitter Link..."
              onChange={this.handleFormChange}
              value={twitter_link}
              name="twitter_link"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalLinkedIn">
          <Form.Label column sm={2}>
            LinkedIn Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="LinkedIn Link..."
              onChange={this.handleFormChange}
              value={linkedin_link}
              name="linkedin_link"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalFacebook">
          <Form.Label column sm={2}>
            Facebook Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Facebook Link..."
              onChange={this.handleFormChange}
              value={facebook_link}
              name="facebook_link"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formHorizontalMedium">
          <Form.Label column sm={2}>
            Medium Link
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Medium Link..."
              onChange={this.handleFormChange}
              value={medium_link}
              name="medium_link"
            />
          </Col>
        </Form.Group>
      </div>
    );
  }; // end of renderSocialSection()

  render() {
    return (
      <div className="admin-portal-content">
        <h1 className="form-header">TOKEN UPLOAD FORM</h1>
        <p>(fields labeled with * are required)</p>
        {this.renderTokenForm()}
      </div>
    );
  } // end of render()
} // end of class TokenForm

export default TokenForm;
