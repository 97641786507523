import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  Jumbotron,
  Spinner,
  Image,
  Modal,
  Table,
  FormFile,
  Pagination,
  Accordion
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Files from "react-butterfiles";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import countryList from "react-select-country-list";
import {
  MdArrowBack,
  MdEdit,
  MdAdd,
  MdRemove,
  MdClear,
  MdCheck,
  MdDelete
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { IoIosArrowRoundBack, IoMdClose } from "react-icons/io";
import ImageUploader from "react-images-upload";
import stockImage from "../../../images/stockimage.png";
import DatePicker from "react-datepicker";
import readXlsxFile from "read-excel-file";
import AssetProfilesTable from "./AssetProfilesTable.js";
import "react-datepicker/dist/react-datepicker.css";

class AssetProfiles extends PureComponent {
  constructor(props) {
    super(props);

    this.locationOptions = countryList().getLabels();

    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.state = {
      assetTypes: this.props.assetTypes,
      assetProfileToEdit: this.props.assetProfileToEdit,
      tradedSymbols: this.props.tradedSymbols,
      params: {
        assetType: "",
        verification: "",
        q: "",
        offset: 0
      },
      currentModal: "",
      modals: "",
      assetTypeForProfileCreation: "",
      currentPage: 1,
      currentTab: "profile",
      offerParams: {
        symbol: "",
        name: "",
        offer_type: "",
        status: "",
        units_offered: "",
        min_units: "",
        start: yesterday,
        end: today
      }
    };
  }

  componentDidMount() {
    this.props.resetTokenFormParams();
    this.props.getTradedSymbolsRequest({ q: "" });
    this.handleSearch();
    this.props.getPublishedTemplatesRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        tokenProfilesList: nextProps.tokenProfilesList,
        assetTypes: nextProps.assetTypes,
        assetProfileToEdit: nextProps.assetProfileToEdit,
        tradedSymbols: nextProps.tradedSymbols
      });
    }
  }

  handleChangeParams = e => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value
        }
      },
      () => this.handleSearch()
    );
  };

  handleSearch = () => {
    this.props.getTokenProfilesListRequest(this.state.params);
  };

  handleCreateAssetProfile = () => {
    this.props.createAssetProfileRequest(
      this.state.assetTypeForProfileCreation
    );
    this.handleHideCreateAssetProfileModal();
  };

  handleChangeField = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;

    if (objectSubfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].subfields[objectSubfieldIndex].value = e.target.value;
    } else if (subfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].value = e.target.value;
    } else {
      newProfileSections[sectionIndex].fields[fieldIndex].value =
        e.target.value;
    }

    this.handleSetStateAndSave();
  };

  handleChangeSubfieldFieldname = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    newProfileSections[sectionIndex].fields[fieldIndex].subfields[
      subfieldIndex
    ].fieldName = e.target.value;

    this.handleSetStateAndSave();
  };

  handleDeleteSubfield = (subfieldIndex, fieldIndex, sectionIndex) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    newProfileSections[sectionIndex].fields[fieldIndex].subfields.splice(
      subfieldIndex,
      1
    );

    this.handleSetStateAndSave();
  };

  handleAddSubfieldToDropdown = (fieldIndex, sectionIndex) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    newProfileSections[sectionIndex].fields[fieldIndex].subfields.push({
      fieldName: "",
      value: ""
    });

    this.handleSetStateAndSave();
  };

  handleAddSubfieldToObject = (fieldIndex, sectionIndex) => {
    const newTemplateField = this.state.assetProfileToEdit.form.template
      .sections[sectionIndex].fields[fieldIndex];
    const subfield = newTemplateField.subfield;
    const newProfileField = this.state.assetProfileToEdit.form.profile.sections[
      sectionIndex
    ].fields[fieldIndex];
    newProfileField.subfields.push(subfield);

    this.handleSetStateAndSave();
  };

  handleDeleteSubfieldFromObject = (
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const newProfileField = this.state.assetProfileToEdit.form.profile.sections[
      sectionIndex
    ].fields[fieldIndex];
    newProfileField.subfields.splice(subfieldIndex, 1);

    this.handleSetStateAndSave();
  };

  handleChangeSubfieldDropdown = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    newProfileSections[sectionIndex].fields[fieldIndex].subfields[
      subfieldIndex
    ].value = e.target.value;

    this.handleSetStateAndSave();
  };

  handleChangeSubfieldObjectFieldname = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    newProfileSections[sectionIndex].fields[fieldIndex].subfields[
      subfieldIndex
    ].fieldName = e.target.value;

    this.handleSetStateAndSave();
  };

  handleChangeOffering = e => {
    const newProfileOffering = this.state.assetProfileToEdit.form.profile
      .offering;
    newProfileOffering[e.target.name] = e.target.value;

    const id = this.state.assetProfileToEdit.form.profile._id;
    this.props.updateAssetProfileOfferingRequest(id, newProfileOffering);
    this.handleSetStateAndSave();
  };

  handleChangeDisplayText = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    if (objectSubfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].subfields[objectSubfieldIndex].displayText = e.target.value;
    } else if (subfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].displayText = e.target.value;
    } else {
      newProfileSections[sectionIndex].fields[fieldIndex].displayText =
        e.target.value;
    }

    this.handleSetStateAndSave();
  };

  handleChangeSocialPlatform = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    if (objectSubfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].subfields[objectSubfieldIndex].platform = e.target.value;
    } else if (subfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].platform = e.target.value;
    } else {
      newProfileSections[sectionIndex].fields[fieldIndex].platform =
        e.target.value;
    }

    this.handleSetStateAndSave();
  };

  handleChangeSubfield = (e, subfieldIndex, fieldIndex, sectionIndex) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    newProfileSections[sectionIndex].fields[fieldIndex].fields[subfieldIndex] =
      e.target.value;

    this.handleSetStateAndSave();
  };

  handleOnChangeDateField = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    if (objectSubfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].subfields[objectSubfieldIndex].value = e;
    } else if (subfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].value = e;
    } else {
      newProfileSections[sectionIndex].fields[fieldIndex].value = e;
    }

    this.handleSetStateAndSave();
  };

  handleOnChangeLocation = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    const value = e[0];
    const newProfileSections = this.state.assetProfileToEdit.form.profile
      .sections;
    if (objectSubfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].subfields[objectSubfieldIndex].value = value;
    } else if (subfieldIndex >= 0) {
      newProfileSections[sectionIndex].fields[fieldIndex].subfields[
        subfieldIndex
      ].value = value;
    } else {
      newProfileSections[sectionIndex].fields[fieldIndex].value = value;
    }

    this.handleSetStateAndSave();
  };

  handleChangeAssetProfile = e => {
    const newProfile = this.state.assetProfileToEdit.form.profile;
    newProfile[e.target.name] = e.target.value;

    this.handleSetStateAndSave();
  };

  handleChangeAssetProfileLocation = location => {
    const newProfile = this.state.assetProfileToEdit.form.profile;
    newProfile.location = location;

    this.handleSetStateAndSave();
  };

  handleSetStateAndSave = () => {
    this.setState(
      {
        assetProfileToEdit: {
          ...this.state.assetProfileToEdit
        }
      },
      () =>
        this.props.editTokenFormRequest(
          this.state.assetProfileToEdit.form.profile
        )
    );
  };

  handleSelectFile = (
    e,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    if (e.target.files.length > 0) {
      const formData = this.state.assetProfileToEdit.form.profile;
      var fileData = e.target.files[0];
      this.props.assetProfileUploadFileRequest(
        fileData,
        formData,
        fieldIndex,
        sectionIndex,
        subfieldIndex,
        objectSubfieldIndex
      );
    }
  };

  handleClearFile = (
    url,
    fieldIndex,
    sectionIndex,
    subfieldIndex,
    objectSubfieldIndex
  ) => {
    const formData = this.state.assetProfileToEdit.form.profile;

    this.props.assetProfileDeleteFileRequest(
      url,
      formData,
      fieldIndex,
      sectionIndex,
      subfieldIndex,
      objectSubfieldIndex
    );
  };

  handleUploadAssetProfileImage = e => {
    if (e.target.files.length > 0) {
      const formData = this.state.assetProfileToEdit.form.profile;
      var fileData = e.target.files[0];
      this.props.uploadAssetProfileImageRequest(fileData, formData);
    }
  };

  handleUploadSponsorImage = e => {
    if (e.target.files.length > 0) {
      const formData = this.state.assetProfileToEdit.form.profile;
      var fileData = e.target.files[0];
      this.props.uploadAssetProfileImageRequest(fileData, formData, "sponsor");
    }
  };

  handleClearAssetProfileImage = url => {
    const formData = this.state.assetProfileToEdit.form.profile;

    this.props.deleteAssetProfileImageRequest(url, formData);
  };

  handleClearSponsorImage = url => {
    const formData = this.state.assetProfileToEdit.form.profile;

    this.props.deleteAssetProfileImageRequest(url, formData, "sponsor");
  };

  handleUploadOfferingDocument = (e, location) => {
    if (e.target.files.length > 0) {
      const formData = this.state.assetProfileToEdit.form.profile;
      var fileData = e.target.files[0];
      this.props.uploadOfferingDocumentRequest(fileData, location, formData);
    }
  };

  handleClearOfferingDocument = (url, location) => {
    const formData = this.state.assetProfileToEdit.form.profile;
    this.props.clearOfferingDocument(url, location, formData);
  };

  handleHideCreateAssetProfileModal = () => {
    this.setState({
      currentModal: "",
      assetTypeForProfileCreation: ""
    });
  };

  handleShowCreateAssetProfileModal = () => {
    this.setState({
      currentModal: "showCreateAssetProfileModal",
      assetTypeForProfileCreation: ""
    });
  };

  handleSelectAssetTypeForProfileCreation = e => {
    this.setState({
      assetTypeForProfileCreation: e.target.value
    });
  };

  handleSelectProfile = id => {
    this.props.selectAssetProfileRequest(id);
  };

  handleShowConfirmDeleteModal = id => {
    this.setState({
      currentModal: "showConfirmDeleteModal",
      deleteProfileId: id
    });
  };

  handleHideConfirmDeleteModal = () => {
    this.setState({
      currentModal: "",
      deleteProfileId: ""
    });
  };

  handleDeleteProfile = id => {
    this.props.deleteAssetProfileRequest(id, this.state.params);
    this.handleHideConfirmDeleteModal();
  };

  handleReturn = () => {
    this.handleSearch();
    this.props.resetTokenFormParams();
  };

  handlePublish = () => {
    const id = this.state.assetProfileToEdit.form.profile._id;

    this.props.publishAssetProfileRequest(id);
  };

  handleUnpublish = () => {
    const id = this.state.assetProfileToEdit.form.profile._id;

    this.props.unpublishAssetProfileRequest(id);
  };

  handleImageError = e => {
    e.target.src = stockImage;
  };

  handleClickPage = number => {
    this.setState(
      {
        currentPage: number,
        params: {
          ...this.state.params,
          offset: (number - 1) * 10
        }
      },
      () => this.handleSearch()
    );
  };

  handleUpdateOffer = () => {
    const { form } = this.state.assetProfileToEdit;
    this.setState(
      {
        offerParams: {
          ...this.state.offerParams,
          profile_id: form.profile._id
        }
      },
      () => this.props.updateOfferRequest(this.state.offerParams)
    );
  };

  handleChangeOfferParams = e => {
    this.setState(
      {
        offerParams: {
          ...this.state.offerParams,
          [e.target.name]: e.target.value
        }
      },
      () => this.handleUpdateOffer()
    );
  };

  handleChangeOfferDate = (date, name) => {
    this.setState(
      {
        offerParams: {
          ...this.state.offerParams,
          [name]: date
        }
      },
      () => this.handleUpdateOffer()
    );
  };

  importFromExcel = e => {
    const files = e.target.files;
    readXlsxFile(files[0]).then(rows => {
      const individualForms = [];

      for (let i = 0; i < rows[0].length; i++) {
        const title = rows[0][i];
        const newFormObj = { name: title };
        individualForms.push(newFormObj);
      }

      for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        const tempRowObj = {};
        const row = rows[rowIndex];
        const treatment = row[1];

        for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
          const cell = row[cellIndex];
          const currentForm = individualForms[cellIndex];
          const newDataObj = {
            value: cell,
            treatment: treatment
          };
          currentForm[row[0]] = newDataObj;
        }
      }

      // const splicedForms = individualForms.splice(0, 13); // no longer necessary

      // console.log(JSON.stringify(individualForms))

      //go through the splicedForms and create a new field for each one
      // start with just strings

      this.props.importFromExcelRequest(individualForms, this.state.params);
    });
  };
  /**********************************************************************/

  renderContent = () => {
    return (
      <React.Fragment>
        <Row noGutters className="forms-header">
          <h4>Create an Asset Profile</h4>
        </Row>
        <div className="forms-table-box">
          {this.renderSearchbar()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </React.Fragment>
    );
  };

  renderSearchbar = () => {
    const { assetType, verification, q } = this.state.params;

    let options;
    if (this.state.assetTypes.loaded) {
      options = this.state.assetTypes.templates.docs.map(option =>
        this.renderAssetTypeOptions(option)
      );
    }

    let importFromExcelBtn = (
      <Col>
        <label className="submission-form-control-label">
          Import from Excel (beta):
        </label>
        <Form.File
          custom
          size="sm"
          onChange={this.importFromExcel}
          label="Select a .XLS file..."
          className="import-form-button"
        />
      </Col>
    );

    return (
      <Row noGutters className="submissions-search-bar">
        <Col>
          <label className="submission-form-control-label">
            Filter by Asset Type:
          </label>
          <FormControl
            size="sm"
            name="assetType"
            as="select"
            value={assetType}
            onChange={this.handleChangeParams}
            className="submission-form-control"
          >
            <option value="">Select an Asset Type...</option>
            {options}
          </FormControl>
        </Col>
        <Col>
          <label className="submission-form-control-label">
            Filter by Verification:
          </label>
          <FormControl
            size="sm"
            name="verification"
            as="select"
            value={verification}
            onChange={this.handleChangeParams}
            className="submission-form-control"
          >
            <option value="">Select a Verification...</option>
            <option value="published">Published</option>
            <option value="pending">Pending</option>
            <option value="unverified">Unverified</option>
          </FormControl>
        </Col>
        <Col>
          <label className="submission-form-control-label">Search:</label>
          <FormControl
            size="sm"
            name="q"
            value={q}
            onChange={this.handleChangeParams}
            placeholder="Search..."
            className="submission-form-control"
          />
        </Col>

        <div className="token-template-create-row">
          <Button
            onClick={this.handleShowCreateAssetProfileModal}
            className="teal-button"
            size="sm"
          >
            Create Asset Profile
          </Button>
        </div>
      </Row>
    );
  };

  renderAssetTypeOptions = option => {
    const { name, _id } = option;
    return (
      <option key={_id} value={_id}>
        {name || _id}
      </option>
    );
  };

  renderCreateAssetProfileModal = () => {
    const { currentModal } = this.state;

    let options;
    if (this.state.assetTypes.loaded) {
      options = this.state.assetTypes.templates.docs.map(option =>
        this.renderAssetTypeOptions(option)
      );
    }

    let disabled;
    if (!this.state.assetTypeForProfileCreation) {
      disabled = true;
    } else {
      disabled = false;
    }

    const show = currentModal == "showCreateAssetProfileModal" ? true : false;

    return (
      <Modal
        centered
        className="admin-modal"
        show={show}
        onHide={this.handleHideCreateAssetProfileModal}
      >
        <Modal.Header closeButton>Create Asset Profile</Modal.Header>

        <Modal.Body>
          <Col>
            <label className="submission-form-control-label">
              Select an Asset Type for this Asset Profile
            </label>
            <FormControl
              size="sm"
              as="select"
              value={this.state.assetTypeForProfileCreation}
              onChange={this.handleSelectAssetTypeForProfileCreation}
              className="submission-form-control"
            >
              <option value="">Select an Asset Type...</option>
              {options}
            </FormControl>
          </Col>
        </Modal.Body>

        <Modal.Footer>
          <Row noGutters className="confirm-delete-modal-row">
            <Button
              onClick={this.handleCreateAssetProfile}
              disabled={disabled}
              className="teal-button"
              size="sm"
            >
              Create Asset Profile
            </Button>

            <Button
              onClick={this.handleHideCreateAssetProfileModal}
              className="delete-button"
              size="sm"
            >
              Cancel
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTable = () => {
    if (!this.state.tokenProfilesList) return;

    const { profiles, loading, loaded } = this.state.tokenProfilesList;
    let content;

    if (loading) {
      content = this.renderTableLoading();
    } else if (loaded) {
      if (profiles.total > 0) {
        content = profiles.docs.map((profile, profileIndex) =>
          this.renderProfile(profile, profileIndex)
        );
      } else {
        content = (
          <tr>
            <td colSpan="4">
              <Row noGutters className="table-loading-row">
                No profiles found
              </Row>
            </td>
          </tr>
        );
      }
    }

    return (
      <Table bordered className="admin-table">
        <thead>
          <tr>
            <th className="common-image-th">Image</th>
            <th>Name</th>
            <th>Asset Type</th>
            <th>Verification</th>
          </tr>
        </thead>

        <tbody>{content}</tbody>
      </Table>
    );
  };

  renderProfile = (profile, profileIndex) => {
    const { name, _id, template_id, verification, image } = profile;

    let renderTemplateId;
    if (template_id) {
      renderTemplateId =
        Object.keys(template_id).length > 0
          ? template_id.name || template_id._id
          : "No associated template_id";
    }
    let renderVerification, linkButton;
    if (verification == "published") {
      renderVerification = <div id="true-content">Published</div>;
      linkButton = (
        <NavLink to={`/assets/${name}`}>
          <Button className="teal-button xs-btn" size="sm">
            View
          </Button>
        </NavLink>
      );
    } else {
      renderVerification = <div id="false-content">Unverified</div>;
    }

    return (
      <tr key={_id}>
        <td>
          <Image
            src={image || stockImage}
            onError={this.handleImageError}
            className="common-table-image"
          />
        </td>
        <td>{name || "No name given"}</td>
        <td>{renderTemplateId}</td>
        <td>
          <Row noGutters className="td-row">
            {renderVerification}
            <div className="table-button-container">
              {linkButton}
              <Button
                className="teal-button xs-btn"
                size="sm"
                onClick={() => this.handleSelectProfile(_id)}
              >
                Edit
              </Button>
              <Button
                onClick={() => this.props.cloneProfileRequest(_id)}
                className="teal-button xs-btn"
                size="sm"
              >
                Copy
              </Button>
              <Button
                className="delete-button xs-btn"
                size="sm"
                onClick={() => this.handleShowConfirmDeleteModal(_id)}
              >
                Delete
              </Button>
            </div>
          </Row>
        </td>
      </tr>
    );
  };

  renderTableLoading = () => (
    <tr>
      <td colSpan="4">
        <Row noGutters className="table-loading-row">
          <Spinner
            className="asset-profile-loading-spinner"
            animation="border"
          />
        </Row>
      </td>
    </tr>
  );

  renderConfirmDeleteModal = () => {
    const show =
      this.state.currentModal == "showConfirmDeleteModal" ? true : false;
    const profileId = this.state.deleteProfileId;
    return (
      <Modal
        centered
        className="admin-modal"
        show={show}
        onHide={this.handleHideConfirmDeleteModal}
      >
        <Modal.Header closeButton>User Data</Modal.Header>

        <Modal.Body>
          <Row noGutters className="confirm-delete-modal-row">
            Are you sure you want to delete this Asset Profile?
          </Row>

          <Row noGutters className="confirm-delete-modal-row email-row">
            <b>{profileId}</b>
          </Row>

          <Row noGutters className="confirm-delete-modal-row">
            <Button
              onClick={() => this.handleDeleteProfile(profileId)}
              className="delete-button"
              size="sm"
            >
              Delete
            </Button>

            <Button
              onClick={this.handleHideConfirmDeleteModal}
              className="teal-button"
              size="sm"
            >
              Cancel
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  handleChangeTab = tab => {
    this.setState({
      currentTab: tab
    });
  };

  renderSelectedProfile = () => {
    const { loaded, loading, form } = this.state.assetProfileToEdit;

    let content;
    if (loaded) {
      const {
        sections,
        name,
        image,
        symbol,
        tradedSymbol,
        description,
        location,
        industry,
        offer_id,
        _id,
        serial_number,
        sponsor_link,
        sponsor_image,
        contract_terms,
        max_supply
      } = form.profile;
      let defaultSelectedLocation;
      if (location) {
        defaultSelectedLocation = [location];
      }

      const templateSections = form.template.sections.map(
        (section, sectionIndex) => this.renderSection(section, sectionIndex)
      );

      let publishButton;
      let disabled = false;
      if (form.profile.verification == "published") {
        publishButton = (
          <Button
            onClick={this.handleUnpublish}
            className="teal-button"
            size="sm"
          >
            Unpublish
          </Button>
        );
        disabled = true;
      } else {
        publishButton = (
          <Button
            onClick={this.handlePublish}
            className="teal-button"
            size="sm"
          >
            Publish
          </Button>
        );
      }

      let imageContent;
      if (form.profile.image) {
        if (
          form.profile.image.includes(".png") ||
          form.profile.image.includes(".jpg")
        ) {
          let deleteIcon;
          if (!disabled) {
            deleteIcon = (
              <IoMdClose
                onClick={() =>
                  this.handleClearAssetProfileImage(form.profile.image)
                }
                className="ap-image-cancel-icon"
              />
            );
          }
          imageContent = (
            <div className="ap-edit-image-container">
              {deleteIcon}
              <img className="ap-image" src={form.profile.image} />
            </div>
          );
        }
      } else {
        imageContent = (
          <Form.File
            custom
            disabled={disabled}
            onChange={e => this.handleUploadAssetProfileImage(e)}
            label="Upload an image..."
            className="registration-form-file-control ap-image-uploader"
          />
        );
      }

      let sponsorImageContent;
      if (sponsor_image) {
        if (sponsor_image.includes(".png") || sponsor_image.includes(".jpg")) {
          let deleteIcon;
          if (!disabled) {
            deleteIcon = (
              <IoMdClose
                onClick={() => this.handleClearSponsorImage(sponsor_image)}
                className="ap-image-cancel-icon"
              />
            );
          }
          sponsorImageContent = (
            <div className="ap-edit-image-container">
              {deleteIcon}
              <img className="ap-image" src={sponsor_image} />
            </div>
          );
        }
      } else {
        sponsorImageContent = (
          <Form.File
            custom
            disabled={disabled}
            onChange={e => this.handleUploadSponsorImage(e)}
            label="Upload an image..."
            className="registration-form-file-control ap-image-uploader"
          />
        );
      }

      let symbolOptions;
      if (this.state.tradedSymbols.loaded) {
        symbolOptions = this.state.tradedSymbols.data.map(symbol =>
          this.renderSymbolOption(symbol)
        );
      }

      let tabContent;
      if (this.state.currentTab == "profile") {
        tabContent = (
          <React.Fragment>
            <Row noGutters className="ap-edit-form-info-row">
              <div className="ap-edit-formfile-container">
                <label className="submission-form-control-label">Image:</label>
                <div className="ap-edit-formfile-interior">{imageContent}</div>
              </div>
              <Col>
                <Row noGutters className="ap-edit-row">
                  <Col>
                    <label className="submission-form-control-label">
                      Name:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      name="name"
                      value={name}
                      onChange={this.handleChangeAssetProfile}
                      placeholder="Name..."
                      className="submission-form-control"
                    />
                  </Col>
                  <Col>
                    <label className="submission-form-control-label">
                      Serial Number:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      name="serial_number"
                      value={serial_number}
                      onChange={this.handleChangeAssetProfile}
                      placeholder="Serial Number..."
                      className="submission-form-control"
                    />
                  </Col>
                  <Col>
                    <Row noGutters>
                      <Col>
                        <label className="submission-form-control-label">
                          Symbol:
                        </label>
                        <FormControl
                          disabled={disabled}
                          size="sm"
                          name="symbol"
                          as="select"
                          value={symbol}
                          onChange={this.handleChangeAssetProfile}
                          placeholder="Symbol..."
                          className="submission-form-control"
                        >
                          <option value="">Select a symbol...</option>
                          {symbolOptions}
                        </FormControl>
                      </Col>
                      <Col>
                        <label className="submission-form-control-label">
                          Traded Symbol:
                        </label>
                        <FormControl
                          disabled={disabled}
                          size="sm"
                          as="select"
                          name="tradedSymbol"
                          value={tradedSymbol}
                          onChange={this.handleChangeAssetProfile}
                          placeholder="Traded Symbol..."
                          className="submission-form-control"
                        >
                          <option value="">Select a traded symbol...</option>
                          {symbolOptions}
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row noGutters className="ap-edit-row">
                  <Col>
                    <label className="submission-form-control-label">
                      Description:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      as="textarea"
                      name="description"
                      value={description}
                      onChange={this.handleChangeAssetProfile}
                      placeholder="Description..."
                      className="submission-form-control"
                    />
                  </Col>
                </Row>
                <Row noGutters className="ap-edit-row">
                  <Col>
                    <label className="submission-form-control-label">
                      Industry:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      as="select"
                      name="industry"
                      value={industry}
                      onChange={this.handleChangeAssetProfile}
                      className="submission-form-control"
                    >
                      <option value="">Select an Industry...</option>
                      {this.props.industries.map(item => {
                        if (item.includes("--")) {
                          return <option disabled>{item}</option>;
                        } else {
                          return <option key={item}>{item}</option>;
                        }
                      })}
                    </FormControl>
                  </Col>
                  <Col>
                    <label className="submission-form-control-label">
                      Location:
                    </label>
                    <Typeahead
                      disabled={disabled}
                      defaultSelected={defaultSelectedLocation}
                      className="submission-form-control common-typeahead"
                      labelKey="value"
                      onChange={this.handleChangeAssetProfileLocation}
                      options={this.locationOptions}
                      placeholder="Select a Location..."
                    />
                  </Col>
                  <Col>
                    <label className="submission-form-control-label">
                      Contract Terms:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      name="contract_terms"
                      value={contract_terms}
                      onChange={this.handleChangeAssetProfile}
                      placeholder="Contract Terms..."
                      className="submission-form-control"
                    />
                  </Col>
                  <Col>
                    <label className="submission-form-control-label">
                      Max Supply:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      type="number"
                      name="max_supply"
                      value={max_supply}
                      onChange={this.handleChangeAssetProfile}
                      placeholder="Max Supply..."
                      className="submission-form-control"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Accordion className="sponsor-accordion">
              <Accordion.Toggle
                as={Button}
                className="teal-button sponsor-toggle"
                size="sm"
                eventKey="0"
              >
                Sponsor Info (optional)
              </Accordion.Toggle>
              <Accordion.Collapse className="sponsor-collapse" eventKey="0">
                <Row noGutters>
                  <div className="ap-edit-formfile-container">
                    <label className="submission-form-control-label">
                      Sponsor Image:
                    </label>
                    <div className="ap-edit-formfile-interior">
                      {sponsorImageContent}
                    </div>
                  </div>
                  <Col>
                    <label className="submission-form-control-label">
                      Sponsor Link:
                    </label>
                    <FormControl
                      disabled={disabled}
                      size="sm"
                      name="sponsor_link"
                      value={sponsor_link}
                      onChange={this.handleChangeAssetProfile}
                      placeholder="Sponsor Link..."
                      className="submission-form-control"
                    />
                  </Col>
                </Row>
              </Accordion.Collapse>
            </Accordion>
            {templateSections}
          </React.Fragment>
        );
      } else if (this.state.currentTab == "offering") {
        if (offer_id) {
          const {
            symbol,
            name,
            offer_type,
            status,
            units_offered,
            min_units,
            start,
            end
          } = this.state.offerParams;
          tabContent = (
            <Row noGutters>
              <Col className="offering-details-col">
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">
                    Symbol
                  </label>
                  <FormControl
                    size="sm"
                    onChange={this.handleChangeOfferParams}
                    name="symbol"
                    value={symbol}
                    placeholder="Symbol..."
                    className="submission-form-control"
                  />
                </Row>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">Name</label>
                  <FormControl
                    size="sm"
                    onChange={this.handleChangeOfferParams}
                    name="name"
                    value={name}
                    placeholder="Name..."
                    className="submission-form-control"
                  />
                </Row>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">
                    Offer Type
                  </label>
                  <FormControl
                    size="sm"
                    onChange={this.handleChangeOfferParams}
                    name="offer_type"
                    value={offer_type}
                    placeholder="Offer Type..."
                    className="submission-form-control"
                  />
                </Row>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">
                    Status
                  </label>
                  <FormControl
                    size="sm"
                    onChange={this.handleChangeOfferParams}
                    name="status"
                    value={status}
                    placeholder="Status..."
                    className="submission-form-control"
                  />
                </Row>
              </Col>
              <Col>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">
                    Units Offered
                  </label>
                  <FormControl
                    size="sm"
                    onChange={this.handleChangeOfferParams}
                    name="units_offered"
                    value={units_offered}
                    placeholder="Units Offered..."
                    className="submission-form-control"
                  />
                </Row>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">
                    Minimum Units
                  </label>
                  <FormControl
                    size="sm"
                    onChange={this.handleChangeOfferParams}
                    name="min_units"
                    value={min_units}
                    placeholder="Minimum Units..."
                    className="submission-form-control"
                  />
                </Row>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">Start</label>
                  <DatePicker
                    onChange={date => this.handleChangeOfferDate(date, "start")}
                    selected={new Date(start)}
                    className="admin-datepicker"
                    showTimeInput
                  />
                </Row>
                <Row noGutters className="offering-details-row">
                  <label className="submission-form-control-label">End</label>
                  <DatePicker
                    onChange={date => this.handleChangeOfferDate(date, "end")}
                    selected={new Date(end)}
                    className="admin-datepicker"
                    showTimeInput
                  />
                </Row>
              </Col>
            </Row>
          );
        } else {
          tabContent = (
            <div className="create-offering-div">
              <Button
                className="teal-button"
                size="sm"
                onClick={() => this.props.createOfferingRequest(_id)}
              >
                <MdAdd />
                Create Offering
              </Button>
            </div>
          );
        }
      }

      content = (
        <React.Fragment>
          <Row noGutters className="forms-header edit-forms-header">
            <h4 className="return-to-header" onClick={this.handleReturn}>
              <IoIosArrowRoundBack /> Return to Create an Asset Profile
            </h4>
          </Row>

          <div className="forms-table-box">
            <Row noGutters className="ap-edit-publish-row">
              <div className="ap-edit-tabs">
                <div
                  onClick={() => this.handleChangeTab("profile")}
                  className="ap-edit-tab"
                  id={this.state.currentTab == "profile" ? "current-tab" : ""}
                >
                  Profile
                </div>
                <div
                  onClick={() => this.handleChangeTab("offering")}
                  className="ap-edit-tab"
                  id={this.state.currentTab == "offering" ? "current-tab" : ""}
                >
                  Offering Details
                </div>
              </div>
              {publishButton}
            </Row>

            {tabContent}
          </div>
        </React.Fragment>
      );
    }

    return content;
  };

  renderOfferingDocumentUploader = (offeringLocation, offering, disabled) => {
    let content;
    if (offering) {
      content = (
        <React.Fragment>
          <div className="offering-document-container">
            <a
              target="_blank"
              href={offering}
              className="registration-document-preview"
            >
              Click here to view this document
            </a>
            <IoMdClose
              onClick={() =>
                this.handleClearOfferingDocument(offering, offeringLocation)
              }
              className="offering-document-clear-button"
            />
          </div>
        </React.Fragment>
      );
    } else {
      content = (
        <Form.File
          disabled={disabled}
          custom
          onChange={e => this.handleUploadOfferingDocument(e, offeringLocation)}
          label="Upload a document from your computer..."
          className="registration-form-file-control"
        />
      );
    }

    return content;
  };

  renderSymbolOption = symbol => {
    const { security } = symbol;

    return <option key={security}>{security}</option>;
  };

  renderSection = (section, sectionIndex) => {
    const { name, fields } = section;

    return (
      <Row noGutters className="ap-edit-content-section" key={sectionIndex}>
        <Col>
          <Row noGutters className="ap-edit-section-row">
            <label className="ap-edit-section-label">{name}</label>
            <div className="ap-edit-required-info">
              Fields marked with ' * ' are required
            </div>
          </Row>
          {fields.map((field, fieldIndex) =>
            this.renderField(field, fieldIndex, sectionIndex)
          )}
        </Col>
      </Row>
    );
  };

  renderField = (field, fieldIndex, sectionIndex) => {
    const { fieldName, fieldType, required } = field;
    const value = this.state.assetProfileToEdit.form.profile.sections[
      sectionIndex
    ].fields[fieldIndex].value;

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let formControl;
    let optionalButton;
    if (fieldType === "string") {
      formControl = this.renderStringInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "number") {
      formControl = this.renderNumberInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "paragraph") {
      formControl = this.renderParagraphInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "video") {
      formControl = this.renderStringInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "document") {
      formControl = this.renderFileUploader(field, fieldIndex, sectionIndex);
    } else if (fieldType === "link") {
      formControl = this.renderLinkInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "social") {
      formControl = this.renderSocialInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "multi") {
      if (field.subfield) {
        if (field.subfield.fieldType == "dropdown") {
          optionalButton = (
            <Button
              disabled={disabled}
              onClick={() =>
                this.handleAddSubfieldToDropdown(fieldIndex, sectionIndex)
              }
              className="teal-button extra-small-button"
              size="sm"
            >
              + Add Subfield
            </Button>
          );
        } else if (field.subfield.fieldType == "object") {
          optionalButton = (
            <Button
              disabled={disabled}
              onClick={() =>
                this.handleAddSubfieldToObject(fieldIndex, sectionIndex)
              }
              className="teal-button extra-small-button"
              size="sm"
            >
              + Add Subfield
            </Button>
          );
        }
      }

      formControl = this.renderMultiFields(field, fieldIndex, sectionIndex);
    } else if (fieldType === "image") {
      formControl = this.renderFileUploader(field, fieldIndex, sectionIndex);
    } else if (fieldType === "dropdown") {
      formControl = this.renderDropdown(field, fieldIndex, sectionIndex);
    } else if (fieldType === "date") {
      formControl = this.renderDatePicker(field, fieldIndex, sectionIndex);
    } else if (fieldType === "location") {
      formControl = this.renderLocationInput(field, fieldIndex, sectionIndex);
    } else if (fieldType === "object") {
      formControl = this.renderObjectField(field, fieldIndex, sectionIndex);
    }

    let renderFieldName;
    if (required) {
      renderFieldName = fieldName + "*";
    } else {
      renderFieldName = fieldName;
    }

    let className;
    if (fieldType == "display") {
      className = "ap-display-subfield editing-role-section";
    } else {
      className = "ap-individual-subfield editing-role-section";
    }

    return (
      <Form.Group key={fieldName + "-" + fieldIndex} className="ap-formgroup">
        <Row noGutters>
          <Col>
            <Row noGutters className="editing-role-form-row additional-styling">
              <b>{renderFieldName || "Unnamed Field"}</b>
              {optionalButton}
            </Row>
            <Row>{formControl}</Row>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  renderLocationInput = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `locationInput: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `locationInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `locationInput: ${fieldName} - ${fieldIndex}`;
    }

    let defaultSelected;
    if (value) {
      defaultSelected = [value];
    }

    let className;
    if (isInvalid) {
      className = "registration-form-typeahead-row-invalid";
    } else {
      className = "registration-form-typeahead-row";
    }

    return (
      <Col className="registration-form-control-column" key={key}>
        <div className={className}>
          <Typeahead
            disabled={disabled}
            value={value}
            className="submission-form-control common-typeahead"
            defaultSelected={defaultSelected}
            placeholder="Location input..."
            onChange={e =>
              this.handleOnChangeLocation(
                e,
                fieldIndex,
                sectionIndex,
                subfieldIndex,
                objectSubfieldIndex
              )
            }
            options={this.locationOptions}
          />
        </div>
      </Col>
    );
  };

  renderStringInput = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `stringInput: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `stringInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `stringInput: ${fieldName} - ${fieldIndex}`;
    }

    return (
      <Col className="registration-form-control-column">
        <Form.Control
          disabled={disabled}
          isInvalid={isInvalid}
          size="sm"
          value={value}
          key={key}
          required={required}
          placeholder="String input..."
          onChange={e =>
            this.handleChangeField(
              e,
              fieldIndex,
              sectionIndex,
              subfieldIndex,
              objectSubfieldIndex
            )
          }
          className="submission-form-control"
        />
      </Col>
    );
  };

  renderLinkInput = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;
    const displayText = this.state.assetProfileToEdit.form.profile.sections[
      sectionIndex
    ].fields[fieldIndex].displayText;

    let value, fieldName, fieldType;
    const { required } = field;
    if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let key;
    if (subfieldIndex >= 0) {
      key = `linkInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `linkInput: ${fieldName} - ${fieldIndex}`;
    }

    return (
      <Col className="registration-form-control-column" key={key}>
        <Row noGutters className="link-row">
          <Col>
            <Form.Control
              disabled={disabled}
              isInvalid={isInvalid}
              size="sm"
              value={displayText}
              required={required}
              placeholder="Display Text..."
              onChange={e =>
                this.handleChangeDisplayText(
                  e,
                  fieldIndex,
                  sectionIndex,
                  subfieldIndex
                )
              }
              className="submission-form-control"
            />
          </Col>
          <Col>
            <Form.Control
              disabled={disabled}
              isInvalid={isInvalid}
              size="sm"
              value={value}
              required={required}
              placeholder="Link URL..."
              onChange={e =>
                this.handleChangeField(
                  e,
                  fieldIndex,
                  sectionIndex,
                  subfieldIndex
                )
              }
              className="submission-form-control"
            />
          </Col>
        </Row>
      </Col>
    );
  };

  renderSocialInput = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { required } = field;
    const { validity } = this.state.assetProfileToEdit;

    let value, platform, fieldName, fieldType;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      platform = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].platform;
      fieldName = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].fieldName;
      fieldType = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      platform = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].subfields[subfieldIndex].platform;
      fieldName = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].fieldName;
      fieldType = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      platform = this.state.assetProfileToEdit.form.profile.sections[
        sectionIndex
      ].fields[fieldIndex].platform;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    function validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }

    let isInvalid;
    if (validity === false && required === true && validURL(value)) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    return (
      <Col className="registration-form-control-column">
        <Row noGutters key={fieldName + "-" + fieldIndex} className="link-row">
          <Col>
            <Form.Control
              as="select"
              disabled={disabled}
              isInvalid={isInvalid}
              size="sm"
              value={platform}
              required={required}
              placeholder="Display Text..."
              onChange={e =>
                this.handleChangeSocialPlatform(
                  e,
                  fieldIndex,
                  sectionIndex,
                  subfieldIndex,
                  objectSubfieldIndex
                )
              }
              className="submission-form-control"
            >
              <option value="">Select a platform...</option>
              <option>Website</option>
              <option>Facebook</option>
              <option>Twitter</option>
              <option>LinkedIn</option>
              <option>Telegram</option>
              <option>Medium</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              disabled={disabled}
              isInvalid={isInvalid}
              size="sm"
              value={value}
              required={required}
              placeholder="Link URL..."
              onChange={e =>
                this.handleChangeField(
                  e,
                  fieldIndex,
                  sectionIndex,
                  subfieldIndex,
                  objectSubfieldIndex
                )
              }
              className="submission-form-control"
            />
          </Col>
        </Row>
      </Col>
    );
  };

  renderNumberInput = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `numberInput: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `numberInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `numberInput: ${fieldName} - ${fieldIndex}`;
    }

    return (
      <Col className="registration-form-control-column">
        <Form.Control
          disabled={disabled}
          isInvalid={isInvalid}
          size="sm"
          type="number"
          value={value}
          key={key}
          required={required}
          placeholder="Number input..."
          onChange={e =>
            this.handleChangeField(
              e,
              fieldIndex,
              sectionIndex,
              subfieldIndex,
              objectSubfieldIndex
            )
          }
          className="submission-form-control"
        />
      </Col>
    );
  };

  renderParagraphInput = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `paragraphInput: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `paragraphInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `paragraphInput: ${fieldName} - ${fieldIndex}`;
    }

    return (
      <Col className="registration-form-control-column">
        <Form.Control
          disabled={disabled}
          isInvalid={isInvalid}
          size="sm"
          as="textarea"
          value={value}
          key={key}
          required={required}
          placeholder="Paragraph input..."
          onChange={e =>
            this.handleChangeField(
              e,
              fieldIndex,
              sectionIndex,
              subfieldIndex,
              objectSubfieldIndex
            )
          }
          className="submission-form-control"
        />
      </Col>
    );
  };

  renderFileUploader = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType, deleteIcon;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    } else {
      deleteIcon = (
        <IoMdClose
          onClick={() =>
            this.handleClearFile(
              value,
              fieldIndex,
              sectionIndex,
              subfieldIndex,
              objectSubfieldIndex
            )
          }
          className="registration-display-image-cancel-icon"
        />
      );
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `fileUploader: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `fileUploader: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `fileUploader: ${fieldName} - ${fieldIndex}`;
    }

    let content;
    if (value) {
      if (value.includes(".png") || value.includes(".jpg")) {
        content = (
          <div
            className="registration-display-image-container"
            key={fieldName + "-" + fieldIndex}
          >
            {deleteIcon}
            <img className="registration-display-image" src={value} />
          </div>
        );
      } else if (value.includes(".pdf")) {
        content = (
          <div
            className="registration-display-pdf-container"
            key={fieldName + "-" + fieldIndex}
          >
            {deleteIcon}

            <a
              target="_blank"
              href={value}
              className="registration-document-preview"
            >
              Click here to view your document
            </a>
          </div>
        );
      } else {
        content = (
          <div
            className="registration-display-pdf-container"
            key={fieldName + "-" + fieldIndex}
          >
            <IoMdClose
              onClick={() =>
                this.handleClearFile(
                  value,
                  fieldIndex,
                  sectionIndex,
                  subfieldIndex,
                  objectSubfieldIndex
                )
              }
              className="registration-display-image-cancel-icon"
            />

            <a
              target="_blank"
              href={value}
              className="registration-document-preview"
            >
              Click here to view this file
            </a>
          </div>
        );
      }
    } else {
      content = (
        <Col
          className="registration-form-control-column"
          key={fieldName + "-" + fieldIndex}
        >
          <Form.File
            disabled={disabled}
            custom
            isInvalid={isInvalid}
            onChange={e =>
              this.handleSelectFile(
                e,
                fieldIndex,
                sectionIndex,
                subfieldIndex,
                objectSubfieldIndex
              )
            }
            label="Upload a file from your computer..."
            className="registration-form-file-control"
          />
        </Col>
      );
    }

    return content;
  };

  renderDropdown = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType, options;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
      options = objectSubfield.options || [];
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
      options = subfield.options;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;

      fieldName = field.fieldName;
      fieldType = field.fieldType;
      options = field.options;
    }

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `dropdownInput: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `dropdownInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `dropdownInput: ${fieldName} - ${fieldIndex}`;
    }

    console.log(options);

    return (
      <Col className="registration-form-control-column" key={key}>
        <Form.Control
          disabled={disabled}
          isInvalid={isInvalid}
          size="sm"
          as="select"
          value={value}
          required
          onChange={e =>
            this.handleChangeField(
              e,
              fieldIndex,
              sectionIndex,
              subfieldIndex,
              objectSubfieldIndex
            )
          }
          className="submission-form-control"
        >
          <option>Select an option...</option>
          {options && options.map(option => this.renderDropdownOption(option))}
        </Form.Control>
      </Col>
    );
  };

  renderDropdownOption = option => (
    <option key={option} value={option}>
      {option || "Unnamed Option"}
    </option>
  );

  renderDatePicker = (
    field,
    fieldIndex,
    sectionIndex,
    subfield,
    subfieldIndex,
    objectSubfield,
    objectSubfieldIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;

    let value, fieldName, fieldType;
    const { required } = field;
    if (objectSubfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].subfields[
        objectSubfieldIndex
      ].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else if (subfieldIndex >= 0) {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].subfields[subfieldIndex].value;
      fieldName = subfield.fieldName;
      fieldType = subfield.fieldType;
    } else {
      value = this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
        .fields[fieldIndex].value;
      fieldName = field.fieldName;
      fieldType = field.fieldType;
    }

    const dateValue = value ? new Date(value) : "";

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    } else {
      isInvalid = false;
    }

    let key;
    if (objectSubfieldIndex >= 0) {
      key = `datePickerInput: ${fieldName} - ${objectSubfieldIndex} - ${subfieldIndex} - ${fieldIndex}`;
    } else if (subfieldIndex >= 0) {
      key = `datePickerInput: ${fieldName} - ${subfieldIndex} - ${fieldIndex}`;
    } else {
      key = `datePickerInput: ${fieldName} - ${fieldIndex}`;
    }

    let disabled;
    let disabledTag;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
      disabledTag = "disabled-datepicker";
    }

    let className;
    if (isInvalid) {
      className = "admin-datepicker invalid";
    } else {
      className = "admin-datepicker";
    }

    return (
      <Col className="registration-form-control-column" key={key}>
        <Row noGutters className="datepicker-row">
          <DatePicker
            disabled={disabled}
            placeholderText="Date input..."
            onChange={date =>
              this.handleOnChangeDateField(
                date,
                fieldIndex,
                sectionIndex,
                subfieldIndex,
                objectSubfieldIndex
              )
            }
            selected={dateValue}
            className={className}
            showTimeInput
          />
        </Row>
      </Col>
    );
  };

  renderMultiFields = (field, fieldIndex, sectionIndex) => {
    const fieldType = field.subfield
      ? field.subfield.fieldType
      : field.fieldType;
    const subfield = this.state.assetProfileToEdit.form.template.sections[
      sectionIndex
    ].fields[fieldIndex].subfield;
    const fieldFromProfile = this.state.assetProfileToEdit.form.profile
      .sections[sectionIndex].fields[fieldIndex];

    let content;
    if (fieldType == "object") {
      const subfieldsFromProfile = fieldFromProfile.subfields;
      content = subfieldsFromProfile.map((subfield, subfieldIndex) =>
        this.renderSubfieldObject(
          subfield,
          subfieldIndex,
          field,
          fieldIndex,
          sectionIndex
        )
      );
    } else if (fieldType == "dropdown") {
      content = fieldFromProfile.subfields.map((subfield, subfieldIndex) =>
        this.renderSubfieldDropdown(
          field.subfield.options,
          subfield,
          subfieldIndex,
          fieldIndex,
          sectionIndex
        )
      );
    }

    return (
      <Col
        className="ap-multifields-col"
        key={`multifield: ${field.fieldName} - ${sectionIndex} - ${fieldIndex}`}
      >
        {content}
      </Col>
    );
  };

  renderSubfieldObject = (
    subfield,
    subfieldIndex,
    field,
    fieldIndex,
    sectionIndex
  ) => {
    const { validity } = this.state.assetProfileToEdit;
    const { fieldName, fieldType, subfields } = subfield;

    let disabled, deleteButton;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }
    if (!disabled) {
      deleteButton = (
        <AiOutlineDelete
          onClick={() =>
            this.handleDeleteSubfieldFromObject(
              subfieldIndex,
              fieldIndex,
              sectionIndex
            )
          }
          className="individual-form-icon floating-icon"
        />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled floating-icon" />
      );
    }

    let isInvalid;
    // if (validity === false && required === true) {
    //   isInvalid = true
    // } else {
    //   isInvalid = false
    // }
    let formControlLabel;
    let formControls = subfields.map((objectSubfield, objectSubfieldIndex) => {
      let formControl;
      if (objectSubfield.fieldType === "string") {
        formControl = this.renderStringInput(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "number") {
        formControl = this.renderNumberInput(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "paragraph") {
        formControl = this.renderParagraphInput(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "video") {
        formControl = this.renderStringInput(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "document") {
        formControl = this.renderFileUploader(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "image") {
        formControl = this.renderFileUploader(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "date") {
        formControl = this.renderDatePicker(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType === "location") {
        formControl = this.renderLocationInput(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType == "dropdown") {
        formControl = this.renderDropdown(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      } else if (objectSubfield.fieldType == "social") {
        formControl = this.renderSocialInput(
          field,
          fieldIndex,
          sectionIndex,
          subfield,
          subfieldIndex,
          objectSubfield,
          objectSubfieldIndex
        );
      }

      return (
        <React.Fragment>
          <label className="submission-form-control-label">
            {objectSubfield.fieldName}
          </label>
          {formControl}
        </React.Fragment>
      );
    });

    return (
      <Row noGutters className="ap-multifield-subfield-row">
        <Col>
          <Form.Control
            disabled={disabled}
            isInvalid={isInvalid}
            size="sm"
            value={
              this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
                .fields[fieldIndex].subfields[subfieldIndex].fieldName
            }
            onChange={e =>
              this.handleChangeSubfieldObjectFieldname(
                e,
                fieldIndex,
                sectionIndex,
                subfieldIndex
              )
            }
            placeholder="Subfield name..."
            className="submission-form-control"
          />
          <div className="ap-multifield-subfields-container">
            {formControls}
          </div>
        </Col>
        {deleteButton}
      </Row>
    );
  };

  renderSubfieldDropdown = (
    options,
    subfield,
    subfieldIndex,
    fieldIndex,
    sectionIndex
  ) => {
    const fieldFromProfile = this.state.assetProfileToEdit.form.profile
      .sections[sectionIndex].fields[fieldIndex];

    let disabled, deleteButton;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }
    if (!disabled) {
      deleteButton = (
        <AiOutlineDelete
          onClick={() =>
            this.handleDeleteSubfield(subfieldIndex, fieldIndex, sectionIndex)
          }
          className="individual-form-icon floating-icon"
        />
      );
    } else {
      deleteButton = (
        <AiOutlineDelete className="individual-form-icon-disabled" />
      );
    }
    return (
      <Row noGutters className="ap-multifield-subfield-row">
        <Col>
          <Form.Control
            disabled={disabled}
            placeholder="Subfield name..."
            value={fieldFromProfile.subfields[subfieldIndex].fieldName}
            onChange={e =>
              this.handleChangeSubfieldFieldname(
                e,
                fieldIndex,
                sectionIndex,
                subfieldIndex
              )
            }
            size="sm"
            className="submission-form-control"
          />
        </Col>
        <Col>
          <Form.Control
            disabled={disabled}
            value={
              this.state.assetProfileToEdit.form.profile.sections[sectionIndex]
                .fields[fieldIndex].subfields[subfieldIndex].value
            }
            onChange={e =>
              this.handleChangeSubfieldDropdown(
                e,
                fieldIndex,
                sectionIndex,
                subfieldIndex
              )
            }
            className="submission-form-control"
            size="sm"
            as="select"
          >
            <option value="">Select an option...</option>
            {options.map(option => (
              <option key={Math.random()}>{option}</option>
            ))}
          </Form.Control>
        </Col>
        {deleteButton}
      </Row>
    );
  };

  renderSubfield = (
    subfield,
    subfieldIndex,
    fieldIndex,
    sectionIndex,
    required
  ) => {
    const { validity } = this.state.assetProfileToEdit;
    const value = this.state.assetProfileToEdit.form.profile.sections[
      sectionIndex
    ].fields[fieldIndex].fields[subfieldIndex];

    let isInvalid;
    if (validity === false && required === true) {
      isInvalid = true;
    }

    let disabled;
    if (
      this.state.assetProfileToEdit.form.profile.verification == "published"
    ) {
      disabled = true;
    }

    return (
      <Row
        noGutters
        className="ap-subfield-row"
        key={`multiSubfield: ${subfieldIndex} - ${fieldIndex}`}
      >
        <Col>
          <Row noGutters className="editing-role-form-row">
            <Col>
              <b>{subfield || "Unnamed Subfield"}</b>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="registration-form-control-column">
              <Form.Control
                disabled={disabled}
                isInvalid={isInvalid}
                size="sm"
                value={value}
                onChange={e =>
                  this.handleChangeSubfield(
                    e,
                    subfieldIndex,
                    fieldIndex,
                    sectionIndex
                  )
                }
                key={subfield + "-" + subfieldIndex}
                required={required}
                className="submission-form-control"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  renderObjectField = (field, fieldIndex, sectionIndex) => {
    let content;
    if (field.subfields.length > 0) {
      content = field.subfields.map((subfield, subfieldIndex) => {
        const { fieldName, fieldType, required } = subfield;

        let formControl;
        if (fieldType === "string") {
          formControl = this.renderStringInput(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "number") {
          formControl = this.renderNumberInput(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "paragraph") {
          formControl = this.renderParagraphInput(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "video") {
          formControl = this.renderStringInput(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "document") {
          formControl = this.renderFileUploader(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "image") {
          formControl = this.renderFileUploader(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "date") {
          formControl = this.renderDatePicker(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        } else if (fieldType === "location") {
          formControl = this.renderLocationInput(
            field,
            fieldIndex,
            sectionIndex,
            subfield,
            subfieldIndex
          );
        }

        let renderFieldName;
        if (required) {
          renderFieldName = fieldName + "*";
        } else {
          renderFieldName = fieldName;
        }

        let className;
        if (fieldType == "display") {
          className = "ap-display-subfield editing-role-section";
        } else {
          className = "ap-individual-subfield editing-role-section";
        }

        return (
          <Form.Group
            key={fieldName + "-" + fieldIndex + "-"}
            className="ap-formgroup"
          >
            <Row noGutters>
              <Col>
                <Row noGutters className="editing-role-form-row">
                  <Col>
                    <b>{renderFieldName || "Unnamed Field"}</b>
                  </Col>
                </Row>
                <Row noGutters>{formControl}</Row>
              </Col>
            </Row>
          </Form.Group>
        );
      });
    }

    return <Col className="registration-form-control-column">{content}</Col>;
  };

  renderPagination = () => {
    if (!this.state.tokenProfilesList) return;

    if (this.state.tokenProfilesList.loaded) {
      const { docs, limit, total } = this.state.tokenProfilesList.profiles;
      const { currentPage } = this.state;
      const numberOfPages = Math.ceil(total / limit);
      let pages = [];

      for (let number = 1; number <= numberOfPages; number++) {
        pages.push(
          <Pagination.Item
            onClick={() => this.handleClickPage(number)}
            key={number}
            active={number === currentPage}
          >
            {number}
          </Pagination.Item>
        );
      }

      const first =
        Math.abs(currentPage - 1) > 3 ? (
          <Pagination.First onClick={() => this.handleClickPage(1)} />
        ) : (
          ""
        );
      const last =
        Math.abs(currentPage - numberOfPages) > 3 ? (
          <Pagination.Last
            onClick={() => this.handleClickPage(numberOfPages)}
          />
        ) : (
          ""
        );

      const next =
        currentPage !== numberOfPages ? (
          <Pagination.Next
            onClick={() => this.handleClickPage(currentPage + 1)}
          />
        ) : (
          ""
        );
      const prev =
        currentPage !== 1 ? (
          <Pagination.Prev
            onClick={() => this.handleClickPage(currentPage - 1)}
          />
        ) : (
          ""
        );

      return (
        <Row noGutters className="pagination-row">
          <Pagination size="sm" className="common-pagination">
            {first}
            {prev}
            {pages}
            {next}
            {last}
          </Pagination>
        </Row>
      );
    }
  };

  render() {
    let content;
    if (this.state.assetProfileToEdit) {
      const { loaded, loading } = this.state.assetProfileToEdit;

      if (loaded) {
        content = this.renderSelectedProfile();
      } else {
        content = this.renderContent();
      }
    } else {
      content = this.renderContent();
    }

    return (
      <Container fluid className="admin-content-container">
        {this.renderCreateAssetProfileModal()}
        {this.renderConfirmDeleteModal()}
        {content}
      </Container>
    );
  }
}

export default AssetProfiles;
