/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import * as CONSTANTS from "../../constants/login";
import * as ACTIONS from "../../actions/login";
import {
  loginError,
  loginSuccess,
  loginFromModalSuccess
} from "../../actions/login";
import { getMyAccountRequest, clearUserCache } from "../../actions/account";
import request from "../../../utils/apiRequest";
import { withCookies, Cookies } from "react-cookie";

function* autoLogin(action) {
  try {
    const responseData = yield call(request, "/account", "GET");

    yield put(ACTIONS.loginSuccess(responseData.data));
  } catch (err) {
    if (err.reason !== "User not logged in") {
      toast.error(err.reason);
    }

    yield put(ACTIONS.loginError(err.reason));
  }
}

function* login(action) {
  try {
    const responseData = yield call(
      request,
      "/account/login",
      "POST",
      action.data
    );
    // console.log(responseData)

    if (responseData.message == "redirect") {
      window.location.href = responseData.url;
    } else {
      yield put(ACTIONS.loginSuccess(responseData.data));
      history.push("/");
    }
  } catch (err) {
    if (err.reason == "Invalid 2fa token") {
      history.push("/two-factor-auth");
      yield put(ACTIONS.loginNeedTwoFactorAuth(action.data));
    } else {
      toast.error(err.reason);
      yield put(ACTIONS.loginError(err.reason));
    }
  }
}

function* loginWithToken(action) {
  try {
    const responseData = yield call(
      request,
      "/account/login",
      "POST",
      action.data
    );

    yield put(ACTIONS.loginSuccess(responseData.data));
    yield put(ACTIONS.clearLoginParams());
    history.push("/");
  } catch (err) {
    if (
      err.reason == "Email not included" ||
      err.reason == "Password not included"
    ) {
      history.push("/login");
    } else if (err.message == "redirect") {
      window.location.href = err.url;
    }

    toast.error(err.reason);
    yield put(ACTIONS.loginError(err.reason));
  }
}

function* loginFromModal(action) {
  try {
    const requestData = {
      username: action.userName,
      password: action.password
    };
    const responseData = yield call(
      request,
      "account/login",
      "POST",
      requestData
    );
    localStorage.setItem("refreshToken", responseData.data.refreshToken);
    const accessData = yield call(
      request,
      "account/accessToken",
      "POST",
      responseData.data
    );
    localStorage.setItem("accessToken", accessData.data.access_token);
    yield put(loginSuccess(accessData));
    yield put(getMyAccountRequest());
    if (window.location.pathname === "/research/apply") {
      // this occurs on the contributor application
      // do nothing
    } else if (localStorage.redirectPathname !== "/news") {
      history.push(localStorage.redirectPathname);
    } else {
      localStorage.setItem("redirectPathname", "/news");
      history.push("/news");
    }
    localStorage.setItem("redirectPathname", "/news");
  } catch (err) {
    toast.error(err.reason.message);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("persist:root");
    yield put(loginError());
  }
} // end of login(action)

function* logout(action) {
  try {
    history.push("/login");
    const responseData = yield call(request, "/account/logout", "POST");
  } catch (err) {}
} // end of logout(action)

export default function* loginSaga() {
  yield takeLatest(CONSTANTS.LOGIN_REQUEST, login);
  yield takeLatest(CONSTANTS.LOGIN_FROM_MODAL_REQUEST, loginFromModal);
  yield takeLatest(CONSTANTS.LOGOUT, logout);
  yield takeLatest(CONSTANTS.AUTO_LOGIN_REQUEST, autoLogin);
  yield takeLatest(CONSTANTS.LOGIN_WITH_TOKEN, loginWithToken);
}
